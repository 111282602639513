import { AuthProvider } from 'react-admin';
import tokenManager from './tokenManager';
import config from './config'
//import { Mixpanel } from './mixpanel.js';
const authUrl = config.authUrl

const publicRoutes = [
  '#/ResetPassword',
  '#/RecoverPassword',
  '#/RedirectPassword',
  '#/AcceptTerms',
  '#/Sent',
  '#/Success'
]



const refreshCallback = () => {
  console.log(`Time to refresh the token before it expires`)
  const refreshEndpoint = new Request(`${authUrl}/users/refreshToken`)
  const request = new Request(refreshEndpoint, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
    body: JSON.stringify({ refreshToken: tokenManager.getRefresh() })
  });
  return fetch(request)
  .then((response) => {
    if (response.status !== 200) {
      tokenManager.eraseToken();
      console.log(
        'Failed to renew the jwt from the refresh token.'
      );
      return { token: null };
    }
    return response.json();
  })
  .then(({ success, token, refreshToken, delay }) => {
    if (success && token) {
      tokenManager.setToken(token, refreshToken, delay);
      return true;
    }
    return false;
  });  
}

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${authUrl}/users/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    tokenManager.setTokenRefreshCallback(refreshCallback)
    return fetch(request)
    .then(response => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
    .then( data => {
      //Mixpanel.identify(username)
      tokenManager.setToken(data.token, data.refreshToken, data.delay)
      if (data.defaultPractice) {
        localStorage.setItem('selectedPractice', data.defaultPractice)
      } else {
        return Promise.reject()
      }
      return Promise.resolve();
    })
    .catch ( e => {
      return Promise.reject()
    })
  },
  logout: () => {
    //Mixpanel.reset()
    console.log(`Explicit logout`)
    const refreshToken = tokenManager.getRefresh()
    const token = tokenManager.getToken()
    tokenManager.eraseToken()
    localStorage.clear()
    if (refreshToken && token) {
      const request = new Request(`${authUrl}/users/logout`, {
        method: 'POST',
        headers: new Headers({ 
          'Authorization': 'Bearer ' + token, 
          'Content-Type': 'application/json'
        }),
        credentials: 'include',
        body: JSON.stringify({ refreshToken })
      })
      return fetch(request)
      .then(response => {
        // ignore errors
        return response.json();
      })
      .then( data => {

        if (data.success) {
          console.log(`logged out successfully`)
        }
        return Promise.resolve()
      })
      .catch ( e => {
        // ignore errors
        return Promise.resolve()
      })
    } else {
      // already signed out
      return Promise.resolve()
    }
  },
  checkError: (e) => {
    console.log(`checkError: `, e)
    return Promise.resolve()
  },
  checkAuth: () => {
    console.log(`CheckAuth called with path ${window.location.hash}`)
    if (publicRoutes.includes(window.location.hash)) {
        console.log(`Public page so its ok`)
        return Promise.resolve()
    }
    return tokenManager.getToken() ? Promise.resolve() : Promise.reject();
    // if (tokenManager.getToken()) {
    //   return Promise.resolve()
    // }
    // Mixpanel.reset()
    // return Promise.reject();
  },
  getPermissions: () => Promise.resolve([]),
  getIdentity: () => {
    console.log(`getIdentity called - remote call to /users/me`)
    const request = new Request(`${authUrl}/users/me`, {
      method: 'GET',
      headers: new Headers({ 
        'Authorization': 'Bearer ' + tokenManager.getToken(), 
        'Content-Type': 'application/json' 
      }),
    })
    return fetch(request)
    .then(response => {
      if (response.status === 401 || response.status === 403) {
        tokenManager.eraseToken()
        window.location.href = '/#/login'
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then( user => {
      localStorage.setItem('isAdmin', user.role.includes('admin') ? 'true' : '')
      localStorage.setItem('isStaff', user.role.includes('staff') ? 'true' : '')
      localStorage.setItem('isDev', user.role.includes('dev') ? 'true' : '')
      localStorage.setItem('isCompliance', user.role.includes('compliance') ? 'true' : '')
      localStorage.setItem('isBillable', user.role.includes('billable') ? 'true' : '')
      localStorage.setItem('isConnectUser', user.role.includes('connect') ? 'true' : '')
      localStorage.setItem('practices', user.practiceIds)

      if (!user.role.includes('terms')) {
        window.location.href = '/#/AcceptTerms'
        return Promise.reject()
      }

      //console.log(`sending updated identity info as a promise`)

      return Promise.resolve({
        id: user._id,
        name: user.firstName + " " + user.lastName,
        fullName: user.firstName + " " + user.lastName,
        picture: user.picture,
        roles: user.role,
        practices: user.practiceIds,
        email: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.picture,
      })
    })
    .catch ( e => {
      throw new Error(e);
    })

  },

};

export default authProvider;