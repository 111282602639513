import { Box, TableHead, TableRow, TableCell, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, CircularProgress } from '@mui/material';
import { Children, useState } from 'react';
import { Datagrid, List, TextField, FunctionField, ReferenceField, useRecordContext, fetchUtils, useRefresh, useSafeSetState, LoginFormClasses, useNotify, DateField } from 'react-admin';
import tokenManager from '../../tokenManager';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BillingsAside from './BillingsAside';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const BillingsList = (props) => {

    const [loading, setLoading] = useSafeSetState(false);
    const [DialogOpen, setDialogOpen] = useState(false)
    const refresh = useRefresh()
    const notify = useNotify()
    const [billingId, setBillingId] = useState('')

    const updateBillingStatus = async () => {
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        await httpClient(`${apiUrl}/kaizenovate/billings/1.0.0/mark-billed`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billing: billingId,
            }),
        }).catch((e)=> {
            console.log(`Error in logActivity:logActivity()`)
            console.error(e)
            throw e
        })
        setDialogOpen(false)
        refresh()
    }

    const handleClickBilling = async (record, event) => {

        event.stopPropagation()
        
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();
        setLoading(true)

        const copyString = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-billing-details`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billingId: record.id,
            }),
        }).catch((e)=> {
            console.log(`Error in billingsList:handleClickBilling()`)
            console.error(e)
            setLoading(false)
            notify('Failed to fetch billing details', {type: 'error'})
            throw e
        })

        navigator.clipboard.writeText(copyString.body)
        setLoading(false)
        setBillingId(record.id)
        
        // navigator.clipboard.writeText(
        //     "5/3/2023 - Jay Greenstein - Provider Called patient for RTM billing - 4:00 - Talked about x, y, and z\n" + 
        //     "5/2/2023 - Jay Greenstein - Resolved Patient Action - 2:43 - Completed: Sent Message\n"
        // )
        setDialogOpen(true);
    }


    const CopyActivitiesField = ({ source, label }) => {
        const record = useRecordContext();
        if (record && (record[source] === 'Activities in Progress' || record[source] === 'Billed' )) return (<></>)
        return (<Button sx={{ py: 1.5, backgroundColor: "#2439F2" }} variant="contained" onClick={async (event) => await handleClickBilling(record, event)}>
                    {<ContentCopyIcon />}&nbsp;Copy Activity Details
                </Button>);
    };

    const DataGridHeader = ({ children }) => (
        <TableHead>
            <TableRow>
                {Children.map(children, child => (
                    <TableCell key={child.props.source} sx={{ fontSize: 20 }}>
                        {child.props.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )

    const StatusField = (props) => {
        const record = useRecordContext();
        if (record['status'] === 'Ready for Billing') {
            return <TextField label={props.label} source="status" sx={{color: 'green'}} />
        } else {
            return <TextField label={props.label} source="status" />;
        }
    }

    return (
    <div>
        <div>
            <Dialog
                open={loading!}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    Loading
                </DialogTitle>
                <DialogContent sx={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                    <DialogContentText id="alert-dialog-description">
                        Fetching billing details
                    </DialogContentText>
                    <CircularProgress
                        className={LoginFormClasses.icon}
                        size={19}
                        thickness={3}
                        sx={{display: 'flex', alignSelf: 'center', padding: '15px'}}
                    />
                </DialogContent>
            </Dialog>
        </div>
        <div>
            <Dialog
                open={DialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    Update status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Has this item been submitted for billing in the EHR?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: "#2439F2" }} onClick={() => setDialogOpen(false)} autoFocus>
                        <CloseIcon />&nbsp;{"No"}
                    </Button>
                    <Button sx={{ color: "#2439F2" }} onClick={updateBillingStatus} autoFocus>
                        <CheckCircleIcon />&nbsp;{"Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
        <Box className="billings-list-container">
            <List
                aside={<BillingsAside />}
                sort={{ field: 'start_date', order: 'DESC' }}
            >
                <Datagrid
                    rowClick="edit"
                    bulkActionButtons={false}
                    header={<DataGridHeader children/>}
                >
                    <FunctionField
                        label="Billing Date"
                        render={record => {
                            var recordCopy = {...record}
                            if (recordCopy.billing_date && recordCopy.billing_date > 0) {
                              recordCopy.billing_date = recordCopy.billing_date * 1000
                            } else {
                              recordCopy.billing_date = null
                            }
                            return <DateField
                                source="billing_date"
                                record={recordCopy}
                                label="Billing Date"
                                emptyText="-"
                                options={{ timeZone: recordCopy["practice"]["time_zone"] }}
                            />
                            
                        }}
                    />
                    <TextField label="Code" source="cpt_code" />
                    <ReferenceField label="Patient Name" source="patient.id" reference="users" />
                    <StatusField label="Status"/>
                    <CopyActivitiesField label="Details" source="status" />
                </Datagrid>
            </List>
        </Box>
    </div>
)};