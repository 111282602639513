import MasterprogramsList from './MasterprogramsList';
import MasterprogramsEdit from './MasterprogramsEdit';
import MasterprogramsCreate from './MasterprogramsCreate';

const resource = {
    list: MasterprogramsList,
    edit: MasterprogramsEdit,
    create: MasterprogramsCreate,
};

export default resource;