import { useState } from "react";
import {
    Button,
    Confirm,
    DateInput,
    SaveButton,
    TimeInput,
    Toolbar,
} from "react-admin";
import SendIcon from '@mui/icons-material/Send';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendTestMessage from "./SendTestMessage";
import TransformBroadcastData from "./TransformBroadcastData";
import TimeZoneNotice from "./TimeZoneNotice";

const ScheduleOrSendToolbar = () => {
    const [open, setOpen] = useState(false);
    return (
        <Toolbar className='broadcast-save-toolbar'>
            {/* trigger schedule window */}
            <Button
                label='Schedule message'
                onClick={() => setOpen(true)}
                startIcon={<ScheduleIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
            />
            <Confirm
                isOpen={open}
                title='Schedule Message'
                className='broadcast-schedule-modal'
                content={<>
                    <div className='broadcast-schedule-fields'>
                        <DateInput source='date' />
                        <TimeInput source='time' />
                        {/* <SelectInput source='time_zone'
                            validate={[required()]} // this + defaultValue remove the empty option
                            defaultValue='New_York' // update to practice timezone
                            choices={[
                                { id: 'New_York', name: 'New York (EDT)' },
                                { id: 'Chicago', name: 'Chicago (CDT)' },
                                { id: 'Denver', name: 'Denver (MDT)' },
                                { id: 'Phoenix', name: 'Phoenix (MST)' },
                                { id: 'Los_Angeles', name: 'Los Angeles (PDT)' },
                                { id: 'Anchorage', name: 'Anchorage (AKDT)' },
                                { id: 'Honolulu', name: 'Honolulu (HST)' },
                            ]} 
                        /> */}
                    </div>
                    <div style={{width: '48%', paddingLeft: '52%', marginTop: '-20px' }}>
                        <TimeZoneNotice />
                    </div>
                </>}
                onClose={() => setOpen(false)}
                onConfirm={() => {
                    localStorage.setItem("scheduled", "true")
                    const submitBtn = document.querySelector('.broadcast-schedule-submit') as HTMLElement
                    submitBtn.click()
                }}
                confirm='Send'
            />

            {/* hidden submit for schedule */}
            <SaveButton className='broadcast-schedule-submit' label='Schedule message' />

            {/* send message now */}
            <SaveButton
                className='broadcase-send-submit'
                sx={{display: 'none'}}
                label='Send message now'
                icon={<SendIcon />}
                type='button'
                transform={(data: any) => TransformBroadcastData(data, true)}
            />
            <Button
                label='Send message now'
                startIcon={<SendIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
                onClick={() => {
                    localStorage.setItem("scheduled", "false")
                    const submitBtn = document.querySelector('.broadcase-send-submit') as HTMLElement
                    submitBtn.click()
                }}
            />
            <SendTestMessage origin='broadcast' />
        </Toolbar>
    )
}

export default ScheduleOrSendToolbar;