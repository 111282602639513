import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Slide,
    Box
} from "@mui/material";
import RepeatIcon from '@mui/icons-material/Repeat';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({  open, countdown, onLogout,onContinue }) => {
    return (
        <Dialog
            open={open}
            classes={{ paper: {borderRadius: 0} }}
            TransitionComponent={Transition}
        >
        <DialogTitle>
            Are You Still Working?
        </DialogTitle>
        <DialogContent>
            <Typography variant="body2">{`This Screen Will Be Timed Out In 30 Seconds Due To Inactivity.`}</Typography>
            <Box sx={{height: '15px'}}/>
            <Typography variant="body2">
                {countdown < 10 ? '0:0' : '0:'}
                <span className={{color: "#ff0000"}}>{countdown}</span>
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={onContinue}
                backgroundColor="inherit"
                variant="contained"
                // sx={{
                //     borderRadius: 0,
                //     textTransform: "none",
                //     padding: 5
                // }}
            >
                <RepeatIcon/>
                <Box sx={{width: '5px'}}/>
                I'm still working
            </Button>
        </DialogActions>
        </Dialog>
    );
}
export default SessionTimeoutDialog;