import React, { useRef } from 'react';
import {
    Attachment,
    Avatar,
    MessageOptions,
    MessageRepliesCountButton,
    MessageStatus,
    MessageText,
    MessageTimestamp,
    useMessageContext,
} from 'stream-chat-react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { FaLock, FaLockOpen } from "react-icons/fa6";

import './CustomMessage.scss';
import { useGetIdentity } from 'react-admin';

export const CustomMessage = () => {

    const { identity } = useGetIdentity();

    const {
        message,
    } = useMessageContext();

    const messageWrapperRef = useRef(null);
    var customMessageTypeData = message.customType;
    const customMessageType = customMessageTypeData?.toString();
    const isCurrentUser = message.user?.id === localStorage.getItem('selectedPractice')
    
    // TODO remove these console logs
    // console.log("Message.created_at:")
    // console.log(message.created_at);
    // console.log(user);
    // console.log(isCurrentUser);
    // console.log(user?.email)
    // console.log(message.user?.email)

    // console.log(message)
    // console.log(customMessageType);

    const kaizoIds = ['63c197739afd7632800951a0', '63c197739afd7619830951a1', '63c197739afd761fec0951a2', '63c197739afd760bed0951a3', '63b64b466d80176806435d0b', '647fa1afdec58400128d10ae', '64cb1cdbb7748050628d14da']

    if (customMessageType === "secure" || (customMessageType === "non-secure" && !kaizoIds.includes(message.user!.id) && message.created_at! < new Date('2024-05-20'))) {
      return (
        <div className="secure-message-wrapper" >
            <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
                <Avatar image={message.user?.image} />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <div className='message-header'>
                        <div className='message-header-name'>{message.user?.name} - Secure App Message</div>
                    </div>
                    <div className='str-chat__message-text'>
                        <div className='str-chat__message-text-inner'>
                            <p>
                                {message.text}
                            </p>
                            <div className="str-chat__message-text-inner--secure-icon">
                                <FaLock size={12}/>
                            </div>
                        </div>
                    </div>
                    <div className='message-header-timestamp'>
                        <MessageTimestamp />
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        </div>
      );
    }

    if (customMessageType === "non-secure") {
      return (
        <div className="non-secure-message-wrapper" >
            <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
                <Avatar image={message.user?.image} />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <div className='message-header'>
                        <div className='message-header-name'>{message.user?.name} - Non-Secure Text Message</div>
                    </div>
                    <div className='str-chat__message-text'>
                        <div className='str-chat__message-text-inner'>
                            <p>
                                {message.text}
                            </p>
                            <div className="str-chat__message-text-inner--non-secure-icon">
                                <FaLockOpen size={12}/>
                            </div>
                        </div>
                    </div>
                    <div className='message-header-timestamp'>
                        <MessageTimestamp />
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        </div>
      );
    }

    if (customMessageType === "patient-text") {
      return (
        <div className="patient-text-wrapper" >
            <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
                <Avatar image={message.user?.image} />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <div className='message-header'>
                        <div className='message-header-name'>{message.user?.name} - TEXT MESSAGE</div>
                    </div>
                    <div className='str-chat__message-text'>
                        <div className='str-chat__message-text-inner'>
                            <p>
                                {message.text}
                            </p>
                        </div>
                    </div>
                    <div className='message-header-timestamp'>
                        <MessageTimestamp />
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        </div>
      );
    }

    if (customMessageType === "symptom") {
        return (
            <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
                <Avatar image={message.user?.image} />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <SymptomsSlider exerciseName={message.exerciseName} symptomValue={message.symptomValue}/>
                    <MessageText />
                    <div className='message-header'>
                        <div className='message-header-timestamp'>
                            <MessageTimestamp />
                        </div>
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        )
    }

    if (customMessageType === "schedule") {
        return (
            <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
                <Avatar image={message.user?.image} />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <div className="schedule-appointment-container">
                        <MessageText />
                        <ScheduleAppointmentButton />
                    </div>
                    <div className='message-header'>
                        <div className='message-header-timestamp'>
                            <MessageTimestamp />
                        </div>
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        )
    }

    if (customMessageType === "appointment") {
        return (
            <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
                <Avatar image={message.user?.image} />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <AppointmentStatus appointmentData={message.appointmentData}/>
                    <MessageText />
                    <div className='message-header'>
                        <div className='message-header-timestamp'>
                            <MessageTimestamp />
                        </div>
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        )
    }

    if (customMessageType === "system-message") {

        return (
            <div className='str-chat__message--me message-wrapper message-wrapper--single' >
                {/* <Avatar image={message.user?.image} /> */}
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    {/* <MessageText /> */}
                    <div className='str-chat__message-text'>
                        <div className='str-chat__message-text-inner'>
                            <p>
                                {message.text}
                            </p>
                        </div>
                    </div>
                    {/* <div className='message-header'>
                        <div className='message-header-timestamp'>
                            <MessageTimestamp />
                        </div>
                    </div> */}
                    {/* <MessageStatus /> */}
                    {/* {message.attachments && <Attachment attachments={message.attachments} />} */}
                    {/* <MessageRepliesCountButton reply_count={message.reply_count} /> */}
                </div>
            </div>
        )
    }

    if (customMessageType === "provider-note") {
      return (
        <div className="provider-note-wrapper" >
            <div className={'str-chat__message--me message-wrapper message-wrapper--single'} >
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <div className='message-header'>
                        <div className='message-header-name'>SYSTEM NOTE</div>
                    </div>
                    <div className='str-chat__message-text'>
                        <div className='str-chat__message-text-inner'>
                            <p>
                                {message.text}
                            </p>
                        </div>
                    </div>
                    <div className='message-header-timestamp'>
                        <MessageTimestamp />
                    </div>
                    <MessageStatus />
                    {message.attachments && <Attachment attachments={message.attachments} />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        </div>
      );
    }

    // if (customMessageTypeData) {
    //     return (
    //         <div className='message-wrapper' >
    //             <Avatar image={message.user?.image} />
    //             <div className='message-wrapper-content'>
    //                 <MessageOptions messageWrapperRef={messageWrapperRef} />
    //                 <div className='message-header'>
    //                     <div className='message-header-name'>{customMessageTypeData}</div>
    //                     <div className='message-header-timestamp'>
    //                         <MessageTimestamp />
    //                     </div>
    //                 </div>
    //                 <MessageText />
    //                 <MessageStatus />
    //                 {message.attachments && <Attachment attachments={message.attachments} />}
    //                 <MessageRepliesCountButton reply_count={message.reply_count} />
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <div className={(isCurrentUser ? 'str-chat__message--me' : '') + ' message-wrapper'} >
            <Avatar image={message.user?.image} />
            <div className='message-wrapper-content'>
                <MessageOptions messageWrapperRef={messageWrapperRef} />
                <div className='message-header'>
                    <div className='message-header-name'>{message.user?.name}</div>
                </div>
                <MessageText />
                <div className='message-header-timestamp'>
                    <MessageTimestamp />
                </div>
                <MessageStatus />
                {message.attachments && <Attachment attachments={message.attachments} />}
                <MessageRepliesCountButton reply_count={message.reply_count} />
            </div>
        </div>
    );
};

const SymptomsSlider = ( data ) => {
    return (
        <div className='custom-message__symptom-slider-container'>
            <div className='exercise-name'>{ data.exerciseName ?? "" }</div>
            <div className='custom-message__symptom-slider'>
                <div className='range--title'>Increased Symptoms</div>
                <input type="range" min={0} max={100} value={ data.symptomValue ? data.symptomValue * 100 : 50 } className="range" disabled/>
                <div className='range--label'>
                    <span>None</span>
                    <span>Severe</span>
                </div>
            </div>
        </div>
    )
}

const ScheduleAppointmentButton = () => {
    return (
        <div className='custom-message__schedule-appointment-button'>
            SCHEDULE AN APPOINTMENT
        </div>
    )
}

const AppointmentStatus = (appointmentData) => {

    const data = appointmentData.appointmentData;

    return (
        <div className='custom-message__appointment-status'>
            <div className='status-text'>{data.action && data.action === "reschedule" ? "Rescheduled an appointment" : "Scheduled an appointment"}</div>
            <div className='appointment-container'>
                <div className='header'>
                    <Avatar image={data?.provider?.image} size={26} />
                    <div className="title-container">
                        <div className='title'>
                            Clinic Appointment
                            {/* <span className='icon'></span> */}
                        </div>
                        <div className='scheduled-date'>{data?.date}</div>
                    </div>
                </div>
                <div className='body'>
                    <div className='confirmation-status'>
                        <span className='icon'>
                            <span className="checkmark">
                                <div className="checkmark_circle"></div>
                                <div className="checkmark_stem"></div>
                                <div className="checkmark_kick"></div>
                            </span>
                        </span>
                        {data?.status}
                    </div>
                    <div className='scheduled-time'>
                        <span className='icon'>
                            <AccessTimeIcon sx={{ fontSize: 20 }} />
                        </span>
                        {data?.time}
                    </div>
                </div>
            </div>
        </div>
    )
}