import { Grid } from '@mui/material';
import {
    AutocompleteInput,
    BooleanInput,
    Button,
    CheckboxGroupInput,
    Edit,
    FormDataConsumer,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    TextInput,
    Toolbar,
    SaveButton,
    FunctionField,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';

const transform = (data: any) => {
    // default submit transform - not in use due to ScheduleOrSendToolbar override
    console.log('data', data);
    const transformResult = { ...data }
    transformResult.practice_id = data.practice.id;
    transformResult.template_id = data.template.id;

    console.log('transformResult', transformResult);
    return transformResult
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const RenderMessageTemplate = () => {
    const formValues = useWatch();
    const channels = formValues.channels;
    // RenderMessageTemplate is only called when formValues.template exists
    const record = useGetOne(`messagetemplates`, { id: formValues.template.id });

    const formatMessage = (message: string) => {
        // repalce newlines with <p> tags
        let sms = message ? message.replaceAll('\n', '</p><p>') : '';
        if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
        // no more than one consecutive empty paragraph
        sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');
        return sms;
    }

    if (channels && record.data) {
        const sms_html = channels.includes('sms') && record.data.sms_message && (
            <>
                <p>SMS Message</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.data.sms_message) }}
                />
            </>
        );
        const email_html = channels.includes('email') && record.data.email_message && (
            <>
                <p>Email</p>
                <p >Email Subject</p>
              <div
               id="email-subject"                
               className="broadcast-textarea"
               style={{minHeight: "35px"}}
                dangerouslySetInnerHTML={{
                  __html: formatMessage(record.data.email_subject || record.data.name),
                }}
              />
                <p>Email Body</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.data.email_message) }}
                />
            </>
        );
        return (
            <div className='broadcast-textarea-wrapper'>
                {sms_html || ""}
                {email_html || ""}
            </div>
        );
    }
    return <div></div>;
};

const MessageAutomationEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data: any) => {
        notify('Automation updated', { type: 'info' });
        redirect('/messageautomations');
    }

    const onCancel = (data: any) => {
        // redirects to automations as the only way to edit broadcast is through automations
        redirect('/messageautomations');
    }

    const CustomToolbar = (props) => {
        return <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button
                label='Cancel'
                size='medium'
                variant='contained'
                onClick={onCancel}
            />
            <SaveButton />
        </Toolbar>
    }

    return (
        <Edit
            // className='broadcast-create template-form'
            className='automation-create'
            sx={{ maxWidth: '1020px' }}
            transform={(data: any) => transform(data)}
            mutationOptions={{ onSuccess }}
            mutationMode="pessimistic"
            resource='messageautomations'
        >
            {/* modal here */}
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='broadcast'
                // toolbar={<ScheduleOrSendToolbar customQuery={createQuery}/>}
                toolbar={<CustomToolbar />}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Edit Broadcast</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        {/* if needed can change the source string if different desired user flow */}
                        {/* read only if already sent */}
                        <FunctionField source="enabled" render={record => <BooleanInput source="enabled" readOnly={(record.last_sent > 0)} label='Enabled' />} />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth readOnly />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='broadcast-label'>Channels:</p>
                    <Grid item xs={12}>
                        <FunctionField source="channels" render={record => <CheckboxGroupInput
                            // read only if already sent
                            readOnly={(record.last_sent > 0)}
                            source='channels'
                            choices={[
                                { id: 'sms', name: 'Text Message' },
                                { id: 'email', name: 'Electronic Mail' },
                            ]}
                            validate={[required()]}
                        />} />
                    </Grid>
                </Grid>

                <Grid {...gridProps} className='broadcast-richtext'>
                    <Grid item xs={12}>
                        <ReferenceInput
                            source='template.id'
                            reference='messagetemplates'
                            filter={{ is_broadcast: false, is_provider_notification: false }}
                        >
                            <AutocompleteInput
                                optionText='name'
                                label='Select a template...'
                                filterToQuery={(value) => ({ name: value })}
                                validate={[required()]}
                                sx={{ display: 'none' }}
                            />
                        </ReferenceInput>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => <>
                            {formData.template && (
                                <Grid item xs={12} className='broadcast-multiline'>
                                    <RenderMessageTemplate />
                                </Grid>
                            )}
                        </>}
                    </FormDataConsumer>
                </Grid>
                <SendTestMessage origin='automation' />
            </SimpleForm>
        </Edit>
    );
};

export default MessageAutomationEdit;
