import { DateInput, Filter } from "react-admin";

const DateFilter = (props) => {
    return (
        <Filter {...props}>
            <DateInput source="time_gte" label="Start Date" alwaysOn />
            <DateInput source="time_lte" label="End Date" alwaysOn />
        </Filter>
    )
}

export default DateFilter;