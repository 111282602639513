import {
    AlignmentButtons,
    ClearButtons,
    ColorButtons,
    FormatButtons,
    ImageButtons,
    LevelSelect,
    LinkButtons,
    ListButtons,
    QuoteButtons,
    RichTextInputToolbar,
} from 'ra-input-rich-text';
import ToolbarDynamicText from './ToolbarDynamicText';
import ToolbarEmojiPicker from './ToolbarEmojiPicker';

const CustomRichTextToolbar = ({richTextMode}: {richTextMode: boolean}) => {
    return (
        <RichTextInputToolbar>
            {richTextMode && <>
                <LevelSelect />
                <FormatButtons />
                <ColorButtons />
                <AlignmentButtons />
                <ListButtons />
                <LinkButtons />
                <ImageButtons />
                <QuoteButtons />
                <ClearButtons />
            </>}
            <ToolbarDynamicText />
            <ToolbarEmojiPicker richTextMode={richTextMode} />
        </RichTextInputToolbar>
    );
}

export default CustomRichTextToolbar;