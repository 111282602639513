import * as React from 'react';
import { Link, FieldProps, useRecordContext } from 'react-admin';

import FullNameField from './FullNameField';
import { Patient } from '../../types';

const PatientLinkField = (props: FieldProps<Patient>) => {
    const record = useRecordContext<Patient>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/users/${record.id}`}>
            <FullNameField />
        </Link>
    );
};

PatientLinkField.defaultProps = {
    source: 'first',
};

export default PatientLinkField;
