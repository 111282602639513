import ExerciseList from './ExerciseList';
import ExerciseEdit from './ExerciseEdit';
import ExerciseCreate from './ExerciseCreate';

const resource = {
    list: ExerciseList,
    edit: ExerciseEdit,
    create: ExerciseCreate,
};

export default resource;