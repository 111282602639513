import { Box, Grid } from "@mui/material"
import PatientDetailsContainer from "./PatientDetailsContainer"

const AppointmentHistory = ({ record }) => {
    return (
        <PatientDetailsContainer title="Appointment History" showMenu={false} menuOptions={[]} menuLoading={false}>
            <Grid item xs={12} mb={5}>
                <Box 
                    borderBottom={1}
                    borderColor="#D2D2D2"
                    display={{ xs: 'block', sm: 'flex' }}
                    p={2}
                >
                    <Box flex={1}>
                        First  Seen
                    </Box>
                    <Box flex={1} textAlign="right">
                        {record.statistics_first_seen && new Date(record.statistics_first_seen*1000).toLocaleString()}
                    </Box>
                </Box>
                <Box 
                    borderBottom={1}
                    borderColor="#D2D2D2"
                    display={{ xs: 'block', sm: 'flex' }}
                    p={2}
                >
                    <Box flex={1}>
                        Last Seen
                    </Box>
                    <Box flex={1} textAlign="right">
                        {record.statistics_last_seen && new Date(record.statistics_last_seen*1000).toLocaleString()}
                    </Box>
                </Box>
                <Box 
                    borderBottom={1}
                    borderColor="#D2D2D2"
                    display={{ xs: 'block', sm: 'flex' }}
                    p={2}
                >
                    <Box flex={1}>
                        Next Appointment
                    </Box>
                    <Box flex={1} textAlign="right">
                        {record.statistics_next_visit && new Date(record.statistics_next_visit*1000).toLocaleString()}
                    </Box>
                </Box>
                <Box 
                    borderBottom={1}
                    borderColor="#D2D2D2"
                    display={{ xs: 'block', sm: 'flex' }}
                    p={2}
                >
                    <Box flex={1}>
                        Total Appointments Attended
                    </Box>
                    <Box flex={1} textAlign="right">
                        {record.statistics_total_visit}
                    </Box>
                </Box>
                <Box 
                    borderBottom={1}
                    borderColor="#D2D2D2"
                    display={{ xs: 'block', sm: 'flex' }}
                    p={2}
                >
                    <Box flex={1}>
                        Appointments Until Next Reward
                    </Box>
                    <Box flex={1} textAlign="right">
                        {record.rewards.visits % record.rewards.goal}/{record.rewards.goal}
                    </Box>
                </Box>
            </Grid>
        </PatientDetailsContainer>
    );
}

export default AppointmentHistory;
