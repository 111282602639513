import { SxProps, Grid, Box, Typography, Link } from "@mui/material";

import { useEffect, useState } from "react";
import {
  FieldProps,
  useRecordContext,
  useUpdate,
  useGetIdentity,
  useRedirect,
  //   useRefresh,
} from "react-admin";
import { Patient } from "../../types.js";
import { format } from "date-fns";
import { DataKey } from "recharts/types/util/types";
import dataProvider from "../../dataProvider.js";
import tokenManager from "../../tokenManager.js";
import { logActivity } from "../../logActivity.js";
import SessionTimeout from "../../SessionTimeout.js";

interface Props extends FieldProps<Patient> {
  size?: string;
  sx?: SxProps;
}

const NewPatientInfoTab = (props: Props) => {
  //date for automated testing, 0 if not testing
  var date = Number(localStorage.getItem("testDate"));
  if (!date) {
    date = 0;
  }

  //   const refresh = useRefresh();
  const record = useRecordContext<Patient>();
  const [update, { isLoading }] = useUpdate();
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const redirect = useRedirect();

  var startTime = new Date();

  // add new activity log
  const updateWithNewRecord = async (newActivity) => {
    if (newActivity.duration <= 1) return;

    var currRecord = record;
    if (identityLoading && !isLoading && tokenManager.getToken()) {
      await dataProvider.getOne(`users`, record).then((result) => {
        currRecord = result.data;
      });
    }

    var currDate = currRecord.start_billing_date;
    var currTime = currRecord.start_billing_time;
    var currBillingDuration = currRecord.current_billing_duration;

    if (
      currDate == null ||
      currDate === "" ||
      currTime == null ||
      currTime === ""
    ) {
      currDate = format(new Date(), "yyyy-MM-dd");
      currTime = format(new Date(), "HH:mm");
    }

    if (currBillingDuration == null || currBillingDuration === 0) {
      currBillingDuration = newActivity.duration;
    } else {
      currBillingDuration =
        currRecord.current_billing_duration + newActivity.duration;
    }

    // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
    const diff = {
      current_billing_duration: currBillingDuration,
      start_billing_date: currDate,
      start_billing_time: currTime,
    };

    update("users", {
      id: currRecord.id,
      data: diff,
      previousData: currRecord,
    });

    logActivity(
      "PROVIDER.View.Details",
      newActivity.activity,
      newActivity.comment,
      currRecord.id,
      newActivity.duration,
      date
    );
  };

  // Add activity log when moving away from Patient Details Tab
  useEffect(() => {
    return () => {
      const duration = Math.round(
        (new Date().getTime() - startTime.getTime()) / 1000
      );
      var newActivity = {
        date: Math.round(Date.now() / 1000),
        duration: duration,
        activity: "Viewed New Patient Info Page",
        first: identity?.firstName ?? "",
        last: identity?.lastName ?? "",
        email: identity?.username ?? "", 
        picture: identity?.picture ?? "",
        comment: "None",
        billable: false,
      };
      updateWithNewRecord(newActivity);
    };
  }, [!identityLoading]);

  var recordSymptomsDataValues: DataKey<any>[] = [];
  record.symptoms_data.forEach((entry, i) => {
    const keyArr = Object.keys(entry);
    keyArr.forEach((key) => {
      if (key !== "date" && recordSymptomsDataValues.indexOf(key) === -1)
        recordSymptomsDataValues.push(key);
    });
  });

  const capitalizeWord = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const HearAboutUs = ({ data, otherDesc, referralCode }) => {
    let refCode = false;
    let other = false;

    data = data.sort((a, b) => {
      // Define the order for special cases
      const specialOrder = ["other", "referral_code"];

      // Check if both elements are special cases
      if (specialOrder.includes(a) && specialOrder.includes(b)) {
        // Maintain the order of "other" and "referral_code" as in `specialOrder`
        return specialOrder.indexOf(a) - specialOrder.indexOf(b);
      }

      // If `a` is a special case, it should come later
      if (specialOrder.includes(a)) {
        return 1;
      }

      // If `b` is a special case, it should come later
      if (specialOrder.includes(b)) {
        return -1;
      }

      // If neither is a special case, sort alphabetically
      return a.localeCompare(b);
    });

    let parsedData = data.map((entry, i) => {
      if (entry === "referral_code") {
        refCode = true;
        return (
          <>
            <br></br>
            <span key={i}>
              <b>Referral Code: </b>
              {referralCode}
            </span>
          </>
        );
      }

      if (entry === "other") {
        other = true;
        return (
          <>
            <hr></hr>
            <span key={i}>
              <b>Other: </b>
              {otherDesc},{" "}
            </span>
          </>
        );
      }

      if (i === data.length - 1)
        return (
          <span key={i}>{capitalizeWord(entry).split("_").join(" ")}</span>
        );
      return (
        <span key={i}>{capitalizeWord(entry).split("_").join(" ")}, </span>
      );
    });

    return <span>{parsedData}</span>;
  };

  const InjuryRelation = ({ data }) => {
    let parsedData = data.map((entry, i) => {
      if (i === data.length - 1)
        return (
          <span key={i}>{capitalizeWord(entry).split("_").join(" ")}</span>
        );
      return (
        <span key={i}>{capitalizeWord(entry).split("_").join(" ")}, </span>
      );
    });

    return <span>{parsedData}</span>;
  };

  return (
    <div>
      <SessionTimeout
        isAuthenticated={true}
        logOut={() => {
          redirect("/users");
        }}
      />
      <Grid container width={{ xs: "100%" }} spacing={2} className="patients">
        <Grid item xs={12} md={8} mb={5}>
          <Typography variant="h4" gutterBottom mb={2} mt={4}>
            <Box fontWeight="fontWeightBold">New Patient Details</Box>
          </Typography>
          <Box display={{ xs: "block", sm: "flex" }} mb={3} mr={2}>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                First
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record.first !== "" ? record.first : "no first name"}
              </Box>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Last
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record.last !== "" ? record.last : "no last name"}
              </Box>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Email
              </Typography>
              <Typography
                component={Link}
                color="primary"
                href={`mailto:${
                  record.auth0_email !== "" ? record.auth0_email : record.email
                }`}
                style={{ textDecoration: "none" }}
              >
                {record.auth0_email !== "" ? record.auth0_email : record.email}
              </Typography>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Phone
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record.phone !== "" ? record.phone : record.phone1}
              </Box>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Birthday
              </Typography>
              <Box fontWeight="fontWeightMedium">{record.birthday}</Box>
            </Box>
            <Box flex={2} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Hear About Us
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record.leadform_referral !== "" ? (
                  <HearAboutUs
                    data={record.leadform_referral}
                    otherDesc={record.leadform_referral_other}
                    referralCode={record.leadform_referral_code}
                  />
                ) : (
                  "no referral"
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} mb={5}>
          <Box display={{ xs: "block", sm: "flex" }} mb={3} mr={2}>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Injury
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record.leadform_referral !== "" ? (
                  <InjuryRelation data={record.leadform_injury_relation} />
                ) : (
                  "--"
                )}
              </Box>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Insurance
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record.leadform_insurance !== ""
                  ? record.leadform_insurance
                  : "--"}{" "}
                {record.leadform_insurance_number !== ""
                  ? record.leadform_insurance_number
                  : "--"}
              </Box>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
              <Typography mb={1} className="c-grey">
                Voice Reminders
              </Typography>
              <Box fontWeight="fontWeightMedium">
                {record && record.leadform_voice_reminders ? "Yes" : "No"}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} mb={5}>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <Typography mb={1} className="c-grey">
              Symptoms
            </Typography>
            <Box fontWeight="fontWeightMedium">
              {record.leadform_symptoms !== ""
                ? record.leadform_symptoms
                : "no symptoms"}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewPatientInfoTab;
