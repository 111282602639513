import { Button, FormGroup, Box } from "@mui/material"
import React, { useContext, useState, useEffect } from "react"
import { AUTH_LOGOUT } from "react-admin"
import authProvider from "../authProvider"
import { UserContext } from "../context/UserContext"
import { Card, Avatar, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';
import PasswordChecklist from "react-password-checklist"

import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { TextInput, Link } from 'react-admin';
import {
    Form,
    required,
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
} from 'ra-core';
import { Mixpanel } from '../mixpanel.js'
import config from '../config'
const authUrl = config.authUrl

const Sent = () => {

  return (
    <Root>
        <Card className={LoginClasses.card}>
            <div className={LoginClasses.avatar}>
                <Avatar className={LoginClasses.icon}>
                    <LockIcon />
                </Avatar>
            </div>
            <CardContent className={LoginClasses.content}>
                <div>
                    Your password has been successfully reset.  Please go back and sign in with your new password.
                </div>
            </CardContent>
        </Card>
    </Root>
  )
}

export default Sent

const PREFIX = 'Kaizo';
export const LoginClasses = {
    card: `${PREFIX}-card`,
    avatar: `${PREFIX}-avatar`,
    icon: `${PREFIX}-icon`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginClasses.button}`]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    [`& .${LoginClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));


const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${LoginClasses.content}`]: {
        width: 300,
    },    
    [`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },
}));