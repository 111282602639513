import React, { useState, useEffect } from 'react';
import { 
    ResponsiveContainer,
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip as RechartToolTip,
    Legend 
} from 'recharts';
import { Card, CardContent } from '@mui/material';
import dataProvider from '../dataProvider';

//not used?
/* const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  })); */

const renderColorfulLegendText = (value: string, entry: any) => { return <span style={{ color: "#828282", fontSize: "12px" }}>{value}</span>; };

export default function App(props) {

    const [data, setData] = useState<any | []>([]);
      
    function getAPIData() {
            // causing the server to crash - removing for now
            // dataProvider.get(`diag/sync-status`).then(result => { 
            //     setData(result.data);
            // });
    }


    useEffect(() => {
        // TODO the auth0Token in localstorage is set just before this is called in authProvider. figure out a better way to listen for change
        // checking for localStorage change doesnt work, something like
        // window.addEventListener("storage", e => storageChange(e));
        // return () => {
        //     window.removeEventListener('storage', e => storageChange(e));
        // };
        setTimeout(()=>{
            getAPIData();
        }, 100)
    }, []);

    return (
        <Card>
            <CardContent className='card-header'>
                Genesis Data Sync Status
            </CardContent>
            <CardContent>
                <div style={{ margin: 'auto', height: 400 }}>
                    <ResponsiveContainer>
                    <BarChart 
                    width={600} 
                    height={300} 
                    data={data} 
                    layout="vertical"
                    margin={{top: 5, right: 30, left: 90, bottom: 5}}
                    >
                        <XAxis type="number"/>
                        <YAxis type="category" dataKey="name" />
                        <CartesianGrid strokeDasharray="3 3"/>
                        <RechartToolTip/>
                        <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            formatter={renderColorfulLegendText}
                            iconType={'circle'}
                        />
                      <Bar dataKey="new" fill="#4444ff" />
                      <Bar dataKey="import_error" fill="#880000" />
                      <Bar dataKey="imported" fill="#0066FF" />
                      <Bar dataKey="imported_new" fill="#82ca9d" />
                      <Bar dataKey="process_error" fill="#ff0000" />
                      <Bar dataKey="processed" fill="#82ca9d" />
                      <Bar dataKey="total" fill="#777777" />
{/*                        <Bar dataKey="App Users" stackId="a" fill="#6FCF97" barSize={56} />
                        <Bar dataKey="Non-App Users" stackId="a" fill="#A3A3A3" barSize={56} />
*/}                    </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
}