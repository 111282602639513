import * as React from 'react';
import { Fragment } from 'react';
import {
    EditBase,
    SimpleForm,
    EditProps,
    TextField,
    Toolbar,
    useRecordContext,
    RichTextField,
    NumberInput,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Box, Grid, Stack, IconButton, Typography, ToggleButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Exerciselibrary } from '../../types';

import '../../scss/pages/Exerciselibrarys.scss';
import '../../scss/pages/Layouts.scss';

interface Props extends EditProps<Exerciselibrary> {
    onCancel: () => void;
    exerciseState: any;
}

const VideoField = (props) => {
  const record = useRecordContext(props);
  return <video poster={`${record.thumbnail}`} src={`${record.video}`} controls width="100%"></video>;
}
VideoField.defaultProps = { label: 'Video' };

const ExerciselibrarysEdit = ({ onCancel, exerciseState, ...props }: Props) => {

    const [editMode, setEditMode] = React.useState(false);

    return (
        <EditBase {...props} >
            <Box pt={5} width={{ xs: '100vW', sm: 600 }} mt={{ xs: 2, sm: 1 }}>
                <Stack direction="row" p={2}>
                    <Typography variant="h6" flex="1">
                        <TextField source="name" fontWeight="bold" fontSize="1em"/>
                    </Typography>
                    { localStorage.getItem('isAdmin') === 'true' ? <ToggleButton
                    value="Edit"
                    selected={editMode}
                    onChange={() => {
                        setEditMode(!editMode);
                    }}
                    >
                        Edit
                    </ToggleButton> : null}
                    <IconButton onClick={onCancel} >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {editMode ? 
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    toolbar={
                    <ExerciselibrarysEditToolbar 
                        // exerciseId={exerciseState.id} 
                        editMode={editMode}
                        />
                    }
                >
                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={8}>
                            <TextInput source='name' isRequired fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput 
                                isRequired
                                fullWidth
                                source="exercise_type"
                                emptyText={ "Exercise" } // Default values
                                emptyValue={ 'exercise' } // Default values
                                choices={[
                                    { id: 'exercise', name: 'Exercise' },
                                    { id: 'stretch', name: 'Stretch' },
                                ]} />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                        <Grid item xs={8}>
                            <TextInput source='video' isRequired fullWidth label='Video URL'/>
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source='video_time' isRequired fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={12}>
                            <TextInput source='thumbnail' isRequired fullWidth label='Thumbnail URL'/>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}  columnSpacing={1}>
                        <Grid item xs={3}>
                            <NumberInput source='sets' isRequired defaultValue={3} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='rest' isRequired defaultValue={30} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='reps' defaultValue={10} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumberInput source='hold' defaultValue={30} fullWidth/>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}>
                        <ArrayInput source="muscle_groups" isRequired label="Regions">
                            <SimpleFormIterator>
                                <ReferenceInput
                                    source=""
                                    reference="musclegroups"
                                    sort={{ field: 'name', order: 'ASC' }}
                                >
                                    <SelectInput 
                                    optionValue="name" optionText="name" fullWidth
                                    // source='name'
                                    isRequired/>
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1} >
                        <RichTextInput source='instructions' />
                    </Grid>
                </SimpleForm>
                : 
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    toolbar={false}
                >
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={12}>
                            <VideoField source="video" />
                        </Grid>
                        <Grid item xs={12}>
                            Duration: <TextField source='video_time' />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                Type
                            </Typography>
                            <TextField source='exercise_type' />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                Sets
                            </Typography>
                            <TextField source='sets' />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                Reps
                            </Typography>
                            <TextField source='reps' />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                Hold
                            </Typography>
                            <TextField source='hold' />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="subtitle1">
                                Rest
                            </Typography>
                            <TextField source='rest' />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}>
                        <ExerciselibrarysEditConditions />
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}>
                        <ExerciselibrarysEditMuscleGroups />
                    </Grid>
                    <Grid container rowSpacing={1} mb={1}>
                        <RichTextField source='instructions' />
                    </Grid>
                </SimpleForm>
                }
            </Box>
        </EditBase>
    );
};

const ExerciselibrarysEditConditions = (props) => {

    const record = useRecordContext();

    return (
        <Grid container rowSpacing={1} mb={1} mt={1}>
            {record.conditions && record.conditions.map((val, index) => (
                <Grid item xs={3} className='diagnosis-chip' >
                    {val}
                </Grid>
            ))}
        </Grid>
    )

}

const ExerciselibrarysEditMuscleGroups = (props) => {

    const record = useRecordContext();

    return (
        <Grid container rowSpacing={1} mb={1} mt={1}>
            {record.muscle_groups && record.muscle_groups.map((val, index) => (
                <Grid item xs={3} className='diagnosis-chip' >
                    {val}
                </Grid>
            ))}
        </Grid>
    )

}

const ExerciselibrarysEditToolbar = ( props ) => {

    return (
        <Toolbar
            sx={{
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'space-between',
                minHeight: { sm: 0 },
                marginBottom: "2em",
            }}
        >
            <Fragment>
                {/* <Button disabled label="ADD TO PATIENT HEP" onClick={
                    () => {
                        navigate(`/exercises/${props.exerciseId}`, {
                            state: {exercise_library_name: record.condition_id }
                        });
                    }
                }></Button> */}
                { props.editMode ? <><SaveButton /><DeleteButton /></> : <></> }
            </Fragment>
        </Toolbar>
    );
};

export default ExerciselibrarysEdit;
