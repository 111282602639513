import { Box } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    CreateButton
} from 'react-admin';

const MusclegroupsList = () => {

    return (
        <Box>
            <List
                actions={<ListActions />}
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    bulkActionButtons={false}
                >
                    <TextField source="name" />
                </Datagrid>
            </List>
        </Box>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export default MusclegroupsList;
