import { useEffect, useState } from "react";
import { ChannelMemberResponse } from "stream-chat";
import { useChatContext } from "stream-chat-react";

export const CustomChannelSearchResultItem = ({ result, index, focusedUser, selectResult }: { result: any; index: any; focusedUser?: any; selectResult: any; }) => {
  const { client } = useChatContext();
  const [otherUser, setOtherUser] = useState<any>({});
  
  // Find the other user in the channel
  useEffect(() => {
    const members: ChannelMemberResponse[] = Object.values(result.state.members);
    const other = members.find((member: ChannelMemberResponse) => member.user?.id !== client.userID);
    if (other && other.user) setOtherUser(other.user);
  }, [result, client.userID]);
  
  if (!otherUser) return null;
  
  return (
    <button 
      className='str-chat__channel-search-result'
      onClick={() => {selectResult(result)}}
    >
      <div className="str-chat__avatar str-chat__avatar--circle str-chat__message-sender-avatar">
        <img 
          src={otherUser.image || `https://getstream.io/random_svg/?name=${encodeURIComponent(otherUser.name ? otherUser.name.split(" ")[0] : 'User')}`} 
          alt={otherUser.name || 'User'} 
          style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
        />
      </div>
      <div
        className="str-chat__channel-search-result--display-name"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {otherUser.name || otherUser.id}
      </div>
    </button>
  );
};