import { useEffect } from "react";
import { useGetOne } from "react-admin";

export function MessagesRecordWrapper({ setRecord, currentChannelPatient }) {
  const record = useGetOne('users', { id: currentChannelPatient?.user_id }).data;

  useEffect(() => {
    setRecord(record);
  }, [record, setRecord])

  return null
}