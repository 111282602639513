import { Chip } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';
import { Messagelog } from '../../types';
import { useEffect, useState } from 'react';

// const segmentsById = segments.reduce((acc, segment) => {
//     acc[segment.id] = segment;
//     return acc;
// }, {} as { [key: string]: any });

const SegmentsField = (props: FieldProps) => {
  const record = useRecordContext<Messagelog>();

  const [label, setLabel] = useState('');

  const labelMap = {
    'firstTrue': 'Conditions',
    'event': 'Event',
    'appointment': 'Reminder/Missed',
    'time': 'Scheduled',
    'now': 'Instant'
  }

  useEffect(() => {
    setLabel(labelMap[record.type])
  }, [])

  return (
    <Chip
        sx={{
          'maxWidth': '100%',
          'display': 'inline-flex',
          'alignItems': 'center',
          'justifyContent': 'center',
          'height': '32px',
          'paddingLeft': '4px',
          'paddingRight': '4px',
        }}
        size="small"
        key={label}
        label={label}
    />
  );
};

export default SegmentsField;