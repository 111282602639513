import * as React from 'react';
import { Create, TextInput, required, SimpleForm } from 'react-admin';
import { Box, Typography } from '@mui/material';


const MusclegroupsCreate = () => {
    return (
        <Create>
            <SimpleForm
                sx={{ maxWidth: '80%' }}
            >

                <Typography variant="h4" gutterBottom mb={3}>
                    <Box fontWeight='fontWeightBold'>
                        Edit Region
                    </Box>
                </Typography>
                <TextInput
                    autoFocus
                    source="name"
                    validate={required()}
                    fullWidth
                />
            </SimpleForm>
        </Create>
    );
};

export default MusclegroupsCreate;
