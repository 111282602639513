import * as React from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MessageIcon from '@mui/icons-material/Message';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampaignIcon from '@mui/icons-material/Campaign';
import SmsIcon from '@mui/icons-material/Sms';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { termsOfUse } from '../TermsofUse';

import {
    MenuItemLink,
    MenuProps,
    fetchUtils,
} from 'react-admin';

import '../scss/pages/Menu.scss';
import { Badge, Input } from '@mui/material';
import tokenManager from '../tokenManager';

const menuStyles = {
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 1,
    fontSize: '.75em',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#AAAAAA'
}

const Menu = ({ dense = false, unreadCount }: MenuProps) => {

    // @TODO this is for display purposes only it does not enforce anything - that still needs to be done on the backend

    let providerMenu;
    if (localStorage.getItem('isStaff') === 'true') {
        providerMenu = <Box sx={menuStyles} >
            Provider
            {/* <DashboardMenuItem /> */}

            {/* @ts-ignore - incorrectly erroring on missing onPointerEnterCapture and onPointerLeaveCapture props */}
            <MenuItemLink
                to="/users"
                state={{ _scrollToTop: true }}
                primaryText="Patients"
                leftIcon={<PersonIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/exerciselibrarys"
                state={{ _scrollToTop: true }}
                primaryText="Exercises"
                leftIcon={<FitnessCenterIcon />}
                dense={dense}
            />
            <div style={{ position: 'relative' }}>
              <Badge
                className="sidebar-menu__messages-badge"
                badgeContent={unreadCount}
                color="error"
                sx={{
                  position: 'absolute',
                  top: '25px',
                  left: '40px',
                }}
              />
              {/* @ts-ignore */}
              <MenuItemLink
                  to="/messages"
                  state={{ _scrollToTop: true }}
                  primaryText="Messages"
                  leftIcon={<MessageIcon />}
                  dense={dense}
              />
            </div>
            {/* @ts-ignore */}
            <MenuItemLink
                to="/billings"
                state={{ _scrollToTop: true }}
                primaryText="Billings"
                leftIcon={<AttachMoneyIcon />}
                dense={dense}
            />

            </Box>
    }

    let marketingMenu;
    if (localStorage.getItem('isConnectUser') === 'true' && localStorage.getItem('embodiConnectEnabled') === 'true') {
        marketingMenu = <Box sx={menuStyles} >
            Connect

            {/* @ts-ignore */}
            <MenuItemLink
                to="/messagebroadcasts/create"
                state={{ _scrollToTop: true }}
                primaryText="Broadcasts"
                leftIcon={<CampaignIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/messagetemplates"
                state={{ _scrollToTop: true }}
                primaryText="Templates"
                leftIcon={<SmsIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/messageautomations"
                state={{ _scrollToTop: true }}
                primaryText="Automations"
                leftIcon={<AutoAwesomeIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/messagelogs"
                state={{ _scrollToTop: true }}
                primaryText="Logs"
                leftIcon={<WatchLaterIcon />}
                dense={dense}
            />
            </Box>
    }

    let adminMenu;
    if (localStorage.getItem('isAdmin') === 'true') {
        adminMenu = <Box sx={menuStyles} >
            Admin

            {/* <MenuItemLink
                to="/exercises"
                state={{ _scrollToTop: true }}
                primaryText="Exercises"
                leftIcon={<LabelIcon />}
                dense={dense}
            /> */}
            {/* @ts-ignore */}
            <MenuItemLink
                to="/conditions"
                state={{ _scrollToTop: true }}
                primaryText="Conditions"
                leftIcon={<LabelIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/musclegroups"
                state={{ _scrollToTop: true }}
                primaryText="Regions"
                leftIcon={<LabelIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/exerciselibrarys"
                state={{ _scrollToTop: true }}
                primaryText="Exercises"
                leftIcon={<LabelIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/masterprograms"
                state={{ _scrollToTop: true }}
                primaryText="Protocols"
                leftIcon={<LabelIcon />}
                dense={dense}
            />
            </Box>           

    }

    let complianceMenu;
    if (localStorage.getItem('isCompliance') === 'true') {
        complianceMenu = <Box sx={menuStyles} >
            Compliance

            {/* @ts-ignore */}
            <MenuItemLink
                to="/logs"
                state={{ _scrollToTop: true }}
                primaryText="Logs"
                leftIcon={<AssignmentTurnedInIcon />}
                dense={dense}
            />

            </Box>
    }

    const [currentEmail, setCurrentEmail] = React.useState("")
    const [emailConfigDialogOpen, setEmailConfigDialogOpen] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [emailError, setEmailError] = React.useState(false)
    const [emailSubmitting, setEmailSubmitting] = React.useState(false)

    const handleEmailConfigClick = () => {
      const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
      const httpClient = fetchUtils.fetchJson;
      const accessToken = tokenManager.getToken();

      const practice = localStorage.getItem('selectedPractice')

      httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-reply-to-email?practiceId=` + practice, {
        method: 'GET',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
      }).then((result) => {
          setCurrentEmail(result.json['email'])
      }).catch((e)=> {
          console.log(`Error in Menu:useEffect()`)
          console.error(e)
          throw e
      })

      setEmailConfigDialogOpen(!emailConfigDialogOpen)
    }

    const handleEmailConfigDialogClose = () => {
      setEmailConfigDialogOpen(false)
      setEmail("")
    }

    const emailChangeHandler = (event) => {
      setEmail(event.target.value)
    }

    const handleChangeEmail = () => {
      setEmailError(false)
      setEmailSubmitting(true)

      var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (!re.test(email)) {
        console.log('invalid email')
        setEmailError(true)
        setEmailSubmitting(false)
        return
      }
      const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
      const httpClient = fetchUtils.fetchJson;
      const accessToken = tokenManager.getToken();

      const practice = localStorage.getItem('selectedPractice')

      httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/put-reply-to-email`, {
        method: 'PUT',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          practiceId: practice,
          email: email,
        }),
      }).then((result) => {
          // console.log(result.json)
          setCurrentEmail(result.json['email'])
      }).catch((e)=> {
          console.log(`Error in Menu:useEffect()`)
          console.error(e)
          throw e
      })

      setEmailSubmitting(false)
      setEmailConfigDialogOpen(false)
      setEmail("")
    }

    let settingsMenu;
    if (localStorage.getItem('isConnectUser') === 'true' && localStorage.getItem('embodiConnectEnabled') === 'true') {
        settingsMenu = <Box sx={menuStyles} >
            Settings

            {/* @ts-ignore */}
            <MenuItemLink
                to="/providernotifications"
                state={{ _scrollToTop: true }}
                primaryText="Notifications"
                leftIcon={<NotificationsActiveIcon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to={window.location.hash.replace('#', '')}
                onClick={handleEmailConfigClick}
                state={{ _scrollToTop: true }}
                primaryText="Emails"
                leftIcon={<EmailIcon />}
                dense={dense}
            />

        </Box>
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpenTerms = () => {
      setOpen(true);
    };
  
    const handleCloseTerms = () => {
      setOpen(false);
    };

    const handleClickPrivacy = () => {
        window.open("https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/");
    }

    return (
        <Box
            sx={{
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
            className="sidebar-menu"
        >
            {providerMenu}
            {marketingMenu}
            {adminMenu}
            {complianceMenu}
            {settingsMenu}
            <div>
                <Dialog
                    open={open}
                    //onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    {"Terms of Use"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <span dangerouslySetInnerHTML={{ __html: termsOfUse }} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTerms} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={emailConfigDialogOpen}
                    onClose={handleEmailConfigDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                    {"Email Configuration"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {"Current reply-to email address: " + currentEmail}
                        </DialogContentText>
                        <Input 
                            error={emailError}
                            id="email"
                            type="email"
                            placeholder="Enter new reply-to email"
                            value={email}
                            onChange={emailChangeHandler}
                            style={{
                              paddingTop: '25px',
                              width: '100%',
                              height: '100%'
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEmailConfigDialogClose} autoFocus>
                            Cancel
                        </Button>
                        <Button onClick={handleChangeEmail} autoFocus disabled={emailSubmitting}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Box className="settings">
                <Box sx={{display: 'flex', flexDirection: 'row'}} onClick={handleClickPrivacy}>
                    <Box sx={{width: 16}}/>
                    <PersonIcon />
                    <Box sx={{width: 10}}/>
                    Privacy Policy
                </Box> 
                <Box sx={{height: 10}}/>
                <Box sx={{display: 'flex', flexDirection: 'row'}} onClick={handleClickOpenTerms}>
                    <Box sx={{width: 16}}/>
                    <LibraryBooksIcon />
                    <Box sx={{width: 10}}/>
                    Terms of Use
                </Box>
            </Box>
        </Box>
    );
};

export default Menu;





// import { MenuItemLink } from 'react-admin';
// import LabelIcon from '@mui/icons-material/Label';

// const Menu = ({ onMenuClick, logout }) => {
//     return (
//         <div>
//             {/* used to use react-redux to getResources but it's been replaced by react-query in react-admin, need to come up with a solution for this if needed*/}
//             <MenuItemLink
//                 to="/"
//                 primaryText="Dashboard"
//                 leftIcon={<LabelIcon />}
//                 onClick={onMenuClick}
//             />
//         </div>
//     );
// }

// export default Menu;