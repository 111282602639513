import { 
    Avatar,
    Box,
    Button
} from '@mui/material';
import { format, intervalToDuration, formatDuration } from 'date-fns';
import { 
    Datagrid,
    useRecordContext,
    useUpdate,
    FunctionField,
    FieldProps,
    useGetIdentity,
    Pagination,
    List,
    useRedirect
} from 'react-admin';
import { useEffect } from 'react';
import { Patient } from '../../types';
import dataProvider from '../../dataProvider';
import tokenManager from '../../tokenManager';
import { logActivity } from '../../logActivity.js'
import SessionTimeout from '../../SessionTimeout';

const ActivityLog = ({onSelectActivity}, props: FieldProps<Patient>) => {

    var date = Number(localStorage.getItem('testDate'))
    if (!date) {
        date = 0
    }
    
    const patientRecord = useRecordContext<Patient>();

    const [update, { isLoading }] = useUpdate();
    const { identity: user, isLoading: identityLoading } = useGetIdentity();
    const redirect = useRedirect()

    var startTime = new Date();

    // add new activity log
    const updateWithNewRecord = async (newActivity) => {

        // dont log if duration too small
        if (newActivity.duration <= 1) {
            return
        }

        var currRecord = patientRecord;
        if(!isLoading && tokenManager.getToken()) {
            await dataProvider.getOne(`users`, patientRecord).then(result => {
                currRecord = result.data;
            });
        }

        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;

        if( currDate == null || 
            currDate === "" ||
            currTime == null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }

        startTime = new Date();
        
        if( currBillingDuration == null || 
            currBillingDuration === 0) {
            currBillingDuration = newActivity.duration
        } else {
            currBillingDuration = currRecord.current_billing_duration + newActivity.duration
        }

        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
        };

        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )

        logActivity('PROVIDER.View.Log', newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)
    }

    // Add activity log when moving away from Activity Log
    useEffect(
        () => {
            return () => {
                const duration = Math.round((new Date().getTime() - startTime.getTime())/1000);
                var newActivity = {
                    "date": Math.round(Date.now()/1000),
                    "duration": duration,
                    "activity": "Viewed Activity Log",
                    "first": user?.firstName ?? "",
                    "last": user?.lastName ?? "",
                    "email": user?.email ?? "",
                    "picture": user?.picture ?? "",
                    "comment": "None",
                    "billable": false // TODO check to see if the current viewing period is billable
                };
                updateWithNewRecord(newActivity);
            }
        },
        [!identityLoading]
    );

    const formatSeconds = (seconds) => {
        const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
        const zeroPad = (num) => String(num).padStart(2, "0");
        const fSeconds = formatDuration(duration, {
            format: seconds >= 3600 ? ["hours", "minutes", "seconds"] : ["minutes", "seconds"],
            zero: true,
            delimiter: ":",
            locale: {
            formatDistance: (_token, count) => zeroPad(count)
            }
        });
        return fSeconds;
    }

    const LogPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props}/>

    return (
        <div>
            <SessionTimeout isAuthenticated={true} logOut={() => {redirect('/users')}}/>
            <Box className="activity-log-list-container" >
                <List
                    resource={'logentries'}
                    filter={{user: patientRecord.id, group: 'activity', split: {'!=': true}}}
                    sort={{ field: 'date', order: 'DESC' }}
                    actions={ false }
                    pagination={<LogPagination/>}
                >
                    <Datagrid
                        bulkActionButtons = { false }
                        optimized
                        sx={{
                            '& .column-groups': {
                                md: { display: 'none' },
                                lg: { displey: 'table-cell' }
                            }
                        }}
                    >
                        <FunctionField render={record => <EditField onSelectActivity={onSelectActivity} log={record}/>} />
                        <FunctionField label="Date" render={record => record && record.date && new Date(record.date * 1000).toLocaleString()} />
                        <FunctionField label="Duration" render={record => record && record.duration && formatSeconds(record.duration)} />
                        <FunctionField label="Activity" render={record => record && record.subject} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '3%'}}>
                            <FunctionField render={record => <Avatar src={record.image} style={{width: 25, height: 25}} />} /> 
                            <FunctionField label="Provider" render={record => record && record.log_name} />
                        </Box>
                        <FunctionField label="Email" render={record => record && record.email} />
                        <FunctionField label="Comment" render={record => record && record.comment && record.data && record.data['note'] ? record.comment + " - Note: " + record.data['note'] : record.comment} />
                    </Datagrid>
                </List>
            </Box>
        </div>
    )
}

const EditField = ({onSelectActivity, log}) => {
    return (
        <Button onClick={() => {
            onSelectActivity(log);
        }}>Edit</Button>
    )
}

export default ActivityLog;
