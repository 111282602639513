import { Box } from '@mui/material';
import {
    CreateButton,
    Datagrid,
    FunctionField,
    List,
    TextField,
    TopToolbar,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ListAside from './ListAside';
import '../../scss/pages/EnhancedMessaging.scss';

const MessageTemplateList = () => {
    return (
        <Box mt={2} className="template-container"> 
            <List
            // sorting by updated date to get most recently modified templates
                sort={{ field: 'updatedAt', order: 'DESC' }}
                aside={<ListAside />}
                actions={<ListActions />}
                filter={{ is_broadcast: false, is_provider_notification: false }}
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                    bulkActionButtons={false} 
                >
                    <TextField source="name" label="Template Name" />
                    <FunctionField label="Excerpt" sortBy="sms_message" render={(record:any) => record && record.sms_message && record.sms_message.length > 30 ?  record.sms_message.slice(0, 20) + "..." : record.sms_message} />;
                    <TextField source="event_type" label="Event Type" />
                    <FunctionField label="Last Updated" sortBy="updatedAt" render={(record: any) => record && record.updatedAt !== 0 ? new Date(record.updatedAt).toLocaleString() : "N/A"} />
                    <FunctionField label="Last Sent" sortBy="last_sent" render={(record:any) => record && record.last_sent !== 0 ? new Date(record.last_sent*1000).toLocaleString() : "N/A"} />
                    <FunctionField label="In Use" sortBy="in_use" render={(record:any) => record && record.in_use ?  <CheckIcon /> : <CloseIcon />} />;
                </Datagrid>
            </List>
        </Box>
    )
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

export default MessageTemplateList