import { fetchUtils } from 'react-admin';
import tokenManager from './tokenManager';

const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
const httpClient = fetchUtils.fetchJson;

export async function logAcceptTerms(version) {
    const accessToken = tokenManager.getToken('AuthToken');

    //change this for new log terms enpoint
    await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/auth/terms`, {
        method: 'POST',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            version,
        }),
    }).catch((e)=> {
        console.log(`Error in logAcceptTerms:logAcceptTerms()`)
        console.error(e)
        return false
    })
    return true
}