import { useEffect } from "react";
import { useGetList } from "react-admin";

export function QueryBuilderGetList({ setRecord, recordType }) {
  const record = useGetList(recordType).data;

  useEffect(() => {
    setRecord(record);
  }, [record, setRecord])

  return null
}