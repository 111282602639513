export const termsOfUse = `<html><head>
<link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic&#038;subset=cyrillic,cyrillic-ext,greek,greek-ext,hebrew,latin,latin-ext,vietnamese&#038;display=swap"/>
<style>
</style>
</head><body>
<h1>
    <strong>KAIZENOVATION TECH, LLC</strong>
</h1>
<h1>
    <strong>PROVIDER PORTAL TERMS OF USE</strong>
</h1>
<p>
    <strong>
        YOU SHOULD CAREFULLY READ THE PROVIDER PORTAL TERMS OF USE (
    </strong>
    <strong>the “Terms”) BEFORE USING THE KAIZENOVATE PROVIDER PORTAL </strong>
    <strong>(the “Provider Portal”). </strong>
    The Provider Portal, including all relevant content and functionality
    associated with the Provider Portal, are collectively referred to as the
    “Services.”
</p>
<p>
    <strong>
        BY CREATING A USER ACCOUNT AND USING THE SERVICES, YOU ARE CONSENTING
        TO BE BOUND BY THE TERMS. IF YOU DO NOT AGREE TO ACCEPT THE TERMS, DO
        NOT LOG IN TO OR USE THE SERVICES.
    </strong>
</p>
<p>
    Any information that Kaizenovate Tech, LLC (“<strong>Kaizenovate</strong>”
    or “<strong>We</strong>”) collects through Your use of the Services is
    subject to the
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    , which is part of these Terms.
</p>
<p>
    <u>By continuing to use the Services, You agree as follows</u>
    :
</p>
<p>
1. You are at least 18 years old or have been legally emancipated;
</p>
<p>
    2. You understand and agree that these Terms are a legally binding
agreement and the equivalent of a signed, written contract;
</p>
<p>
    3. You will use the Services in a manner consistent with applicable laws
    and regulations and these Terms, as they may be amended by Kaizenovate from
    time to time; and
</p>
<p>
    4. You understand, accept, and have received these Terms and the
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    , and acknowledge and demonstrate that You can access these Terms and the
    Provider Portal Privacy Policy at will.
</p>
<p>
    <strong>
        If You do not agree with and accept the Terms, please discontinue all
        further use of the Services.
    </strong>
    Do not log into the Provider Portal and immediately delete all files, if
    any, associated with the accompanying Services from your computer or mobile
    device.
</p>
<p>
    <strong>
        <u>ARBITRATION NOTICE</u>
        : EXCEPT IF YOU OPT-OUT AND EXCEPT FOR CERTAIN TYPES OF DISPUTES
        DESCRIBED IN THE DISPUTE RESOLUTION SECTION BELOW, YOU AGREE THAT
        DISPUTES BETWEEN YOU AND KAIZENOVATE WILL BE RESOLVED BY BINDING,
        INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
        CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION. YOU CAN OPT-OUT OF THE
        ARBITRATION AGREEMENT BY CONTACTING CUSTOMERSUPPORT@KAIZENOVATE.COM
        WITHIN 30 DAYS OF ACCEPTING THESE TERMS.
    </strong>
</p>
<br clear="all"/>
<h1 align="center">
    <strong>Provider Portal Terms of Use</strong>
</h1>
<p>
    Effective: <strong>January 30, 2023</strong>
</p>
<p align="left">
    Last Updated: January 30, 2023
</p>
<p>
    <strong>
        These Provider Portal Terms of Use ("Provider Portal Terms of Use" or
        “Terms”) are a legal contract between you (“You/Your” or "User") and
        Kaizenovate Tech, LLC (“Us” or “We” or “Our” or “Kaizenovate”).
    </strong>
Kaizenovate is the creator of the Kaizenovate provider portal (the “    <strong>Provider</strong> <strong>Portal</strong>”). Kaizenovate’s Provider
    Portal, including all relevant content and functionality associated with
the Provider Portal, are collectively known as the “    <strong>Services</strong>.” These Terms govern Your use of the Services and
    apply to healthcare providers (“<strong>Provider Users</strong>”) as well
    as administrative personnel (“<strong>Administrators</strong>”) who access
    and use the Services (collectively, “<strong>Users</strong>”). By accepting
    these Terms and using the Services, You acknowledge that You have read,
    understand, and agree to these Terms and Our
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    . Any capitalized terms not defined in these Terms are defined in the
    Provider Portal Privacy Policy.
</p>
<h2>
    <strong>WHAT ARE THE SERVICES?</strong>
</h2>
<p>
    The Provider Portal is intended to provide a convenient platform for
    Provider Users to proactively track and analyze their patients’ health
    conditions through pertinent health data to track time spent providing
    remote therapeutic monitoring services to patients, and to communicate with
    patients about their health.
    <strong>
        The Services are not intended for use by healthcare providers or
        patients for continuous patient monitoring in such a way that would
        allow immediate clinical action in an emergency situation.
    </strong>
</p>
<p>
    You may access and use the Services only in accordance with these Terms,
    and You agree to comply with all applicable laws, rules, and regulations,
    including any other policies incorporated into these Terms, such as Our
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    . Kaizenovate is acting solely as a technology platform that enables You to
    provide healthcare services and connect with Your patients. We do not
    supervise or direct You in Your provision of such healthcare services to
    Your patients. We do not make any representations or warranties that the
    clinical services You are providing are suitable, reliable, timely or
    accurate for the patient, all of which are Your responsibility.
</p>
<h2>
    <strong>WHAT DOES Kaizenovate NOT PROVIDE?</strong>
</h2>
<p>
    <strong><u>We DO NOT provide clinical services.</u></strong>
</p>
<p>
    THE SERVICES CANNOT AND ARE NOT DESIGNED, INTENDED, OR APPROPRIATE TO
    REPLACE OR SUBSTANTIVELY IMPACT YOUR PROVIDER-PATIENT RELATIONSHIP WITH
    OTHER USERS TO ADDRESS SERIOUS, EMERGENT, OR LIFE-THREATENING MEDICAL
    CONDITIONS AND SHOULD NOT BE USED IN THOSE CIRCUMSTANCES.
</p>
<p>
    <strong>
        <u>We DO NOT provide insurance billing services or guarantees.</u>
    </strong>
</p>
<p>
    We provide a convenient platform for You to track information that may
    allow You to provide certain services that may or may not be reimbursable
    by federal or state health insurance payors and to generate billing
    reports. We are not billing experts and any information provided to You
    with respect to billing is for informational purposes only and should not
    be relied upon or construed to guarantee reimbursement of any services.
</p>
<h2>
    <strong>WHO IS ELIGIBLE TO USE THE SERVICES?</strong>
</h2>
<p>
    You must register to create an account (“<strong>User Account</strong>”)
    and become a registered user to access the Services. The registration
    process begins when Kaizenovate or Your designated Administrator enters
    Your demographic information (e.g., name, email address, contact
    information, role, and licensure information) in the Provider Portal
    (collectively, Your “<strong>Registration Data</strong>”). From there, You
    will receive an email inviting You to register to use the Services. To
    register, You must create a password. You may change or correct information
    by accessing Your User Account dashboard or by contacting
    customersupport@kaizenovate.com. You agree not to register for a User
    Account on behalf of an individual other than Yourself unless You are
    legally authorized to bind such person to these Terms. By registering
    another person, You hereby represent that You are legally authorized to do
    so.
</p>
<p>
By registering for an account and using the Services,    <strong>You represent and warrant as follows</strong>:
</p>
<ol start="1" type="1">
    <li>
        You are at least 18 years old and are otherwise legally qualified to
        enter into and form contracts under applicable law;
    </li>
    <li>
        Your Registration Data is true, accurate, current, and complete;
    </li>
    <li>
        You will update Your Registration Data as needed to maintain its
        accuracy;
    </li>
    <li>
        You are authorized to create a User Account (either for Yourself or
        another person);
    </li>
    <li>
        You acknowledge and agree to the terms of the
        <a target="_blank"
            href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
        >
            Provider Portal Privacy Policy
        </a>
        ;
    </li>
    <li>
        You are legally authorized to view information stored in the Provider
        Portal; and
    </li>
    <li>
        You are not located in a country that is subject to a U.S. Government
        embargo or that is designated by the U.S. Government as a “terrorist
        supporting” country, and You are not listed on any U.S. Government list
        of prohibited or restricted parties.
    </li>
</ol>
<p>
    <strong><u>NOTE</u></strong>
    : THIS AGREEMENT IS VOID WHERE PROHIBITED BY LAW. DO NOT USE THE SERVICES
    WHERE PROHIBITED BY LAW. YOU UNDERSTAND THAT YOUR USE OF THE SERVICES MAY
    INVOLVE OR REQUIRE THE TRANSMISSION OF SIGNIFICANT AMOUNTS OF DATA. YOU ARE
    RESPONSIBLE FOR ALL DATA CHARGES THAT MAY BE CHARGED BY YOUR WIRELESS
    CARRIER OR INTERNET SERVICE PROVIDER OR THAT MAY OTHERWISE ARISE FROM YOUR
    USE OF THE SERVICES.
</p>
<h2>
    <strong>
        HOW WILL KAIZENOVATE NOTIFY YOU OF CHANGES TO THESE
        TERMS?
    </strong>
</h2>
<p>
    With the exception of the Arbitration Agreement (see the “Dispute
    Resolution” section) included near the end of these Terms, Kaizenovate
    reserves the right to change or modify these Terms at any time without
    prior notice to You. If We materially change or modify these Terms, We will
    let You know by (1) posting a new version of the Terms to the Provider
    Portal; and/or (2) sending You a notification to the email address provided
    to Use by Your Administrator in the Registration Data.
</p>
<p>
    If You continue to use the Services after we have informed You of the
    changes, You agree to be bound by the modified Terms. If You do not accept
    the changes, You should immediately stop using the Services and delete all
    files associated with the Services on Your devices.
</p>
<h2>
    <strong>WHO OWNS THE SERVICES AND PERSONAL DATA?</strong>
</h2>
<p>
    <strong><u>Services Ownership and Use.</u></strong>
</p>
<p>
    Kaizenovate owns the Services, including all content and functionality You
    access through the Services. Subject to Your compliance with these Terms,
    Kaizenovate grants You a non-exclusive, non-sublicensable, revocable,
    non-transferable license to use the Services by accessing the Provider
    Portal on Your device.
</p>
<p>
    <strong>
        PURSUANT TO THESE TERMS, ONLY YOU MAY USE AND ACCESS THE SERVICES. YOU
        MAY NOT RESELL OR SUBLICENSE YOUR ABILITY TO USE AND ACCESS THE
        SERVICES
    </strong>
    . You may not use the Services for any other purpose than what is allowed
    under these Terms without Kaizenovate’s express written permission.
</p>
<p>
    You may not use Kaizenovate’s name, trademarks, service marks, or logos, or
    those of third parties appearing on the Services in any advertising or
    publicity or to otherwise indicate Kaizenovate’s or such third party’s
    sponsorship or affiliation with any product or service without express
    written permission from Kaizenovate or such third party.
</p>
<p>
    <strong><u>Personal Data Ownership and Use.</u></strong>
</p>
<p>
    You own Your Personal Data (as defined in the Provider Portal Privacy
    Policy) and any other information You submit on or through the Services
    (collectively, “<strong>User Information</strong>”). If You are entering
    someone else’s information into the Services, You represent and warrant
    that You have permission to do so. For Us to provide You with the Services,
    You grant to Kaizenovate a perpetual, non-exclusive, fully paid and
    royalty-free, transferable, sublicensable, worldwide license to use Your
    User Information for the purpose of providing the Services, subject to the
    restrictions in the
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    . You also agree to allow Kaizenovate to de-identify and anonymize Your
    User Information, in accordance with the
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    , and to use or disclose such de-identified information for any purpose.
</p>
<h2>
    <strong>
        WHAT ARE YOU NOT ALLOWED TO DO WITH THE SERVICES?
    </strong>
</h2>
<p>
    You may use the Services only for lawful purposes and in accordance with
    these Terms. In addition, We impose certain restrictions on Your use of the
    Services. While using the Services, <strong><u>You shall not</u></strong>:
</p>
<p>
    1. Provide false, misleading, or inaccurate information to Us or any other
    user;
</p>
<p>
    2. Use the Services (i) for any commercial purpose; (ii) for the benefit of
    any third party; or (iii) in any manner not permitted by these Terms;
</p>
<p>
    3. Impersonate or attempt to impersonate Us, one of Our employees, another
    user, or any other person or entity (including, without limitation, by
    using e-mail addresses or screen names associated with any of the
    foregoing);
</p>
<p>
    4. Use or attempt to use any manual process, engine, software, tool, agent,
    or other device or mechanism (including, without limitation, browsers,
    spiders, robots, avatars, or intelligent agents) to harvest, monitor, or
    otherwise collect information from the Services for any use, including,
    without limitation, use on third-party websites, without Our consent;
</p>
<p>
    5. Use the Services in any manner that could disable, overburden, damage,
    or impair the Services or interfere with any other party's use of the
    Services, including their ability to use the Services;
</p>
<p>
    6. Access content or data not intended for You, or log onto a server or
    account that You are not authorized to access;
</p>
<p>
    7. Violate any applicable federal, state, local, or international law or
    regulation (including, without limitation, any laws regarding the export of
    data or software to and from the U.S. or other countries);
</p>
<p>
    8. Attempt to probe, scan, or test the vulnerability of the Services or any
    associated system or network, or breach security or authentication measures
    without proper authorization;
</p>
<p>
    9. Interfere or attempt to interfere with the use or functionality of the
    Services by any other user, host, or network, including, without
    limitation, by means of submitting a virus, trojan horse, worm, logic bomb
    or other material which is malicious or technologically harmful,
    overloading, "flooding," "spamming," "mail bombing," or "crashing";
</p>
<p>
    10. Forge any TCP/IP packet header or any part of the header information in
    any e-mail or in any uploading or posting to, or transmission, display,
    performance, or distribution by means of, the Services;
</p>
<p>
    11. Post or transmit any unsolicited advertising, promotional materials,
    "junk mail," "spam," "chain letters," "pyramid schemes," or any other form
    of solicitation;
</p>
<p>
    12. Post, upload, publish, submit, or transmit any content that: (i)
    infringes, misappropriates, or violates a third party’s patent, copyright,
    trademark, trade secret, moral rights, or other intellectual property
    rights, or rights of publicity or privacy; (ii) violates, or encourages any
    conduct that would violate, any applicable law or regulation or would give
    rise to civil liability; (iii) is fraudulent, false, misleading, or
    deceptive; (iv) is defamatory, obscene, pornographic, vulgar, or offensive;
    (v) promotes discrimination, bigotry, racism, hatred, harassment, or harm
    against any individual or group; (vi) is violent or threatening or promotes
    violence or actions that are threatening to any person or entity; or (vii)
    promotes illegal or harmful activities or substances;
</p>
<p>
    13. Exploit, harm, or attempt to exploit or harm minors in any way by
    exposing them to inappropriate content, asking for personally identifiable
    information, or otherwise;
</p>
<p>
    14. Avoid, bypass, remove, deactivate, impair, descramble, or otherwise
    circumvent any technological measure implemented by Us, You, or any other
    third-party (including another user) to protect the Services;
</p>
<p>
    15. Attempt to modify, reverse-engineer, decompile, disassemble, or
    otherwise reduce or attempt to reduce to a human-perceivable form any of
    the source code used by Us in providing the Services. Any violation of this
    section may subject You to civil and/or criminal liability;
</p>
<p>
    16. Engage in any other conduct that restricts or inhibits anyone's use or
    enjoyment of the Services, or which, as determined by Us, may harm Us or
    users of the Services or expose them to liability, or otherwise interfere
    with or attempt to interfere with the proper working of the Services;
</p>
<p>
    17. Use the Provider Portal in any manner that could disable, overburden,
    damage, or impair the Provider Portal or interfere with the Provider Portal
    or any other party’s use of the Provider Portal;
</p>
<p>
    18. Attack the Provider Portal via a denial-of-service attack or a
    distributed denial-of-service attack; or
</p>
<p>
    18. Encourage or enable any other individual to do any of the above.
</p>
<p>
    Kaizenovate is not obligated to monitor Your use of the Services, but We
    may do so to ensure Your compliance with these Terms, and/or to respond to
    law enforcement or other government agencies if and when We are required
    to. Kaizenovate reserves the right to suspend or terminate Your use of the
    Services without notice to You if You engage in any of the prohibited uses
    described above.
</p>
<h2>
    <strong>
        HOW SHOULD YOU PROTECT YOUR LOGIN INFORMATION?
    </strong>
</h2>
<p>
    The Services are designed to require Provider Users to create a password to
    access and use the Services, Your “<strong>User Credentials</strong>.” You
    are solely responsible for (i) maintaining the strict confidentiality of
    Your User Credentials, (ii) not allowing another person to use Your User
    Credentials to access the Services, and (iii) any and all damages or losses
    that may be incurred or suffered as a result of any activities that occur
    under Your User Credentials, regardless of whether You were aware of those
    activities. You agree to immediately notify Kaizenovate in writing by email
    of any unauthorized use of Your User Credentials or any other compromise of
    the security of Your User Account.
</p>
<p>
    WE WILL NOT BE LIABLE FOR ANY LOSS THAT YOU INCUR AS A RESULT OF SOMEONE
    ELSE USING YOUR PASSWORD, EITHER WITH OR WITHOUT YOUR KNOWLEDGE. WE ARE NOT
    AND SHALL NOT BE LIABLE FOR ANY HARM ARISING FROM OR RELATING TO THE THEFT
    OF YOUR USER CREDENTIALS AND/OR ANY RESULTING ACCESS TO YOUR PERSONAL DATA,
    YOUR DISCLOSURE OF YOUR USER CREDENTIALS, OR THE USE OF YOUR USER
    CREDENTIALS BY ANOTHER PERSON OR ENTITY REGARDLESS OF WHETHER YOU WERE
    AWARE OF SUCH USE.
</p>
<p>
    You may be held liable for any losses incurred by Kaizenovate and/or its
    affiliates, officers, directors, and representatives due to someone else’s
    use of Your User Account or password, regardless of whether You were aware
    of such use.
</p>
<h2>
    <strong>HOW DOES KAIZENOVATE PROTECT YOUR PRIVACY?</strong>
</h2>
<p>
    Kaizenovate values Your privacy and is committed to keeping Your Personal
    Data confidential. Please see Our
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    for an explanation of Our privacy practices, the data We collect from You,
    how We use that data, and Your rights regarding Your data. By clicking “I
    Agree,” accessing or using the Services, or by downloading, viewing, or
    uploading any content through the Services, You acknowledge and agree to
    the provisions of the Provider Portal Privacy Policy and affirm that the
    Provider Portal Privacy Policy is a part of these Terms.
</p>
<p>
    By using the Services and accepting these Terms, You further acknowledge
    that Kaizenovate may share Your Personal Data with third parties as
    described in the
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    , and will seek Your consent before doing so where required by law.
</p>
<p>
    We are not responsible for nor liable to You or any third party for a third
    party’s treatment of Personal Data, including any collection, use,
    disclosure, storage, loss, theft, or misuse of Personal Data, whether or
    not such treatment violates applicable law.
</p>
<h2>
    <strong>COMPUTER EQUIPMENT AND INTERNET ACCESS</strong>
</h2>
<p>
    You are responsible for obtaining, installing, maintaining, and operating
all software, hardware, or other equipment (collectively, "    <strong>Systems</strong>") necessary for You to access and use the
    Services. This includes, without limitation, obtaining Internet services,
    using up to date web-browsers and the best commercially available
    encryption, antivirus, anti-spyware, and internet security software. You
    are responsible for the data security of the Systems used to access the
    Services and for the transmission and receipt of information using such
    Systems. We are not responsible for any errors or problems that arise from
    the malfunction or failure of the Internet or Your Systems.
</p>
<p>
    THERE ARE ALWAYS CERTAIN SECURITY AND ACCESS AVAILABILITY RISKS ASSOCIATED
    WITH USING OPEN NETWORKS SUCH AS THE INTERNET, AND YOU EXPRESSLY ASSUME
    SUCH RISKS.
</p>
<h2>
    <strong>
        OPTING OUT OF COMMUNICATIONS FROM KAIZENOVATE
    </strong>
</h2>
<p>
    We may send communications, including emails, to You regarding Your User
    Account and the Services. You can choose to filter any User Account and
    Services emails using Your email settings, but we do not provide an option
    for You to opt out of these communications.
</p>
<p>
    If You consent to receive marketing or other communications not related to
    Your User Account or the Services, We will provide You with the option to
    opt out of such marketing communications within the applicable message.
</p>
<h2>
    <strong>THIRD PARTIES AND THIRD-PARTY SITES</strong>
</h2>
<p>
    <strong><u>Third-Party Websites.</u></strong>
</p>
<p>
    In the course of using the Services, You may be introduced to areas or
    features of the Services that allow You to access website that do not
belong to and are not controlled by Us (collectively, “    <strong>Third-Party Sites</strong>”). If You choose to access one of these
    Third-Party Sites, You will leave Our Provider Portal and be redirected to
    an environment owned and controlled by an external third party. You
    acknowledge and agree that the Third-Party Sites may have different privacy
    policies, terms of use, user guides, and/or business practices
    (collectively, “<strong>Third-Party Rules</strong>”) than Us, and that Your
    use of such Third-Party Sites is governed exclusively by the respective
    Third-Party Rules. We provide links to Third-Party Sites to You as a
    convenience, and We do not verify, make any representations, or take
    responsibility for such Third-Party Sites, including, without limitation,
    the truthfulness, accuracy, quality, or completeness of the content,
    application, links displayed, and/or any other activities conducted on or
    through such Third-Party Sites.
</p>
<p>
    YOU AGREE THAT WE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR
    LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, SERVICES, BUSINESS
PRACTICES, INFORMATION, RESOURCES, APPLICATIONS, AND OTHER CONTENT (“    <strong>Third Party Matters</strong>”) AVAILABLE ON OR THROUGH ANY
    THIRD-PARTY SITES OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY
    HARM RELATED THERETO, OR FOR ANY DAMAGES OR LOSS CAUSED OR ALLEGED TO BE
    CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE THIRD PARTY
    MATTERS.
</p>
<p>
    Any reference in the Services to any product, service, publication,
    institution, organization of any third-party entity, or individual does not
    constitute or imply Our endorsement or recommendation.
</p>
<p>
    <strong><u>Third-Party Services.</u></strong>
</p>
<p>
    To the extent any features, aspects, products, or services offered through
the Services are provided, in whole or in part, by third parties (“<strong>Third-Party Services</strong>” as provided by “    <strong>Third-Party Service Providers</strong>”), You may be subject to
    additional terms and conditions. To the extent applicable, You will receive
    a notification and have the opportunity to accept such terms and
    conditions. IF YOU DO NOT UNDERSTAND OR DO NOT AGREE TO BE BOUND BY THOSE
    ADDITIONAL TERMS AND CONDITIONS, DO NOT USE THE RELATED THIRD-PARTY
    SERVICES.
</p>
<p>
    In the event of any inconsistency between terms of use relating to
    Third-Party Services and these Terms, those additional terms and conditions
    will control with respect to such Third-Party Services. Third-Party Service
    Providers may collect and use certain information about You, as specified
    in the Third-Party Service Provider’s privacy policies. Prior to providing
    information to any Third-Party Service Provider, You should review their
    privacy policy. IF YOU DO NOT UNDERSTAND OR DO NOT AGREE TO THE TERMS OF A
    THIRD-PARTY SERVICE PROVIDER’S PRIVACY POLICY OR TERMS OF USE, YOU SHOULD
    NOT USE THE RELATED THIRD-PARTY SERVICES. WE WILL NOT, UNDER ANY
    CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE FOR ANY OF YOUR INFORMATION
    COLLECTED OR USED BY THIRD-PARTY SERVICE PROVIDERS.
</p>
<h2>
    <strong>YOUR REPRESENTATIONS AND WARRANTIES</strong>
</h2>
<p>
    In addition to other representations and warranties contained throughout
    the Terms, You represent and warrant that Your use of the Services will be
    in accordance with these Terms and all applicable laws, regulations, rules,
    and Kaizenovate policies and procedures (to the extent such policies and
    procedures are communicated to You). Specifically,
    <strong>
        YOU REPRESENT AND WARRANT THAT YOU ARE LEGALLY AUTHORIZED TO SHARE
        PERSONAL DATA (
    </strong>
    <strong>
        BELONGING TO YOURSELF OR OTHERS ON WHOSE BEHALF YOU ARE SUBMITTING SUCH
        PERSONAL DATA)
    </strong>
    <strong> WITH US.</strong>
</p>
<h2>
    <strong>
        WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY
    </strong>
</h2>
<p>
    <strong><u>No Warranties.</u></strong>
</p>
<p>
    THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER
    EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, KAIZENOVATE EXPLICITLY
    DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
    PURPOSE, QUIET ENJOYMENT, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT
    OF COURSE OF DEALING OR USAGE OF TRADE. KAIZENOVATE MAKES NO WARRANTY THAT
    THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN
    UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. KAIZENOVATE MAKES NO WARRANTY
    REGARDING THE QUALITY OF ANY PRODUCTS, APPLICATIONS, OR MATERIALS ACCESSED
    OR PURCHASED THROUGH THE APPLICATION OR THE ACCURACY, TIMELINESS,
    TRUTHFULNESS, COMPLETENESS, OR RELIABILITY OF ANY CONTENT OBTAINED THROUGH
    THE SERVICES.
</p>
<p>
    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM
    KAIZENOVATE OR THROUGH THE PROVIDER PORTAL OR MATERIALS, WILL CREATE ANY
    WARRANTY NOT EXPRESSLY MADE IN THESE TERMS.
</p>
<p>
    YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS
    WITH THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR
    INTERACT AS A RESULT OF YOUR USE OF THE SERVICES, INCLUDING, WITHOUT
    LIMITATION, PATIENTS AND OTHER AUTHORIZED THIRD PARTIES.
</p>
<p>
    KAIZENOVATE CANNOT ALWAYS FORESEE OR ANTICIPATE TECHNICAL OR OTHER
    DIFFICULTIES THAT MAY RESULT IN FAILURE TO OBTAIN DATA OR LOSS OF DATA,
    PERSONALIZATION SETTINGS, OR OTHER SERVICE INTERRUPTIONS. KAIZENOVATE
    CANNOT ASSUME RESPONSIBILITY FOR THE TIMELINESS, ACCURACY, DELETION,
    NON-DELIVERY OR FAILURE TO STORE ANY USER DATA, COMMUNICATIONS, OR
    PERSONALIZATION SETTINGS. IT IS YOUR RESPONSIBILITY TO BACK UP ANY
    INFORMATION YOU ENTER INTO THE PROVIDER PORTAL.
</p>
<p>
    <u>Your Responsibility for Loss or Damage.</u>
</p>
<p>
    YOU AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. YOU WILL NOT
    HOLD KAIZENOVATE OR ITS THIRD-PARTY SERVICE PROVIDERS, LICENSORS, OR
    SUPPLIERS, AS APPLICABLE, RESPONSIBLE FOR ANY LOSS OR DAMAGE THAT RESULTS
    FROM YOUR ACCESS TO OR USE OF THE SERVICES, INCLUDING, WITHOUT LIMITATION,
    ANY LOSS OR DAMAGE TO ANY OF YOUR COMPUTERS OR DATA.
</p>
<p>
    <strong><u>Limitation of Liability.</u></strong>
</p>
<p>
    YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE
    ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SERVICES REMAINS
    WITH YOU. NEITHER KAIZENOVATE NOR ANY OTHER PERSON OR ENTITY INVOLVED IN
    CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY
    INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
    PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER
    DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE PRODUCTS OR
    APPLICATIONS, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL
    DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE
    OF OR INABILITY TO USE THE SERVICES, OR FROM ANY COMMUNICATIONS,
    INTERACTIONS, OR MEETINGS WITH OTHER USERS OF THE SERVICES OR OTHER PERSONS
    WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE
    SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
    PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
    KAIZENOVATE HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A
    LIMITED REMEDY SET FORTH IN THESE TERMS IS FOUND TO HAVE FAILED IN MEETING
    ITS ESSENTIAL PURPOSE.
</p>
<p>
    <strong>
        IF YOU ARE DISSATISFIED WITH THE SERVICES OR THESE TERMS, YOUR ONLY
        REMEDY IS TO DISCONTINUE USING THE SERVICES
    </strong>
    .
</p>
<p>
    KAIZENOVATE IS NOT LIABLE TO YOU OR ANY PERSON FOR ANY DECISION MADE OR
    ACTION TAKEN IN RELIANCE UPON INFORMATION INCLUDED IN THE SERVICES.
    KAIZENOVATE IS NOT LIABLE TO ANY USER OR PERSON FOR ANY HARM CAUSED BY YOUR
    NEGLIGENCE OR MISCONDUCT, WHETHER OR NOT RELYING UPON INFORMATION
    COLLECTED, GENERATED, OR STORED VIA THE SERVICES. BECAUSE SOME STATES DO
    NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
    INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN
    SUCH STATES, KAIZENOVATE’S LIABILITY IS LIMITED AND WARRANTIES ARE EXCLUDED
    TO THE GREATEST EXTENT PERMITTED BY LAW, BUT SHALL, IN NO EVENT, EXCEED
    $100.00. ANY CLAIM ARISING FROM THE USAGE OF THE SERVICES MUST BE BROUGHT
    WITHIN ONE (1) YEAR OF THE OCCURRENCE OF THE EVENT FROM WHICH THE CLAIM
    AROSE.
</p>
<h2>
    <strong>INDEMNIFICATION</strong>
</h2>
<p>
    <strong>YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS</strong>
    <strong>KAIZENOVATE</strong>
    <strong>
        AND ITS REPRESENTATIVES FROM ANY LIABILITY, LOSS, CLAIM, SUIT, DAMAGE,
        AND EXPENSE (INCLUDING REASONABLE ATTORNEYS' FEES AND EXPENSES) ARISING
        OUT OF OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO OR USE OF THE
        SERVICES, YOUR VIOLATION OF THESE TERMS, OR ANY NEGLIGENT OR WRONGFUL
        CONDUCT BY YOU OR RELATED TO YOUR USER ACCOUNT BY YOU OR ANY OTHER
        PERSON ACCESSING THE SERVICES THROUGH YOUR USER ACCOUNT, REGARDLESS OF
        WHETHER YOU WERE AWARE OF SUCH USE.
    </strong>
</p>
<p>
    <strong>
        IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE
        SECTION 1542, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO
        CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO
        EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND
        THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER
        SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”
    </strong>
</p>
<h2>
    <strong>PROVIDING FEEDBACK TO KAIZENOVATE</strong>
</h2>
<p>
    We welcome and encourage You to provide feedback, comments, and suggestions
    for improvements to the Services (“<strong>Feedback</strong>”). You may
    submit Feedback by e-mailing Us at customersupport@kaizenovatae.com. You
    acknowledge and agree that if You submit any Feedback to Us, You grant to
    Us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid,
    royalty-free, sub-licensable, and transferable license under any and all
    intellectual property rights that You own or control to use, copy, modify,
    create derivative works based upon, and otherwise exploit the Feedback for
    any purpose.
</p>
<h2>
    <strong>USER ACCOUNT TERMINATION</strong>
</h2>
<p>
    If You breach any of these Terms, We may suspend or disable Your User
    Account or terminate Your access to the Services without prior notice to
    You. There may be other instances where We may need to terminate Your
    access to the Services that are not related to any of Your actions or
    inactions. We reserve the right to terminate Your access to and use of the
    Services and materials at any time, with or without cause.
</p>
<p>
    If You wish to terminate Your User Account, please contact Kaizenovate at
    customersupport@kaizenovate.com, immediately discontinue Your use of the
    Services, and delete all files associated with the Services from Your
    computer.
</p>
<h2>
    <strong>DISPUTE RESOLUTION</strong>
</h2>
<p>
    *PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS*
</p>
<p>
    You agree that any dispute between You and Kaizenovate arising out of or
    relating to these Terms will be governed by the dispute resolution
    procedure outlined below. We want to address Your concerns without needing
    a formal legal case, so We have included a tiered dispute resolution
    process. This Dispute Resolution section may be modified by written
    agreement between You and Kaizenovate.
</p>
<p>
    <strong><u>Informal Dispute Resolution.</u></strong>
</p>
<p>
    Before filing a claim against Kaizenovate, You agree to try to resolve the
    dispute informally by contacting customersupport@kaizenovate.com. Most user
    concerns can be resolved quickly and to Your satisfaction through email.
</p>
<p>
    <strong><u>Arbitration Agreement.</u></strong>
</p>
<p>
    In the unlikely event that Our support team is unable to resolve a
    complaint You may have (or if We have not been able to resolve a dispute
    with You after attempting to do so informally), including, but not limited
    to, any alleged breach of these Terms, You agree to resolve the dispute
    through binding arbitration. Arbitration, which is often less expensive,
    faster, and less formal than a lawsuit in court, uses a neutral arbitrator
    instead of a judge or jury. Arbitrators can award the same damages and
    relief that a court can award, and may, but do not have to, award legal
    fees, arbitrator’s fees and costs, and other costs incurred by the party
    that does not win the dispute. You agree that any dispute will be
    exclusively, fully, and finally resolved using binding arbitration through
    the American Health Lawyers Association (“<strong>AHLA</strong>”).
</p>
<p>
    <strong><u>Arbitration Procedure.</u></strong>
</p>
<p>
You and Kaizenovate agree to employ three (3) arbitrators (the “    <strong>Panel</strong>”), where one arbitrator (1) is selected by You, one
    (1) arbitrator is selected by Kaizenovate, and the third (3rd) arbitrator
    is selected by mutual consent of You and Kaizenovate. If You and
    Kaizenovate cannot agree upon the third arbitrator, the first two (2)
    arbitrators chosen shall select the third arbitrator. The selection
    decision shall be binding.
</p>
<p>
    The arbitration shall be conducted by telephone, online, and/or be solely
    based on written submissions—the specific manner to be chosen by the party
    initiating the arbitration. The arbitration shall not involve any personal
    appearance by the parties or witnesses unless otherwise mutually agreed by
    the parties. Any judgment on the award rendered by the Panel may be entered
    in any court of competent jurisdiction.
</p>
<p>
    Any claim or dispute arising under these Terms must be initiated by
    arbitration within two (2) years from its accrual date. Any claim or
    dispute initiated two (2) years or longer from its accrual date shall be
    time-barred and dismissed.
</p>
<p>
    <strong><u>Cost of Arbitration.</u></strong>
</p>
<p>
    You and Kaizenovate shall each pay 50% of all mediator and/or arbitrator
    costs, expenses, and fees incurred in connection with mediating and/or
    arbitrating under these Terms.
</p>
<p>
    <strong><u>Exceptions to Agreement to Arbitrate.</u></strong>
</p>
<p>
    Kaizenovate may bring a lawsuit solely for injunctive relief to stop
    unauthorized use or abuse of the Services or infringement of intellectual
    property rights (for example, trademark, trade secret, copyright, or patent
    rights) without first engaging in the informal dispute-resolution process
    described above.
</p>
<p>
    YOU MAY ONLY RESOLVE DISPUTES WITH KAIZENOVATE ON AN INDIVIDUAL BASIS, AND
    MAY NOT BRING A CLAIM AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS,
    CONSOLIDATED, OR REPRESENTATIVE ACTION. CLASS ARBITRATIONS, CLASS ACTIONS,
    PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER ARBITRATIONS
    ARE NOT ALLOWED UNDER THESE TERMS.
</p>
<p>
    <strong><u>Opt Out of Alternative Dispute Resolution Process.</u></strong>
</p>
<p>
    <strong>
        Notwithstanding the above, You can decline or “opt out” of the
        alternative dispute resolution process described above by contacting
        customersupport@kaizenovate.com within 30 days of first accepting these
        Terms and stating that You (first and last name) decline this dispute
        resolution process.
    </strong>
</p>
<p>
    <strong>
        YOU UNDERSTAND AND AGREE THAT, BY NOT OPTING OUT OF THE ALTERNATIVE
        DISPUTE RESOLUTION PROCESS DESCRIBED, YOU WAIVE ANY RIGHT TO A JURY
        TRIAL TO WHICH YOU MAY OTHERWISE BE ENTITLED IN CONNECTION WITH ANY
        ACTION OR LITIGATION IN ANY WAY ARISING OUT OF OR RELATED TO THESE
        TERMS.
    </strong>
</p>
<p>
    If You opt out of the dispute resolution process described in this section,
    or if any matter is otherwise determined not to be subject to such dispute
    resolution process, You submit to the exclusive jurisdiction of any state
    or federal court sitting in the State of Maryland within twenty-five (25)
    miles of [county], Maryland in any legal proceeding arising out of or
    relating to these Terms. You agree that any and all claims and matters
    arising out of these Terms, unless subject to the dispute resolution
    process described above, may be heard and determined in any such court, and
    You hereby waive any right to object to such filing on grounds of improper
    venue, forum non-conveniens, or other venue-related grounds, unless such
    objection asserts that the claim or matter in dispute is subject to
    determination through the dispute resolution process described above.
</p>
<h2>
    <strong>GENERAL CONTRACT TERMS</strong>
</h2>
<p>
    <strong><u>Entire Agreement.</u></strong>
</p>
<p>
    These Terms, the
    <a target="_blank"
        href="https://www.kaizenovate.com/kaizenovate-tech-llc-provider-user-privacy-policy/"
    >
        Provider Portal Privacy Policy
    </a>
    , and any other terms incorporated by reference, constitute the entire and
    exclusive understanding and agreement between Kaizenovate and You regarding
    the Services, and these Terms supersede and replace any and all prior oral
    or written understandings or agreements between Kaizenovate and You
    regarding the Services.
</p>
<p>
    <strong><u>Governing Law.</u></strong>
</p>
<p>
    These Terms shall be governed by the laws of the State of Maryland without
    reference to its conflict of laws provisions.
</p>
<p>
    <strong><u>Assignment.</u></strong>
</p>
<p>
    You may not assign or transfer these Terms, by operation of law or
    otherwise, without Kaizenovate’s prior written consent. Any attempt by You
    to assign or transfer these Terms, without such consent, will be null and
    of no effect. Kaizenovate may assign or transfer these Terms, at its sole
    discretion, without restriction. Subject to the foregoing, these Terms will
    bind and inure to the benefit of the parties, their successors, and
    permitted assigns.
</p>
<p>
    <strong><u>Notices.</u></strong>
</p>
<p>
    Any notices or other communications permitted or required under these
    Terms, including those regarding modifications to these Terms, will be in
    writing and given: (i) by Kaizenovate via email (in each case to the
    address that You provide); and/or (ii) by posting to the Provider Portal.
    For notices made by email, the notice will be effective as of the date the
    notice is first transmitted. You agree that any notice received from
    Kaizenovate electronically satisfies any legal requirement that such notice
    be in writing. YOU ALONE ARE RESPONSIBLE FOR ENSURING THAT YOUR EMAIL
    ADDRESS ON FILE WITH KAIZENOVATE IS ACCURATE AND CURRENT, AND NOTICE TO YOU
    SHALL BE DEEMED EFFECTIVE UPON THE SENDING OF AN EMAIL TO THE ADDRESS WE
    HAVE ON FILE.
</p>
<p>
    You shall give any notice to Kaizenovate by email to:
    customersupport@kaizenovate.com. Notice to Kaizenovate shall be effective
    upon receipt of notice by Kaizenovate.
</p>
<p>
    <strong><u>No Inadvertent Waiver.</u></strong>
</p>
<p>
    The failure of Kaizenovate to enforce any right or provision of these Terms
    will not constitute a waiver of future enforcement of that right or
    provision. The waiver of any such right or provision will be effective only
    if in writing and signed by a duly authorized representative of
    Kaizenovate.
</p>
<p>
    <u>Severability.</u>
</p>
<p>
    If any provision of these Terms is determined to be invalid, illegal, or
    unenforceable, the remaining provisions of these Terms remain in full
    force, provided that the essential terms and conditions remain valid,
    binding, and enforceable and the economic and legal substance of the
    transactions contemplated by these Terms are materially preserved.
</p>
<p>
    <strong><u>Intellectual Property Rights.</u></strong>
</p>
<p>
    “<strong>Intellectual Property Rights</strong>” means all intellectual
    property rights or similar proprietary rights, including (i) patent rights
    and utility models, (ii) copyrights and database rights, (iii) trademarks,
    trade names, domain names and trade dress and the goodwill associated
    therewith, (iv) trade secrets, (v) mask works, and (vi) industrial design
    rights; in each case, including any registrations of, applications to
    register, and renewals and extensions of, any of the foregoing in any
    jurisdiction in the world.
</p>
<p>
    As between You and Us, all right, title, and interest, including all
    Intellectual Property Rights, in the Services, any related materials,
    logos, products, and documentation, and any other property or materials
    furnished or made available hereunder, and all modifications and
    enhancements thereof, belong to and are retained solely by Kaizenovate or
    its licensors, vendors, and affiliates, as applicable. All rights not
    expressly granted are reserved by Us. Any use of the Services not expressly
    permitted by these Terms is a breach of these Terms and may violate
    copyright, trademark and other laws.
</p>
<p>
    <strong><u>Remedies.</u></strong>
</p>
<p>
    Except as expressly set forth in these Terms, the exercise by either party
    of any of its remedies under these Terms will be without prejudice to its
    other remedies under these Terms or otherwise. If, for any reason, a court
    of competent jurisdiction finds any provision of these Terms invalid or
    unenforceable, that provision will be enforced to the maximum extent
    permissible, and the other provisions of these Terms will remain in full
    force and effect.
</p>
<p>
    <strong><u>Contacting Kaizenovate.</u></strong>
</p>
<p>
    Please feel free to contact Us if You have any questions about these Terms
    and/or any other documents referenced in these Terms. You may contact Us at
    customersupport@kaizenovate.com, or at our mailing address:
</p>
<p>
    Kaizenovation Tech, LLC
</p>
<p>
    827E Rockville Pike
</p>
<p>
    Rockville, MD 20852
</p>
</body></html>`