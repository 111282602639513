import {
    FilterButton,
    ReferenceInput,
    SelectInput,
    TopToolbar,
    Datagrid,
    List,
    TextField,
    CreateButton,
    ArrayField,
    ReferenceField,
} from 'react-admin';

import Aside from './Aside';

const MasterprogramsList = () => {
    // const getResourceLabel = useGetResourceLabel();

    // const match = matchPath('/masterprograms/:id', location.pathname);

    // const [exerciseState, setExerciseState] = useState( location.state );

    return (
        <List
                // filters={ masterprogramsFilters }
                aside={<Aside />}
                sort={{ field: 'level', order: 'ASC' }}
                actions={ <ListActions /> }
                
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    bulkActionButtons={false}
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source="name" />
                    <ReferenceField
                        source="condition_id"
                        reference="conditions"
                        link={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="level" />
                    <ArrayField source="relieve_exercises" >
                        <Datagrid bulkActionButtons={false}>
                            <ReferenceField
                                source="easy_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField
                                source="default_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField
                                source="hard_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField source="restore_exercises" >
                        <Datagrid bulkActionButtons={false}>
                            <ReferenceField
                                source="easy_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField
                                source="default_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField
                                source="hard_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField source="reinforce_exercises" >
                        <Datagrid bulkActionButtons={false}>
                            <ReferenceField
                                source="easy_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField
                                source="default_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField
                                source="hard_exercise_id"
                                reference="exerciselibrarys"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </Datagrid>
                    </ArrayField>
                </Datagrid>
            </List>
    );
};

export const masterprogramsFilters = [
    // <SearchInput source="q" alwaysOn />,
    <ReferenceInput
        source="condition_id"
        reference="conditions"
        sort={{ field: 'condition_id', order: 'ASC' }}
    >
        <SelectInput source="name" />
    </ReferenceInput>,
    // <NumberInput source="width_gte" />,
    // <NumberInput source="width_lte" />,
    // <NumberInput source="height_gte" />,
    // <NumberInput source="height_lte" />,
    // <QuickFilter
    //     label="resources.exercises.fields.stock_lte"
    //     source="stock_lte"
    //     defaultValue={10}
    // />,
];

const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        {/* <SortButton fields={['name', 'condition_id']} /> */}
        <CreateButton />
        {/* <ExportButton /> */}
    </TopToolbar>
);

export default MasterprogramsList;
