import * as React from 'react';
import { Inspector, LayoutClasses, LayoutProps, Loading, Sidebar, SkipNavigationButton, useSidebarState, Error, useRefresh, useLogout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { SelectChangeEvent, styled } from '@mui/material';
import { ErrorInfo, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { StreamChat } from 'stream-chat';
import dataProvider from '../dataProvider';
import tokenManager from '../tokenManager';

const Layout = (props: LayoutProps) => {
  
  // default react stuff
  const {
      appBarAlwaysOn,
      children,
      className,
      dashboard,
      error: errorComponent,
      title,
      ...rest
  } = props;

  const [open] = useSidebarState();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | undefined>(undefined);

  const handleError = (error: Error, info: ErrorInfo) => {
      setErrorInfo(info);
  };

  // custom stuff

  const [practices, setPractices] = useState([]);
  const [currPractice, setCurrPractice] = useState('xyz');
  const [client, setClient] = useState(StreamChat.getInstance(process.env.REACT_APP_STREAM_API_KEY!));
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadCountError, setUnreadCountError] = useState(0);
  const refresh = useRefresh();
  const logout = useLogout();

  const listenEvents = ["channel.updated", "message.deleted", "message.new", "message.read", "notification.mark_read", "notification.mark_unread"]

  let listener = client.on((event) => {
    if (listenEvents.includes(event.type) && event.unread_channels !== undefined && event.unread_channels !== null && event.unread_channels !== (unreadCount + unreadCountError)) {
      setUnreadCount(event.unread_channels - unreadCountError)
    }
  })

  useEffect(() => {
    client.getUnreadCount(localStorage.getItem('selectedPractice')!).then((result) => {
      let fakeUnreadCount = 0
      for ( const channel of result.channels) {
        if (channel.unread_count === 0) fakeUnreadCount++
      }
      setUnreadCountError(fakeUnreadCount)
      if (fakeUnreadCount > 0) {
        console.warn('fakeUnreadCount is greater than 0')
      }
    })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const value = localStorage.getItem('selectedPractice')
      if (!value) {
        logout()
      }
    }, 60000)

    return () => clearInterval(interval);
  }, [logout])

  useEffect(() => {
      if(tokenManager.getToken()) {
          dataProvider.get(`kaizenovate/provider/1.0.0/practices`).then(result => {
              setPractices(result.data);
              let selectedPracticeIndex = 0
              for (var index = result.data.length - 1; index >= 0; index--) {
                  if (result.data[index]['id'] === localStorage.getItem('selectedPractice')) {
                      selectedPracticeIndex = index
                      break
                  } else if (result.data[index]['status'] === 'active') {
                      selectedPracticeIndex = index
                  }
              }

              if (result.data[selectedPracticeIndex]['status'] === 'active') {
                  localStorage.setItem('selectedPractice',result.data[selectedPracticeIndex]['id'])
                  localStorage.setItem('selectedPracticeTZ',result.data[selectedPracticeIndex]['time_zone'])
                  localStorage.setItem('enhancedMessagingEnabled', result.data[selectedPracticeIndex]['enhanced_messaging_enabled'] ? 'true' : 'false')
                  localStorage.setItem('embodiConnectEnabled', result.data[selectedPracticeIndex]['embodi_connect_enabled'] ? 'true' : 'false')
                  localStorage.setItem('notificationEmailDomains', result.data[selectedPracticeIndex]['email_domains'])
                  setCurrPractice(result.data[selectedPracticeIndex]['id'])
                  //init chat
                  try {
                    const initChat = async () => {
                      const createTokenResult = await dataProvider.create(`messages/createToken`, { data: { userId: result.data[selectedPracticeIndex]['id'] } })
                      const connectUserResult = await client!.connectUser(
                        {
                          id: result.data[selectedPracticeIndex]['id'],
                        },
                        createTokenResult.data.extra,
                      );
                      setUnreadCount((connectUserResult && connectUserResult.me && connectUserResult.me.unread_channels) ? connectUserResult.me.unread_channels : 0)

                      
                    }
                    initChat();
                  } catch (e) {
                    console.log(e)
                  }
                  refresh()
              }
          });
      }
      return () => { listener.unsubscribe(); client!.disconnectUser(); }
  }, []);

  const handlePracticeChange = (event: SelectChangeEvent) => {
      window.location.href = '/#/users'
      client!.disconnectUser();
      localStorage.setItem('selectedPractice',event.target.value)
      for (const practice of practices) {
        if (practice['id'] === event.target.value) {
          localStorage.setItem('enhancedMessagingEnabled', practice['enhanced_messaging_enabled'] ? 'true' : 'false')
          localStorage.setItem('embodiConnectEnabled', practice['embodi_connect_enabled'] ? 'true' : 'false')
          localStorage.setItem('selectedPracticeTZ', practice['time_zone'])
          localStorage.setItem('notificationEmailDomains', practice['email_domains'])
          break
        }
      }

      try {
        const initChat = async () => {
          const createTokenResult = await dataProvider.create(`messages/createToken`, { data: { userId: event.target.value } })
          const connectUserResult = await client!.connectUser(
            {
              id: event.target.value,
            },
            createTokenResult.data.extra,
          )
          setUnreadCount((connectUserResult && connectUserResult.me && connectUserResult.me.unread_channels) ? connectUserResult.me.unread_channels : 0)
        }

        initChat()
      } catch(e) {
        console.log(e)
      }

      client.getUnreadCount(localStorage.getItem('selectedPractice')!).then((result) => {
        let fakeUnreadCount = 0
        for ( const channel of result.channels) {
          if (channel.unread_count === 0) fakeUnreadCount++
        }
        setUnreadCountError(fakeUnreadCount)
        if (fakeUnreadCount > 0) {
          console.warn('fakeUnreadCount is greater than 0')
        }
      })
      
      setCurrPractice(event.target.value as string);

      refresh()
  };

  // separate function to handle practice change with params
  const handlePracticeChangeParams = (practiceString: string) => {
    // window.location.href = '/#/users'
    client!.disconnectUser();
    localStorage.setItem('selectedPractice',practiceString)
    for (const practice of practices) {
      if (practice['id'] === practiceString) {
        localStorage.setItem('enhancedMessagingEnabled', practice['enhanced_messaging_enabled'] ? 'true' : 'false')
        localStorage.setItem('embodiConnectEnabled', practice['embodi_connect_enabled'] ? 'true' : 'false')
        localStorage.setItem('selectedPracticeTZ', practice['time_zone'])
        localStorage.setItem('notificationEmailDomains', practice['email_domains'])
        break
      }
    }

    try {
      const initChat = async () => {
        const createTokenResult = await dataProvider.create(`messages/createToken`, { data: { userId: practiceString } })
        const connectUserResult = await client!.connectUser(
          {
            id: practiceString,
          },
          createTokenResult.data.extra,
        )
        setUnreadCount((connectUserResult && connectUserResult.me && connectUserResult.me.unread_channels) ? connectUserResult.me.unread_channels : 0)
      }

      initChat()
    } catch(e) {
      console.log(e)
    }

    client.getUnreadCount(localStorage.getItem('selectedPractice')!).then((result) => {
      let fakeUnreadCount = 0
      for ( const channel of result.channels) {
        if (channel.unread_count === 0) fakeUnreadCount++
      }
      setUnreadCountError(fakeUnreadCount)
      if (fakeUnreadCount > 0) {
        console.warn('fakeUnreadCount is greater than 0')
      }
    })
    
    setCurrPractice(practiceString as string);

    refresh()
};

  return (
      <Core className={'layout ' + className} {...rest}>
          <SkipNavigationButton />
          <div className={LayoutClasses.appFrame}>
              <AppBar open={open} title={title} alwaysOn={appBarAlwaysOn} practices={practices} currpractice={currPractice} handlepracticechange={handlePracticeChange} handlePracticeChangeParams={handlePracticeChangeParams} />
              <main className={LayoutClasses.contentWithSidebar}>
                  <Sidebar appBarAlwaysOn={appBarAlwaysOn}>
                      <Menu hasDashboard={!!dashboard} unreadCount={unreadCount} />
                  </Sidebar>
                  <div id="main-content" className={LayoutClasses.content}>
                      <ErrorBoundary
                          onError={handleError}
                          fallbackRender={({ error, resetErrorBoundary }) => (
                              <Error
                                  error={error}
                                  errorComponent={errorComponent}
                                  errorInfo={errorInfo}
                                  resetErrorBoundary={resetErrorBoundary}
                                  title={title}
                              />
                          )}
                      >
                          <Suspense fallback={<Loading />}>
                              {children}
                          </Suspense>
                      </ErrorBoundary>
                  </div>
              </main>
              <Inspector />
          </div>
      </Core>
  );
};

export default Layout;



const Core = styled('div', {
  name: 'RaLayout',
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  minWidth: 'fit-content',
  width: '100%',
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(7),
      },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
      display: 'flex',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
      }),
  },
  [`& .${LayoutClasses.content}`]: {
      backgroundColor: theme.palette.background.default,
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: 0,
      [theme.breakpoints.up('xs')]: {
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(1),
      },
  },
}));
