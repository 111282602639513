import { Grid } from '@mui/material';
import {
    BooleanInput,
    Button,
    DateInput,
    FormDataConsumer,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    TimeInput,
    required
} from 'react-admin';
import { useState } from 'react';
import TimeZoneNotice from '../../components/enhancedmessaging/TimeZoneNotice';
import FindEventTriggersModal from './FindEventTriggersModal';

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

export default function ScheduleCriteria ({ mode }) {
    const [modalOpen, setModalOpen] = useState(false)

    return (<>
        {/* event trigger modal */}
        <FindEventTriggersModal modalOpen={modalOpen} setModalOpen={setModalOpen} mode='create' />
        <Grid {...gridProps}>
            <p className='automation-label'>3. Schedule automation to run when:</p>
            <Grid item xs={12}>
                <RadioButtonGroupInput
                    source='type'
                    label=''
                    row={false}
                    readOnly={mode === 'edit'}
                    choices={[
                        { id: 'firstTrue', name: 'Each time conditions become true' },
                        { id: 'event', name: 'A specific event occurs' },
                        { id: 'appointment', name: 'Upcoming or missed appointment' },
                        // { id: 'time', name: 'At a specific date and time' },
                        // { id: 'now', name: 'Now (just once)' },
                    ]}
                    validate={[required()]}
                />
            </Grid>
            <FormDataConsumer>
                {({ formData }) => {
                    switch (formData.type) {
                        case 'event':
                            return (
                                <div style={{ paddingLeft: '50px' }}>
                                    <Grid item xs={12}>
                                        <ReferenceInput 
                                            source={mode === 'create' ? 'trigger_id' : 'trigger.id' }
                                            reference='triggers'
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <SelectInput
                                                optionText="name"
                                                label="Event Name"
                                                validate={[required()]}
                                                sx={{ width: '400px' }}
                                            />
                                        </ReferenceInput>
                                        {' '}
                                        <Button
                                            label='Find...'
                                            size='medium'
                                            variant='contained'
                                            sx={{ marginTop: '15px', marginLeft: '20px' }}
                                            onClick={() => setModalOpen(true)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label style={{ lineHeight: '60px', paddingRight: '20px' }}>Which occurrence:</label>
                                        <SelectInput
                                            label='Occurrence Type'
                                            source='trigger_data.event.occurrence_type'
                                            sx={{ width: '300px', paddingRight: '20px' }}
                                            validate={[required()]}
                                            defaultValue={'every'}
                                            choices={[
                                                { id: 'every', name: 'Every occurrence' },
                                                { id: 'specific', name: 'Specific occurrence' },
                                                { id: 'nth', name: 'Every nth occurrence' },
                                            ]}
                                        />
                                        {' '}
                                        {(formData.trigger_data &&
                                        formData.trigger_data.event &&
                                        formData.trigger_data.event.occurrence_type &&
                                        formData.trigger_data.event.occurrence_type !== 'every') && (
                                            <TextInput
                                                sx={{ width: '140px' }}
                                                source='trigger_data.event.occurrence'
                                                label='Occurrence'
                                                format={(value) => parseInt(value) || ''}
                                                parse={(value) => parseInt(value) || ''}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: '80px' }}>
                                        <span style={{ marginTop: '8px', display: 'inline-block' }}>
                                            <BooleanInput source='trigger_data.event.hold' label='Hold' />
                                        </span>
                                        {formData.trigger_data &&
                                        formData.trigger_data.event &&
                                        formData.trigger_data.event.hold === true && (<>
                                            <TextInput
                                                sx={{ width: '140px' }}
                                                source='trigger_data.event.hold_number'
                                                label='Quantity'
                                                validate={[required()]}
                                                format={(value) => parseInt(value) || ''}
                                                parse={(value) => parseInt(value) || ''}
                                            />
                                            <SelectInput
                                                source='trigger_data.event.hold_unit'
                                                label='Timeframe'
                                                sx={{ width: '285px', marginLeft: '24px' }}
                                                choices={[
                                                    { id: 'minute', name: 'Minute(s)' },
                                                    { id: 'hour', name: 'Hour(s)' },
                                                    { id: 'day', name: 'Day(s)' },
                                                ]}
                                                validate={[required()]}
                                            />
                                        </>)}
                                    </Grid>
                                </div>
                            );
                        case 'appointment':
                            return (
                                <Grid {...gridProps} sx={{ paddingLeft: '50px' }}>
                                    <Grid item xs={3}>
                                        <TextInput
                                            sx={{ width: '90%' }}
                                            source='trigger_data.appointment.appointment_number'
                                            label='Quantity'
                                            validate={[required()]}
                                            placeholder='1'
                                            format={(value) => parseInt(value) || ''}
                                            parse={(value) => parseInt(value) || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectInput
                                            sx={{ width: '90%' }}
                                            source='trigger_data.appointment.appointment_unit'
                                            label='Timeframe'
                                            choices={[
                                                { id: 'minute', name: 'Minute(s)' },
                                                { id: 'hour', name: 'Hour(s)' },
                                                { id: 'day', name: 'Day(s)' },
                                            ]}
                                            validate={[required()]}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <SelectInput
                                            sx={{ width: '90%' }}
                                            source='trigger_data.appointment.appointment_condition'
                                            label='When'
                                            choices={[
                                                { id: 'before', name: 'Before Appointment' },
                                                { id: 'after_missed', name: 'After Missed Appointment' },
                                            ]}
                                            validate={[required()]}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        // case 'time':
                        //     return (
                        //         <Grid {...gridProps} sx={{ paddingLeft: '50px' }}>
                        //             <Grid item xs={4}>
                        //                 <DateInput
                        //                     sx={{ width: '90%' }}
                        //                     source='trigger_data.date_time.date'
                        //                     label='Date'
                        //                     validate={[required()]}
                        //                 />
                        //             </Grid>
                        //             <Grid item xs={4}>
                        //                 <TimeInput
                        //                     sx={{ width: '90%' }}
                        //                     source='trigger_data.date_time.time'
                        //                     label='Time'
                        //                     validate={[required()]}
                        //                 />
                        //                 <div style={{ marginTop: '-20px' }}>
                        //                     <TimeZoneNotice />
                        //                 </div>
                        //             </Grid>
                        //         </Grid>
                        //     );
                        default:
                            return null;
                    }
                }}
            </FormDataConsumer>
        </Grid>
    </>);
};