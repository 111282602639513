import {
    Button,
    Confirm,
    DateInput,
    FormDataConsumer,
    TimeInput,
} from "react-admin";
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimeZoneNotice from "../enhancedmessaging/TimeZoneNotice";
import { useFormContext } from 'react-hook-form';

const ScheduleMessage = ({ setScheduleDate, setScheduleTime, setIsSchedule, scheduleModalOpen, setScheduleModalOpen, scheduleModalError, setScheduleModalError }) => {

    const handleDateChange = (event: any) => {
        setScheduleDate(event.target.value);
    }
    const handleTimeChange = (event: any) => {
        setScheduleTime(event.target.value);
    }

    const ResetInputs = () => { // workaround for conditionally triggering useFormContext
      useFormContext().setValue('date', "")
      useFormContext().setValue('time', "")
      setScheduleDate("")
      setScheduleTime("")
      return null
    }

    return (
        <>
            {/* trigger schedule window */}
            <Button
                label='Schedule'
                onClick={() => setScheduleModalOpen(true)}
                startIcon={<ScheduleIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
            />
            {!scheduleModalOpen && <ResetInputs />}
            <FormDataConsumer>
                {({ formData }) => {
                    return (
                        <Confirm
                            isOpen={scheduleModalOpen}
                            title='Schedule Message'
                            className='broadcast-schedule-modal'
                            content={<>
                                <div className='broadcast-schedule-fields'>
                                    <DateInput source='date' onChange={handleDateChange} />
                                    <TimeInput source='time' onChange={handleTimeChange} />
                                </div>
                                <div style={{width: '48%', paddingLeft: '52%', marginTop: '-20px' }}>
                                    <TimeZoneNotice />
                                </div>
                                <div className="no-input-error-msg" style={{display: "none"}}>
                                    <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                                        Date and time are required for scheduling
                                    </p>
                                </div>
                                <div className="empty-message-error-msg" style={{display: "none"}}>
                                    <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                                        Please enter a message before scheduling
                                    </p>
                                </div>
                                <div className="schedule-error" style={{display: scheduleModalError ? 'block' : 'none'}}>
                                    <p style={{fontSize: "0.75rem", color: "#d32f2f", marginBottom: 0}}>
                                        We ran into an issue scheduling your message. Please try again later.
                                    </p>
                                </div>
                            </>}
                            onClose={() => {
                              setScheduleModalOpen(false)
                              setScheduleModalError(false)
                              setIsSchedule(false)
                            }}
                            onConfirm={() => {
                                const textInput = document.querySelector('.str-chat__textarea') as HTMLElement
                                const messageText = textInput!.querySelector('textarea')!.value
                                if (formData.date && formData.time && messageText) {
                                    const emptyErrMsg = document.querySelector('.empty-message-error-msg') as HTMLElement
                                    emptyErrMsg.style.display = 'none'
                                    const noInputErrMsg = document.querySelector('.no-input-error-msg') as HTMLElement
                                    noInputErrMsg.style.display = 'none'
                                    setIsSchedule(true)
                                } else {
                                    if (!(messageText)) {
                                        const emptyErrMsg = document.querySelector('.empty-message-error-msg') as HTMLElement
                                        emptyErrMsg.style.display = 'block'
                                    } else {
                                        const noInputErrMsg = document.querySelector('.no-input-error-msg') as HTMLElement
                                        noInputErrMsg.style.display = 'block'
                                    }
                                }
                            }}
                            confirm='Schedule'
                        />
                    )
                }}
            </FormDataConsumer>
        </>
    )
}

export default ScheduleMessage;