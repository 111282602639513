import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import { useGetIdentity } from 'react-admin';
import PatientMixPieChart from './components/PatientMixPieChart';
import ClinicVisitBarChart from './components/ClinicVisitBarChart';
import EngagementFunnelChart from './components/EngagementFunnelChart';
import PatientDischargeBarChart from './components/PatientDischargeBarChart';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import GenesisSyncPipelineChart from './components/GenesisSyncPipelineChart';

import './scss/pages/Dashboard.scss';

const Spacer = () => <span style={{ width: '1em', height: '1em' }} />;

export default function Dashboard() {

    const { identity, isLoading: identityLoading } = useGetIdentity();

    useEffect(() => {
        if (typeof identity !== "undefined") {
            if (identity?.roles.includes('admin') && !identity?.roles.includes('staff')) {
                window.location.href = '/#/conditions'
            } else {
                window.location.href = '/#/users'
            }
        }
    }, [identityLoading])

    const [month, setMonth] = useState("December");

    const prevMonth = () => {
        switch(month) {
            case("December"):
                setMonth("November")
                break
            case("November"):
                setMonth("October")
                break
        }
    }

    const nextMonth = () => {
        switch(month) {
            case("October"):
                setMonth("November")
                break
            case("November"):
                setMonth("December")
                break
        }
    }

    return (
        <div className='dashboard'>        

            <div className='month-selector'>
                <ArrowBackIosRoundedIcon onClick={prevMonth} className="chevron"/>
                <div className='title'>{month} 2022</div>
                <ArrowForwardIosRoundedIcon onClick={nextMonth} className="chevron"/>
            </div>
            { (localStorage.getItem('isDev') === 'true') &&
                <>
                    <div style={{ display: 'flex'}}>
                        <Card style={{ flex: '8'}}>
                            <GenesisSyncPipelineChart></GenesisSyncPipelineChart>
                        </Card>
                    </div>
                    <Spacer />
                </>
            }
            <div style={{ display: 'flex'}}>
                <Card style={{ flex: '3'}}>
                    <PatientMixPieChart month={month}></PatientMixPieChart>
                </Card>
                <Spacer />
                <Card style={{ flex: '5'}}>
                    <ClinicVisitBarChart month={month}></ClinicVisitBarChart>
                </Card>
            </div>
            <Spacer />
            <div style={{ display: 'flex'}}>
                <Card style={{ flex: '3'}}>
                    <EngagementFunnelChart month={month}></EngagementFunnelChart>
                </Card>
                <Spacer />
                <Card style={{ flex: '5'}}>
                    <PatientDischargeBarChart month={month}></PatientDischargeBarChart>
                </Card>
            </div>
            <Spacer />

        </div>
    )
    
};