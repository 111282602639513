import { Grid } from '@mui/material';
import * as React from 'react';
import { 
    Create, 
    TextInput,
    SimpleForm, 
    ReferenceInput, 
    SimpleFormIterator, 
    ArrayInput, 
    SelectInput,
    NumberInput
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';


const ExerciselibrarysCreate = () => {
    return (
        <Create>
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className="exerciselibrarys" 
                width="90%"
            >
                <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                    <Grid item xs={10}>
                        <TextInput source='name' isRequired fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectInput 
                            isRequired
                            fullWidth
                            source="exercise_type"
                            emptyText={ "Exercise" } // Default values
                            emptyValue={ 'exercise' } // Default values
                            choices={[
                                { id: 'exercise', name: 'Exercise' },
                                { id: 'stretch', name: 'Stretch' },
                            ]} />
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={1} columnSpacing={1}>
                    <Grid item xs={10}>
                        <TextInput source='video' isRequired fullWidth label='Video URL'/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextInput source='video_time' isRequired fullWidth/>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={1}>
                    <Grid item xs={12}>
                        <TextInput source='thumbnail' isRequired fullWidth label='Thumbnail URL'/>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={1}  columnSpacing={1}>
                    <Grid item xs={3}>
                        <NumberInput source='sets' isRequired defaultValue={3} fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput source='rest' isRequired defaultValue={30} fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput source='reps' defaultValue={10} fullWidth/>
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput source='hold' defaultValue={30} fullWidth/>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={1}  columnSpacing={1}>
                    <Grid container rowSpacing={1} mb={1} xs={6}>
                        <RichTextInput source='instructions' />
                    </Grid>
                    <Grid container rowSpacing={1} mb={1} xs={1}>
                    </Grid>
                    <Grid container rowSpacing={1} mb={1} xs={5}>
                        <ArrayInput source="muscle_groups" isRequired label="Regions">
                            <SimpleFormIterator>
                                <ReferenceInput
                                    source=""
                                    reference="musclegroups"
                                    sort={{ field: 'name', order: 'ASC' }}
                                >
                                    <SelectInput 
                                    optionValue="name" optionText="name" 
                                    // source='name'
                                    isRequired/>
                                </ReferenceInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default ExerciselibrarysCreate;
