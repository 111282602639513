import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { 
    Edit,
    SimpleForm,
    TextInput,
 } from 'react-admin';

const MusclegroupsEdit = (props) => {

    return (
        <Edit 
        {...props}>
            <SimpleForm 
            {...props}
            // validate={validateForm}
            >
                <div>
                    <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Edit Region
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        autoFocus
                                        source="name"
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

export default MusclegroupsEdit;
