import { useGetOne } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

const RemoveInvalidTemplate = () => {
    useFormContext().setValue('template', null);
    return null;
}

const RenderMessageTemplate = ({template_id}) => {
    const formValues = useWatch();
    const channels = formValues.channels;
    const record = useGetOne(`messagetemplates`, { id: template_id }).data;
    
    const formatMessage = (message: string) => {
        // repalce newlines with <p> tags
        let sms = message ? message.replaceAll('\n', '</p><p>') : '';
        if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
        // no more than one consecutive empty paragraph
        sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');
        return sms;
    }

    if (channels && record) {
        const sms_html = (channels.includes('sms') || (channels.includes('email') && !record.email_message)) && record.sms_message && (
            <>
                <p>Text message template</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.sms_message) }}
                />
            </>
        );
        const email_html = channels.includes('email') && record.email_message && (
            <>
                <p >Email Subject</p>
                <div
                    id="email-subject"
                    className='broadcast-textarea'
                    style={{minHeight: '35px'}}
                    dangerouslySetInnerHTML={{
                    __html: formatMessage(record.email_subject || record.name), // this will be the email_subject or the name of the template
                    }}
                />
                <p>Email template</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.email_message) }}
                />
            </>
        );
        return (
            <div className='broadcast-textarea-wrapper'>
                {/* workaround for conditional useFormContext hook call */}
                {formValues.valid_event_types && !formValues.valid_event_types.includes(record.event_type) && <RemoveInvalidTemplate />}
                {sms_html}
                {email_html}
            </div>
        );
    }
    return <div></div>;
};

export default RenderMessageTemplate;