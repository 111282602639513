import { Grid } from '@mui/material';
import {
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    FormDataConsumer,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    TextInput,
    RadioButtonGroupInput,
    Button,
    Toolbar,
    SaveButton,
    useCreateSuggestionContext,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import SelectionCriteria from './SelectionCriteria';
import RenderMessageTemplate from './RenderMessageTemplate';
import transformAutomationsData from './TransformAutomationsData';
import { MessageTemplateCreateModal } from '../messagetemplates/Create';
import ScheduleCriteria from './ScheduleCriteria';

const transform = (data: any) => {
    data = {
        ...data,
        practice_id: localStorage.getItem('selectedPractice') || '',
        template_id: data.template,
        is_broadcast: false,
    };

    data = transformAutomationsData(data);

    // delete fields that are not in the model
    delete data.template; // "converted" to template_id

    return data;
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const CreateTemplateModal = () => {
    const { onCancel } = useCreateSuggestionContext();

    return (
        <Modal
            open={true}
            onClose={onCancel}
            className='automations-template-modal'
        >
            <MessageTemplateCreateModal/>
        </Modal>
    );
};

/* Start template selection functions */
const FilteredTemplates = ({ event_types }) => {
    if (!event_types) event_types = ['General'];
    const valid_event_types = useWatch().valid_event_types;
    // if valid_event_types is not set or is set and not matching the current event_types, set it to current event_types
    const setValid = (!valid_event_types || (valid_event_types && valid_event_types.join() !== event_types.join()));

    return (<>
        {/* workaround for conditional useFormContext hook call */}
        {setValid && <SetValidEventType event_types={event_types} />}
        <ReferenceInput
            source='template'
            reference='messagetemplates'
            filter={{ is_broadcast: false, is_provider_notification: false, event_type: event_types }}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <AutocompleteInput
                optionText='name'
                label='Select a template...'
                filterToQuery={(value) => ({ name: value })}
                validate={[required()]}
                createLabel='< Create New Template >'
                createItemLabel="< Create New Template >"
                create={<CreateTemplateModal/>}
            />
        </ReferenceInput>
    </>);
}

const GetTriggerAndFilter = ({triggerId}) => {
    const record = useGetOne('triggers', { id: triggerId }).data;
    let event_filter = ['General'];
    if (record && record.category) event_filter.push(record.category);
    return <FilteredTemplates event_types={event_filter} />;
}

const SetValidEventType = ({ event_types }) => {
    useFormContext().setValue('valid_event_types', event_types);
    return null;
}
/* End template selection functions */

const MessageAutomationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data: any) => {
        notify('Automation created', { type: 'info' });
        redirect('/messageautomations'); 
    }

    const onCancel = (data: any) => {
        redirect('/messageautomations');
    }

    useEffect(() => { // move template <create> option to top on automation pages
        document.body.classList.add("message-automations-page");
        return () => {
            document.body.classList.remove("message-automations-page");
        }
    })

    const CustomToolbar = (props) => {
        return <Toolbar {...props} style={{display: "flex", justifyContent:"space-between"}}>
            <Button
                label='Cancel'
                size='medium'
                variant='contained'
                onClick={onCancel}
            />
            <SaveButton />
        </Toolbar>
    }

    return (
        <Create
            className='automation-create'
            sx={{ maxWidth: '1020px' }}
            transform={transform}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='automation'
                toolbar={<CustomToolbar />}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Create Automation</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        <BooleanInput source='enabled' label='Enabled' defaultValue={false} />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <p className='automation-label'>1. Name your automation:</p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='automation-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                        <CheckboxGroupInput
                            source='channels'
                            label=''
                            choices={[
                                { id: 'sms', name: 'Text Message' },
                                { id: 'email', name: 'Electronic Mail' },
                            ]}
                            validate={[required()]}
                            defaultValue={['sms']}
                        />
                    </Grid>
                </Grid>

                <ScheduleCriteria mode='create' />

                <SelectionCriteria mode='create' />

                <Grid {...gridProps} className='automation-richtext'>
                    <p className='automation-label'>5. Select your message:</p>
                    <Grid item xs={12}>
                        <FormDataConsumer>
                            {({ formData }) => {
                                if (formData.type === 'event' && formData.trigger_id) {
                                    return <GetTriggerAndFilter triggerId={formData.trigger_id} />
                                } else if (formData.type === 'appointment') {
                                    return <FilteredTemplates event_types={['General', 'Appointments']} />
                                } else {
                                    return <FilteredTemplates event_types={null} />
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => <>
                            {formData.template && (
                                <Grid item xs={12}>
                                    <RenderMessageTemplate template_id={formData.template} />
                                </Grid>
                            )}
                        </>}
                    </FormDataConsumer>
                </Grid>
                <SendTestMessage origin='automation' />
            </SimpleForm>
        </Create>
    );
};

export default MessageAutomationCreate;
