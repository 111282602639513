import { useTiptapEditor } from 'ra-input-rich-text';
import '../../scss/components/enhancedmessaging/ToolbarDynamicText.scss';
import { FormDataConsumer } from 'react-admin';

const ToolbarDynamicText = () => {
    const editor = useTiptapEditor();

    const insertText = (e: any) => {
        if (editor) {
            const text = e.target.value;
            editor.chain().focus().insertContent(text).run();
            e.target.value = '';
        }
    }

    return (
        <FormDataConsumer>
            {({ formData }) => {
                return (
                    <select
                        className='toolbar-dynamic-text'
                        onChange={(e) => insertText(e)}
                    >
                        <option value="">Insert Dynamic Text</option>
                        <option value="{FirstName}">First Name</option>
                        <option value="{LastName}">Last Name</option>
                        <option value="{LastAppointment}">Last Appointment</option>
                        <option value="{NextAppointment}">Next Appointment</option>
                        <option value="{PracticeName}">Practice Name</option>
                        <option value="{PracticePhone}">Practice Phone</option>
                        <option value="{PracticeAddress}">Practice Address</option>
                        <option value="{ReviewURL}">Review URL</option>
                        <option value="{LastProviderName}">Name of Last Provider</option>
                        <option value="{NextProviderName}">Name of Next Provider</option>
                        {formData.event_type && formData.event_type === "Appointments" &&
                            <>
                            <option value="{AppointmentDate}">Appointment Date</option>
                            <option value="{AppointmentTime}">Appointment Time</option>
                            <option value="{AppointmentType}">Appointment Type</option>
                            <option value="{AppointmentDurationMinutes}">Appointment Duration Minutes</option>
                            <option value="{AppointmentProviderName}">Appointment Provider Name</option>
                            <option value="{AppointmentPracticeLocation}">Appointment Practice Location</option>
                            <option value="{AppointmentPracticeAddress}">Appointment Practice Address</option>
                            <option value="{AppointmentPracticePhone}">Appointment Practice Phone</option>
                            {/* <option value="{AppointmentAction}">Appointment Action</option> */} 
                            </>
                        }
                        {/*  ExerciseName and PainLevel dynamic text no longer allowed
                        {formData.event_type && formData.event_type === "Report Pain" &&
                            <>
                            <option value="{ExerciseName}">Exercise Name (Report Pain)</option>
                            <option value="{PainLevel}">Pain Level (Report Pain)</option>
                            </>
                        } */}

                        
                        {/*  Patient Age dynamic text no longer allowed 
                        {formData.event_type && formData.event_type === "Birthday" &&
                            <option value="{PatientAge}">Patient Age (Birthday)</option>
                        } */}
                        {formData.event_type && formData.event_type === "Message Received" &&
                            <option value="{MessageType}">Message Type</option>
                        }
                        {formData.event_type && formData.event_type === "Opt-In" &&
                            <>
                            <option value="{OptInStatus}">Opt-In Status</option>
                            <option value="{OptInType}">Opt-In Type</option>
                            </>
                        }
                        {formData.event_type && formData.event_type === "RTM Code" &&
                            <option value="{RTMCode}">RTM Code</option>
                        }
                        {formData.event_type && formData.event_type === "Rating" && 
                            <option value="{Rating}">Rating</option>
                        }
                        {formData.event_type && formData.event_type === "Appointment Confirmation" &&
                            <>
                            <option value="{ConfirmationResponse}">Confirmation Response</option>
                            <option value="{AppointmentDate}">Appointment Date</option>
                            <option value="{AppointmentTime}">Appointment Time</option>
                            <option value="{AppointmentType}">Appointment Type</option>
                            <option value="{AppointmentDurationMinutes}">Appointment Duration Minutes</option>
                            <option value="{AppointmentProviderName}">Appointment Provider Name</option>
                            <option value="{AppointmentPracticeLocation}">Appointment Practice Location</option>
                            <option value="{AppointmentPracticeAddress}">Appointment Practice Address</option>
                            <option value="{AppointmentPracticePhone}">Appointment Practice Phone</option>
                            {/* <option value="{AppointmentAction}">Appointment Action</option> */} 
                            </>
                        }
                        {formData.capture_response && formData.capture_response === true && // only allowed on templates that are capturing a response
                          <option value="{ResponseLink}">Response Link</option>
                        }
                    </select>
                );
            }}
        </FormDataConsumer>
    );
}

export default ToolbarDynamicText;