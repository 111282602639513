import moment from 'moment-timezone';

const transformAutomationsData = (data: any) => {
    if (data.type === 'event') {
        // delete triggers for other types
        delete data.trigger_data.appointment;
        delete data.trigger_data.date_time;

        // set hold
        let hold_seconds = 0;
        const trigger_event = data.trigger_data.event;
        if (trigger_event.hold) {
            hold_seconds = trigger_event.hold_number;
            if (trigger_event.hold_unit === 'minute') hold_seconds *= 60;
            else if (trigger_event.hold_unit === 'hour') hold_seconds *= 3600;
            else if (trigger_event.hold_unit === 'day') hold_seconds *= 86400;
        }

        trigger_event.hold_seconds = hold_seconds;
    } else if (data.type === 'appointment') {
        // delete triggers for other types
        delete data.trigger_data.event;
        delete data.trigger_data.date_time;

        // set hold
        const trigger_appt = data.trigger_data.appointment;
        let appointment_seconds = trigger_appt.appointment_number;
        if (trigger_appt.appointment_unit === 'minute') appointment_seconds *= 60;
        else if (trigger_appt.appointment_unit === 'hour') appointment_seconds *= 3600;
        else if (trigger_appt.appointment_unit === 'day') appointment_seconds *= 86400;

        trigger_appt.appointment_seconds = appointment_seconds;
    } else if (data.type === 'time') {
        // delete triggers for other types
        delete data.trigger_data.event;
        delete data.trigger_data.appointment;

        // set when
        const time = new Date(data.trigger_data.date_time.time);
        let whenHours = time.getHours() || '00';
        let whenMinutes = time.getMinutes() || '00';

        // add leading zero when needed
        whenHours = ('0' + whenHours).slice(-2);
        whenMinutes = ('0' + whenMinutes).slice(-2);

        const practiceTZ = localStorage.getItem('selectedPracticeTZ') || 'America/New_York'
        const dateTime = `${data.trigger_data.date_time.date} ${whenHours}:${whenMinutes}:00`;
        const time_when = moment.tz(dateTime, practiceTZ).unix();

        data.when = time_when;
        data.trigger_data.date_time.when = time_when;
    } else {
        // delete triggers for other types - keep trigger_data.provider_notification in this case
        if (data.trigger_data && data.trigger_data.appointment) delete data.trigger_data.appointment;
        if (data.trigger_data && data.trigger_data.date_time) delete data.trigger_data.date_time;
        if (data.trigger_data && data.trigger_data.event) delete data.trigger_data.event;
    }

    if (data.advanced_query_enabled) { // fix formatting
        data.selection_criteria = JSON.stringify(JSON.parse(data.selection_criteria));
    } else {
        data.selection_criteria = JSON.stringify(data.selection_criteria);
    }

    // delete fields that are not in the model
    delete data.selection_criteria_with_error; // temporary "backup" of advanced query
    delete data.valid_event_types; // used to clear template selection
    // remove test message fields
    delete data.test_sms;
    delete data.test_email;

    return data;
}

export default transformAutomationsData;