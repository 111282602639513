import React, { useState, useEffect } from 'react';
import {
    PieChart,
    Pie,
    Cell,
    Label,
    ResponsiveContainer,
    Tooltip as RechartToolTip,
    Legend,
} from 'recharts';
import { styled } from '@mui/material/styles';
import { Card, CardContent } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import dataProvider from '../dataProvider';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import tokenManager from '../tokenManager';

const COLORS = ['#007AFF', '#EB5757', '#F2C94C', '#6FCF97'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    // index,
    value
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text
            x={x}
            y={y}
            fill="black"
            fontFamily="arial"
            fontWeight={400}
            fontSize={14}
            textAnchor="middle"
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}% (${value})`}
        </text>
    );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

const renderColorfulLegendText = (value: string, entry: any) => { return <span style={{ color: "#828282", fontSize: "12px" }}>{value}</span>; };

export default function App(props) {

    const [data, setData] = useState<any | []>([]);

    useEffect(() => {
        // TODO the auth0Token in localstorage is set just before this is called in authProvider. figure out a better way to listen for change
        // checking for localStorage change doesnt work, something like
        // window.addEventListener("storage", e => storageChange(e));
        // return () => {
        //     window.removeEventListener('storage', e => storageChange(e));
        // };
        setTimeout(()=>{ // migrated functionality of "getApiData" to here to fix build warning
            if(tokenManager.getToken()) {
                dataProvider.get(`kaizenovate/provider/1.0.0/current_patients?month=${props.month}`).then(result => {
                    setData(result.data);
                });
            }
        }, 100)
    }, [props.month]);

    var dataTotalValue = 0;
    data.map((entry, index) => ( dataTotalValue += entry.value ));

    return (
        <Card>
            <CardContent className='card-header'>
                Current Patient Mix
                <HtmlTooltip
                    title={
                    <React.Fragment>
                        <Typography color="inherit">Current Patient Mix</Typography>
                        <br />
                        {"New:"}<li>{"Patient's first visit was in selected time period"}</li>
                        <br />
                        {"Self-Discharge Risk:"}<li>{"Missed or canceled at least 1 appointment in selected time period"}</li>
                        <br />
                        {"Returning:"}<li>{"Patient was already in the EHR but this is the first clinic visit in trailing 60 days"}</li>
                        <br />
                        {"Active:"}<li>{"Patient has attended 1 or more appointments in trailing 60 days"}</li>
                    </React.Fragment>
                    }
                >
                    <InfoOutlined fontSize='small'/>
                </HtmlTooltip>
            </CardContent>
            <CardContent>
                <div style={{ margin: 'auto', height: 400 }}>
                    <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                            <RechartToolTip />
                            <Legend
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="center"
                                formatter={renderColorfulLegendText}
                                iconType={'circle'}
                            />
                            <Pie
                                data={data}
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={170}
                                fill="#8884d8"
                                dataKey="value"
                                innerRadius={70}
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                                <Label
                                    position="centerBottom"
                                    fill="#a0a0a0"
                                    height="200px"
                                    width="150px"
                                    fontSize="14px"
                                    fontFamily="arial"
                                    fontWeight={400}
                                >
                                    TOTAL &nbsp;
                                </Label>
                                <Label
                                    position="centerTop"
                                    height={100}
                                    color="black"
                                    value={dataTotalValue}
                                    fontSize="28px"
                                    fontFamily="arial"
                                    fontWeight={400}
                                    offset={-200}
                                ></Label>
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
}