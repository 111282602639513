import { Grid, Box, Typography, Drawer, Stack, IconButton, Button } from '@mui/material';

import { 
    Show, 
    ShowController,
    TabbedShowLayout, 
    Tab, 
    useRecordContext,
    useUpdate
} from 'react-admin';
import PatientDetailsTab from './PatientDetailsTab';
import NewPatientInfoTab from './NewPatientInfoTab';
import HomeExercisePlan from './HomeExercisePlan';
import ActivityLog from './ActivityLog';
import PatientMessages from './PatientMessages';
import BillingTab from './BillingTab';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from "react";

import '../../scss/pages/Patients.scss';
import { useResolveAction } from '../../resolveAction.js';

const PatientEdit = (props) => {

    const [open, setOpen] = useState(false);
    const [currRecord, setCurrRecord] = useState<any>();

    const handleDrawerOpen = (record?) => {
        setCurrRecord(record);
        setOpen(true);
    };
  
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
      <div>
        <ShowController {...props}>
          {(controllerProps) =>
            controllerProps.record?.patient_actions.length > 0 ? (
              <Show
                {...props}
                actions={false}
                className="patient-checkbox-container"
              >
                <Grid item xs={12}>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box display="flex" flexDirection="column" >
                      <Typography variant="h6" fontWeight="bold">
                        {controllerProps.record?.first}{" "}{controllerProps.record?.last}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom mb={3}>
                        Click to Resolve Patient Actions:{" "}
                      </Typography>
                    </Box>
                    <ResolveActionCheckboxes />
                  </Box>
                </Grid>
              </Show>
            ) : (
              <div></div>
            )
          }
        </ShowController>
        {/* <ShowController {...props}>
                {controllerProps => 
                    <Show {...props} {...controllerProps} actions={false} className="patients">
                        {(controllerProps.record && controllerProps.record.start_billing_date) ? 
                        (
                        <Grid item xs={12} md={4} p={2}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Billing Details for <FunctionField fontSize={"2.125rem"} fontWeight='fontWeightBold' render={record => record && (record["first"] + " " + record["last"]) } />
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        Current 7-day duration
                                    </Typography>
                                    <Box fontWeight='fontWeightMedium'>
                                        <FunctionField fontSize={18} render={record => record && record["current_billing_duration"] ? formatSeconds(record["current_billing_duration"]) : ""} />
                                    </Box>
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        Start Date
                                    </Typography>
                                    <Box fontWeight='fontWeightMedium'>
                                        <DateField fontSize={18} source='start_billing_date'/>
                                    </Box>
                                </Box>
                                <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        Start Time
                                    </Typography>
                                    <TextField fontSize={18} source='start_billing_time'/>
                                </Box>
                            </Box>
                        </Grid>
                        ) : (
                        <Grid item xs={12} p={2}>
                            <Typography variant="subtitle1" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Billing Details (Not Covered) - Beyond 7-Day Duration
                                </Box>
                            </Typography>
                        </Grid>
                        )
                        }
                    </Show>
                }
            </ShowController> */}
        <ShowController {...props}>
          {(controllerProps) => {
            return (
              <Show {...props} actions={false} className="patients">
                <TabbedShowLayout>
                  <Tab label="Patient Details" path="patient-details">
                    <PatientDetailsTab />
                  </Tab>
                  <Tab label="Billing" path="billings">
                    <BillingTab />
                  </Tab>
                  <Tab label="Home Exercise Plan" path="home-exercise-plan">
                    <HomeExercisePlan />
                  </Tab>
                  <Tab label="Activity Log" path="activity-log">
                    <ActivityLog onSelectActivity={handleDrawerOpen} />
                    <Drawer
                      variant="persistent"
                      open={open}
                      anchor="right"
                      onClose={handleDrawerClose}
                      PaperProps={{
                        sx: {
                          zIndex: 100,
                          width: 465,
                        },
                      }}
                    >
                      <ActivityLogEdit
                        onCancel={handleDrawerClose}
                        currentRecord={currRecord}
                      />
                    </Drawer>
                  </Tab>
                  <Tab label="Messages" path="patient-messages">
                    <PatientMessages />
                  </Tab>
                  {controllerProps.record?.patient_actions.includes(
                    "New Patient"
                  ) ||  controllerProps.record?.patient_actions.includes(
                    "New Patient (Duplicate)"
                  )? (
                    <Tab label="New Patient Info" path="new-patient-info">
                      <NewPatientInfoTab />
                    </Tab>
                  ) : null}
                </TabbedShowLayout>
              </Show>
            );
          }}
        </ShowController>
      </div>
    );
};

const ResolveActionCheckboxes = () => {
    const record = useRecordContext();
    return useResolveAction(record)
}

const ActivityLogEdit = ({ onCancel, currentRecord }) => {

    // record needs to be current patient
    const [comment, setComment] = useState(currentRecord?.comment ?? "");
    const [thisRecord, setThisRecord] = useState(currentRecord);
    const [update, { error }] = useUpdate();
   
    if(thisRecord !== currentRecord) {
        setThisRecord(currentRecord);
        setComment(currentRecord?.comment);
    }

    const handleClick = () => {
        update(
            'logentries',
            { id: thisRecord.id, data: {comment: comment}, previousData: thisRecord }
        ).then((result) => {
            onCancel()
        })
        if (error) { return <p>ERROR</p>;}
    }

    return (
        <div className='activity-log-drawer'>
            <Stack direction="row" p={2} mt={4}>
                <Typography variant="h4" flex="1">
                    
                </Typography>
                <IconButton onClick={onCancel} size="small">
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Grid>
                <Grid container rowSpacing={1} mb={4}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" className='c-grey'>
                            Date
                        </Typography>
                        { (currentRecord?.date !== 0 && typeof currentRecord?.date == 'number') ? new Date(currentRecord?.date*1000).toLocaleString() : currentRecord?.date}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" className='c-grey'>
                            Duration
                        </Typography>
                        {currentRecord?.duration}
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={4}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" className='c-grey'>
                            Activity
                        </Typography>
                        {currentRecord?.subject}
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} mb={4}>
                    <Typography variant="subtitle2" className='c-grey'>
                        Comment
                    </Typography>
                    <Grid item xs={12}>
                        <textarea value={comment} onChange={e => setComment(e.target.value)} rows={6}></textarea>
                    </Grid>
                </Grid>
            </Grid>
            <Button onClick={() => handleClick()} className="save">Save</Button>
        </div>
    )
}

export default PatientEdit;
