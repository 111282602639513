import { Box, Grid, Typography } from '@mui/material';
import { 
    Create,
    SimpleFormIterator,
    SimpleForm,
    TextInput,
    ArrayInput,
    SelectInput,
    ReferenceInput,
    useRedirect,
 } from 'react-admin';

import '../../scss/pages/Layouts.scss';

const MasterprogramsCreate = (props) => {
    
    const redirect = useRedirect();
    
    const onSuccess = (data) => {
        redirect(`/masterprograms`);
    };

    return (
        <Create 
        {...props}
        mutationOptions={{ onSuccess }}
        >
            <SimpleForm 
            {...props}
            // validate={validateForm}
            >
                <div>
                    <Grid container width={{ xs: '100%', xl: 1200 }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Protocol
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={6} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="name" fullWidth />
                                </Box>
                                <Box flex={6} mr={{ xs: 0, sm: '0.5em' }}>
                                    <ReferenceInput
                                        source="condition_id"
                                        reference="conditions"
                                        sort={{ field: 'name', order: 'ASC' }}
                                    >
                                        <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                    </ReferenceInput>
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                    <ArrayInput source="relieve_exercises">
                                        <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                                            <ReferenceInput
                                                source="easy_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="default_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                isRequired
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth isRequired />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="hard_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                    <ArrayInput source="restore_exercises">
                                        <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                                            <ReferenceInput
                                                source="easy_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="default_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                isRequired
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="hard_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                    <ArrayInput source="reinforce_exercises">
                                        <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                                            <ReferenceInput
                                                source="easy_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="default_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                isRequired
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="hard_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Create>
    );
};

export default MasterprogramsCreate;
