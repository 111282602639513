import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList
} from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
    endOfToday,
    endOfMonth,
    endOfWeek,
} from 'date-fns';
import { subDays } from 'date-fns';

const GetUnixTime = (date: Date) => {
    return Math.round(new Date(date).getTime()/1000);
}

const Aside = () => {

    const triggers = useGetList('triggers', {sort: {field: 'name', order: "ASC"}}).data;
    return (
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 16em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch source="name" label="Automation Name" />

                <FilterList
                    label="Last Sent"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Today"
                        value={{
                            last_sent: {
                                ">=": GetUnixTime(endOfYesterday()),
                                "<=": GetUnixTime(endOfToday()),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This week"
                        value={{
                            last_sent: {
                                ">=": GetUnixTime(subDays(startOfWeek(new Date()),1)),
                                "<=": GetUnixTime(endOfWeek(new Date())), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Last week"
                        value={{
                            last_sent: {
                                ">=": GetUnixTime(subWeeks(subDays(startOfWeek(new Date()),1),1)),
                                "<=": GetUnixTime(subDays(startOfWeek(new Date()),1)),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This month"
                        value={{
                            last_sent: { 
                                ">=": GetUnixTime(subDays(startOfMonth(new Date()), 1)),
                                "<=": GetUnixTime(endOfMonth(new Date())), 
                             },
                        }}
                    />
                    <FilterListItem
                        label="Last month"
                        value={{
                            last_sent: {
                                ">=": GetUnixTime(subMonths(subDays(startOfMonth(new Date()), 1),1)),
                                "<=": GetUnixTime(subDays(startOfMonth(new Date()), 1)),
                            },
                        }}
                    />
                    <FilterListItem
                        label="Earlier"
                        value={{
                            last_sent: {"<=": GetUnixTime(subMonths(subDays(startOfMonth(new Date()), 1),1)) },
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Triggers"
                    icon={<EventIcon />}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.1rem' }}>
                        {triggers && triggers.length > 0 ? triggers.map((trigger: any) => (
                            <FilterListItem
                                label={trigger.name}
                                value={{ trigger: trigger.id }}
                            />
                        )) : null}
                    </div>
                </FilterList>

                <FilterList
                    label="Inactive"
                    icon={<TaskAltIcon />}
                >
                    <FilterListItem
                        // label="ra.boolean.true"
                        label="Hide"
                        value={{ 
                            enabled: {
                                "!=": 0,
                            },
                        }}
                    />
                    <FilterListItem
                        // label="ra.boolean.false"
                        label="Show"
                        value={{ 
                            enabled: 0,
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Type"
                    icon={<EventIcon />}
                >
                    <FilterListItem
                        label="Triggered"
                        value={{ type: "event" }}
                    />
                    <FilterListItem
                        label="Timed"
                        value={{ type: "time" }}
                    />
                    <FilterListItem
                        label="Conditions Match"
                        value={{ type: "firstTrue" }}
                    />
                    <FilterListItem
                        label="Reminder/Missed"
                        value={{ type: "appointment" }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    )
};

export default Aside;
