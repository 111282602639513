import { Button, FormGroup, Box } from "@mui/material"
import React, { useContext, useState } from "react"
import { UserContext } from "../context/UserContext"
import { Card, Avatar, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';

import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { TextInput, Link } from 'react-admin';
import {
    Form,
    required,
    email,
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
} from 'ra-core';
import config from '../config'
const { authUrl, adminUrl } = config

const RecoverPassword = () => {

  const resetPasswordPath = `${adminUrl}/?token={token}#/RedirectPassword`
  const notify = useNotify();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [username, setUsername] = useState("")
  const [loading, setLoading] = useSafeSetState(false);

  const formSubmitHandler = e => {
    console.log(e)
    setIsSubmitting(true)
    setError("")

    const genericErrorMessage = "Something went wrong! Please try again later."

    fetch(`${authUrl}/users/recoverPassword`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, url: resetPasswordPath }),
    })
    .then(async response => {
      setIsSubmitting(false)
      if (!response.ok) {
        if (response.status === 400) {
          setError("Please fill all the fields correctly!")
        } else if (response.status === 401) {
          setError("User not found")
        } else {
          setError(genericErrorMessage)
        }
      } else {
        const data = await response.json()
        console.log(data)
        if (data.success) {
          window.location.href='/#/Sent'
        } else {
          setError(`We were unable to send you a password reset at this time.  Please check your email address and try again.`)
        }
      }
    })
    .catch(error => {
      setIsSubmitting(false)
      setError(genericErrorMessage)
    })
  }

  return (
        <Root>
            <Card className={LoginClasses.card}>
                <div className={LoginClasses.avatar}>
                    <Avatar className={LoginClasses.icon}>
                        <LockIcon />
                    </Avatar>
                </div>
                <CardContent className={LoginClasses.content}>
                  <StyledForm
                      onSubmit={formSubmitHandler}
                      mode="onChange"
                      noValidate
                      className="recoverPassword"
                  >
                    <TextInput
                        autoFocus
                        source="username"
                        label="Email"
                        type="email"
                        autoComplete="username"
                        validate={[required(), email()]}
                        onChange={e => setUsername(e.target.value)}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                        className={LoginClasses.button}
                    >
                        {loading ? (
                            <CircularProgress
                                className={LoginClasses.icon}
                                size={19}
                                thickness={3}
                            />
                        ) : (
                            "Reset Password"
                        )}
                    </Button>
                </StyledForm>
              </CardContent>
            </Card>
        </Root>
  )
}

export default RecoverPassword

const PREFIX = 'Kaizo';
export const LoginClasses = {
    card: `${PREFIX}-card`,
    avatar: `${PREFIX}-avatar`,
    icon: `${PREFIX}-icon`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginClasses.button}`]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    [`& .${LoginClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));


const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${LoginClasses.content}`]: {
        width: 300,
    },    
    [`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },
}));