import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
    FilterList,
    FilterListItem,
    useGetList,
} from 'react-admin';

import { Musclegroup } from '../../types';

const Aside = () => {
    const { data : muscleGroupData } = useGetList<Musclegroup>('musclegroups');

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                {/*
                <FilterLiveSearch source="name"/> 
                <FilterList
                    label="conditions"
                    icon={<LocalOfferIcon />}
                >
                    {conditionData &&
                        conditionData.map((record: any) => (
                            <FilterListItem
                                label={record.name}
                                key={record.name}
                                value={{ conditions : record.name }}
                            />
                        ))}
                </FilterList>
                */}
                <FilterList
                    label="Regions"
                    icon={<LocalOfferIcon />}
                >
                    {muscleGroupData &&
                        muscleGroupData.map((record: any) => (
                            <FilterListItem
                                label={record.name}
                                key={record.name}
                                value={{ muscle_groups : record.name }}
                            />
                        ))}
                </FilterList>

            </CardContent>
        </Card>
    );
};

export default Aside;
