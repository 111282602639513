import { Grid } from '@mui/material';
import {
    Button,
    Edit,
    RecordContext,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useNotify,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import { convert } from 'html-to-text';
import '../../scss/pages/EnhancedMessaging.scss';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

const transform = (data: any) => {
    // remove links from message only keeps the text of the a tag
    data.message = convert(data.message.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), {
        wordwrap: false,
    }) // convert html to text
        .replace(/[\n]{2}/g, '\n') // fix duplicate newlines
        .replace(/[\n]{2,}/g, '\n\n') // no more than one empty line
        .trim() // no leading/trailing newlines or spaces

    return data;
};

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
};

const editorOptions = { // Disable automatic URL linking
    extensions: [StarterKit, Link.configure({ autolink: false })]
}

const CustomToolbar = (props) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={props.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

const SavedChatMessageEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();


    const onCancel = (data: any) => {
        redirect('/savedchatmessages');
    }

    const onSuccesss = () => {
        notify("Saced Message updated successfully");
        redirect('/savedchatmessages');
    }

    return (
        <RecordContext.Consumer>
            {record => {
                return <SavedChatMessageEditForm record={record} onCancel={onCancel} onSuccess={onSuccesss}/>
            }}
        </RecordContext.Consumer>
    );
};

export const SavedChatMessageEditModal = ({record, onCancel, onSuccess}) => {
    console.log("SavedChatMessageEditModal record: ", record);
    return <SavedChatMessageEditForm record={record} onCancel={onCancel} onSuccess={onSuccess}/>;
}

const SavedChatMessageEditForm = ({ record, onCancel, onSuccess }) => {
    console.log("SavedChatMessageEditForm record: ", record);
    console.log("SavedChatMessageEditForm onCancel: ", onCancel);
    return (
        <Edit
            resource='savedchatmessages'
            className='saved-chat-message-edit'
            sx={{ maxWidth: "1020px" }}
            transform={transform}
            mutationMode="pessimistic"
            id={record.id}
            mutationOptions={{ onSuccess: onSuccess }}
            disableAuthentication={true}
        >
            <RecordContext.Consumer>
                {record => {
                    // repalce newlines with <p> tags
                    let sms = record.message ? record.message.replaceAll('\n', '</p><p>') : '';
                    if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
                    // no more than one consecutive empty paragraph
                    sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');

                    const newRecord = {
                        ...record,
                        message: sms,
                    }

                    return (<SimpleForm
                        sx={{ pt: 0, pb: 0 }}
                        className="saved-chat-message-form"
                        record={newRecord}
                        toolbar={<CustomToolbar onCancel={onCancel}/>}
                    >
                        <Grid {...gridProps}>
                            <Grid item xs={10}>
                                <h1>Edit Saved Message</h1>
                            </Grid>
                        </Grid>
                        <Grid {...gridProps}>
                            <Grid item xs={12}>
                                <p >Name</p>
                                <TextInput source='name' validate={[required()]} fullWidth />
                            </Grid>
                        </Grid>

                        <Grid {...gridProps} className='saved-chat-message-form-richtext'>
                            <Grid item xs={12}>
                                <p>Message</p>
                                <RichTextInput
                                    source='message'
                                    fullWidth
                                    label={false}
                                    validate={[required()]}
                                    toolbar={<CustomRichTextToolbar richTextMode={false} />}
                                    editorOptions={editorOptions}
                                    sx={{minHeight: '200px'}}
                                />
                            </Grid>
                        </Grid>
                    </SimpleForm>);
                }}
            </RecordContext.Consumer>
        </Edit>
    );
};
