import MusclegroupsList from './MusclegroupsList';
import MusclegroupsEdit from './MusclegroupsEdit';
import MusclegroupsCreate from './MusclegroupsCreate';

const resource = {
    list: MusclegroupsList,
    edit: MusclegroupsEdit,
    create: MusclegroupsCreate,
};

export default resource;