import { Box } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ChipField,
    FunctionField,
} from 'react-admin';
import SegmentsField from './SegmentsField';

import MessageLogsAside from './MessageLogsAside';


const MessageLogsList = () => {
    return (
        <Box mt={2}>
            <List
                // changing to the more appropriate sent_date (attempted send) 
                sort={{ field: "sent_date", order: "DESC" }}
                aside={<MessageLogsAside />}
                filter={{ channel: {'!=': 'providerNotification'} }}
            >
                {/* needs row click show */}
                <Datagrid bulkActionButtons={false} rowClick="show">
                    <ReferenceField source="automation.id" reference="messageautomations" label="Automation Name" link={false} sortable={false}>
                        <TextField source='name' />
                    </ReferenceField>
                    <ReferenceField source="user.id" reference="users" label="Patient Name" sortable={false}/>
                    <ChipField sx={{ textTransform: 'capitalize' }} source="result" label="Result"  />
                    <SegmentsField source="type" label="Type"  />
                    <ChipField sx={{ textTransform: 'capitalize' }} source="channel" label="Channel"  />
                    {/* sent_date is in seconds*/}
                    {/* staging should always have a sent_date even on failure for email shouldn't need to change else condition */}
                    <FunctionField label="Date Attempted" sortBy="sent_date" render={(record: any) => record && record.sent_date !== 0 ? new Date(record.sent_date * 1000).toLocaleString() : "N/A"} />

                </Datagrid>
            </List>
        </Box>
    )
};

export default MessageLogsList;