import {
    Box, IconButton, Tooltip, Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';

import {
    List,
    Datagrid,
    TextField,
    FilterLiveSearch,
    ChipField,
    Button,
    useRecordContext
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";


const ToolTipField = ({ source }) => {
    const record = useRecordContext()
    let parseTitle = record[source].split("\n").join("<br />")
 
    const newTitle = `<div>${parseTitle}</div>`
    
    return ( record && record.notes ?
        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: newTitle }} />}>
            <IconButton>
                <InfoIcon />
            </IconButton>
        </Tooltip>
    : null)
}

const SelectEventButton = ({ setModalOpen, mode }) => {
    const record = useRecordContext();
    const formContext = useFormContext();
    const handleClick = () => {
        console.log(record.id);
        setModalOpen(false);
        if (mode === 'create') formContext.setValue('trigger_id', record.id);
        else if (mode === 'edit') formContext.setValue('trigger.id', record.id);
    };

    return <Button label="Select" onClick={handleClick} />
}

const TriggersList = ({ modalOpen, setModalOpen, mode }) => {

    const handleClose = () => {
        setModalOpen(false);
    };

    return (
        <Dialog
            open={modalOpen}
            onClose={handleClose}
            scroll="body"
            sx={{ "& .MuiDialog-paper": { minWidth: "75vw" , minHeight: "65vh"} }} >
            <DialogTitle
                sx={{
                    paddingBottom: "0px",
                    marginBottom: "0px !important",
                }}
            >
                Select Event Trigger
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Box>

                    <List

                        actions={false}
                        sort={{ field: "name", order: "ASC" }}
                        resource="triggers"
                    >
                        {/* implement fuzzy search for casing? */}
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <FilterLiveSearch source="name" label='Name' sx={{ m: 2, maxWidth: "80%" }} />
                            <FilterLiveSearch source="category" label='Category' sx={{ m: 2, maxWidth: "80%" }} />
                        </div>

                        <Datagrid
                            bulkActionButtons={false}
                        >
                            <TextField source='name' label="Name" sortable={true} />
                            <TextField source='fires_when' label="Trigger Fires When" sortable={false} />
                            <ChipField source='category' label="Category" sortable={true} />
                            <ToolTipField source="notes" />
                            <SelectEventButton setModalOpen={setModalOpen} mode={mode} />
                        </Datagrid>
                    </List>

                </Box>
            </DialogContent>
        </Dialog>
    )
};

export default TriggersList;