import * as React from 'react';
import { Box } from '@mui/material';
import {
    Datagrid,
    TextField,
    ListBase
} from 'react-admin';

const ExerciseList = () => {
    return (
        <Box mt={5}>
        <ListBase>   
            <Datagrid
                optimized
                rowClick="edit"
                bulkActionButtons={false}
            >
                <TextField source="patient_id" />
                <TextField source="level" />
                <TextField source="easier_exercise.exercise" />
                <TextField source="default_exercise.exercise" />
                <TextField source="harder_exercise.exercise" />
                <TextField source="status" />
            </Datagrid>
        </ListBase>
        </Box>
    );
};

export default ExerciseList;
