import React, { useState } from "react";
import { useCallback } from 'react';
import { Box, useMediaQuery, Theme, Drawer } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import {
    CreateButton,
    FilterButton,
    FilterForm,
    FilterContext,
    ListBase,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useGetResourceLabel,
} from 'react-admin';

import ImageList from './GridList';
import Aside from './Aside';

import ExerciselibrarysEdit from './ExerciselibrarysEdit';

const ExerciselibrarysList = () => {
    const getResourceLabel = useGetResourceLabel();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate('/exerciselibrarys');
    }, [navigate]);

    const match = matchPath('/exerciselibrarys/:id', location.pathname);

    const [exerciseState] = useState( location.state );

    return (
        <Box>
            <ListBase>
                <Title defaultTitle={getResourceLabel('exerciselibrarys', 2)} />
                <FilterContext.Provider value={exerciselibrarysFilters}>
                    <TopToolbar>
                      <SortButton fields={['name', 'id']} />
                      {localStorage.getItem('isAdmin') === 'true' ? <CreateButton /> : null}
                    </TopToolbar>
                    {isSmall && (
                        <Box m={1}>
                            <FilterForm />
                        </Box>
                    )}
                </FilterContext.Provider>
                <Box display="flex">
                    <Aside />
                    <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                        <ImageList />
                        <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
                    </Box>
                </Box>
            </ListBase>
            <Drawer
                variant="persistent"
                open={!!match}
                anchor="right"
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        zIndex: 100,
                    }
                }}
            >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {!!match && (
                    <ExerciselibrarysEdit
                        id={(match as any).params.id}
                        onCancel={handleClose}
                        exerciseState={exerciseState}
                    />
                )}
            </Drawer>
        </Box>
    );
};

export const exerciselibrarysFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput
        source="condition_id"
        reference="conditions"
        sort={{ field: 'condition_id', order: 'ASC' }}
    >
        <SelectInput source="name" />
    </ReferenceInput>,
    // <NumberInput source="width_gte" />,
    // <NumberInput source="width_lte" />,
    // <NumberInput source="height_gte" />,
    // <NumberInput source="height_lte" />,
    // <QuickFilter
    //     label="resources.exercises.fields.stock_lte"
    //     source="stock_lte"
    //     defaultValue={10}
    // />,
];

const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        <SortButton fields={['name']} />
        <CreateButton />
        {/* <ExportButton /> */}
    </TopToolbar>
);

export default ExerciselibrarysList;
