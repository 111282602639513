import {
    ChatAutoComplete,
    SendButton,
    useMessageInputContext
   } from 'stream-chat-react';

   export const CustomMessageInput = () => {

    const { handleSubmit } = useMessageInputContext();

    return (
      <div
        className='str-chat__input-flat str-chat__input-flat--send-button-active'>
        <div className='str-chat__input-flat-wrapper'>
          <div className='str-chat__input-flat--textarea-wrapper'>
            <ChatAutoComplete />
            {/* https://getstream.io/chat/docs/sdk/react/customization/input_ui/ */}
          </div>
          <SendButton sendMessage={handleSubmit} />
        </div>
      </div>
    );
   };