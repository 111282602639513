import { useState } from 'react';
import { Show, ShowController, FunctionField, TextField, ReferenceField, fetchUtils, useRecordContext, useRefresh, LoginFormClasses, useNotify } from "react-admin";
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, CircularProgress } from "@mui/material";
import { useSafeSetState } from 'ra-core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BillingsEntries from "./BillingsEntries";
import tokenManager from '../../tokenManager';
import "../../scss/pages/Billings.scss";

export const BillingsEdit = (props) => {

    const [loading, setLoading] = useSafeSetState(false)
    const refresh = useRefresh();
    const navigate = useNavigate();
    const notify = useNotify();
    const [DialogOpen, setDialogOpen] = useState(false);

    const handleClickBilling = async (record) => {
        
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();
        setLoading(true)

        const copyString = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-billing-details`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billingId: record.id,
            }),
        }).catch((e)=> {
            console.log(`Error in billingsEdit:handleClickBilled()`)
            console.error(e)
            setLoading(false)
            notify('Failed to fetch billing details', {type: 'error'})
            throw e
        })

        navigator.clipboard.writeText(copyString.body)
        setLoading(false)
        
        // navigator.clipboard.writeText(
        //     "5/3/2023 - Jay Greenstein - Provider Called patient for RTM billing - 4:00 - Talked about x, y, and z\n" + 
        //     "5/2/2023 - Jay Greenstein - Resolved Patient Action - 2:43 - Completed: Sent Message\n"
        // )
        setDialogOpen(true);
    }
    const handleClickBilled = async (record) => {
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();
        setLoading(true)

        const copyString = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/get-billing-details`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billingId: record.id,
            }),
        }).catch((e)=> {
            console.log(`Error in billingsEdit:handleClickBilled()`)
            console.error(e)
            setLoading(false)
            notify('Failed to fetch billing details', {type: 'error'})
            throw e
        })

        navigator.clipboard.writeText(copyString.body)
        setLoading(false)
    }

    const CopyDetailsButton = () => {
        const record = useRecordContext();
        if (!record) {
            return null;
        }
        if (record.status === 'Ready for Billing') {
            return <Button sx={{ py: 1.5, backgroundColor: "#2439F2" }} variant="contained" onClick={async () => await handleClickBilling(record)}>
                {<ContentCopyIcon />}&nbsp;Copy Activity Details
            </Button>
        } else if (record.status === "Billed") {
            return <Button sx={{ py: 1.5, backgroundColor: "#2439F2" }} variant="contained" onClick={async () => await handleClickBilled(record)}>
                {<ContentCopyIcon />}&nbsp;Copy Activity Details
            </Button>
        } else {
            return null
        }
    }

    const StatusField = () => {
        const record = useRecordContext();
        if (record['status'] === 'Ready for Billing') {
            return <TextField sx={{ fontWeight: 'bold', color: 'green' }} fontSize={18} source='status'/>
        } else {
            return <TextField sx={{ fontWeight: 'bold' }} fontSize={18} source='status'/>
        }
    }

    const updateBillingStatus = async () => {
        const id = window.location.href.split('#')[1].split('/')[2]
        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const httpClient = fetchUtils.fetchJson;
        const accessToken = tokenManager.getToken();

        await httpClient(`${apiUrl}/kaizenovate/billings/1.0.0/mark-billed`, {
            method: 'POST',
            credentials: 'include',
            user: {
                authenticated: true,
                token: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                billing: id,
            }),
        }).catch((e)=> {
            console.log(`Error in logActivity:logActivity()`)
            console.error(e)
            throw e
        })
        setDialogOpen(false)
        refresh()
    }

    return (
        <div>
            <div>
                <Dialog
                    open={DialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                        Update status
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Has this item been submitted for billing in the EHR?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ color: "#2439F2" }} onClick={() => setDialogOpen(false)} autoFocus>
                            <CloseIcon />&nbsp;{"No"}
                        </Button>
                        <Button sx={{ color: "#2439F2" }} onClick={updateBillingStatus} autoFocus>
                            <CheckCircleIcon />&nbsp;{"Yes"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Dialog
                    open={loading!}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
                        Loading
                    </DialogTitle>
                    <DialogContent sx={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                        <DialogContentText id="alert-dialog-description">
                            Fetching billing details
                        </DialogContentText>
                        <CircularProgress
                            className={LoginFormClasses.icon}
                            size={19}
                            thickness={3}
                            sx={{display: 'flex', alignSelf: 'center', padding: '15px'}}
                        />
                    </DialogContent>
                </Dialog>
            </div>
            <ShowController {...props}>
                {controllerProps => 
                    <Show {...props} {...controllerProps} actions={false} className="billings">
                        <Grid item xs={12} md={4} p={2}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Billing Details for <FunctionField fontSize={"2.125rem"} fontWeight='fontWeightBold' render={record => record && (record["cpt_code"]) } />
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }} >
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        Patient Name
                                    </Typography>
                                    <Box fontWeight='fontWeightMedium'>
                                        <ReferenceField sx={{ fontWeight: 'bold' }} source="patient.id" reference="users" />
                                    </Box>
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        Start Date
                                    </Typography>
                                    <Box fontWeight='fontWeightMedium'>
                                        <FunctionField sx={{ fontWeight: 'bold' }} label="Billing Date" fontSize={18} render={record => record && new Date(record["start_date"] * 1000).toLocaleString().toString().slice(0,10).replace(',', '').replace(' ', '')} />
                                    </Box>
                                </Box>
                                <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        End Date
                                    </Typography>
                                    {
                                        <FunctionField sx={{ fontWeight: 'bold' }} label="Billing Date" fontSize={18} render={record => record && record["billing_date"] ? new Date(record["billing_date"] * 1000).toLocaleString().toString().slice(0,10).replace(',', '').replace(' ', '') : "-"} />
                                    }
                                </Box>
                                <Box flex={3} ml={{ xs: 0, sm: '0.5em' }}>
                                    <Typography mb={1} className="c-grey">
                                        Status
                                    </Typography>
                                    <StatusField/>
                                </Box>
                            </Box>
                        </Grid>
                        <BillingsEntries />
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'space-between',
                                width: '98%',
                                py: 2,
                                px: 2,
                                backgroundColor: '#E0E4ED',
                            }}>
                            <Button sx={{ color: "#2439F2", paddingRight: '15px' }}variant="text" onClick={() => {navigate(-1)}}>
                                {<ArrowBackIcon />}&nbsp;Go back
                            </Button>
                            <CopyDetailsButton />
                        </Box>
                    </Show>
                    
                }
            </ShowController>
        </div>
    )
}