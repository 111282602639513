import { useEffect, useState } from 'react';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import '../../scss/components/enhancedmessaging/QueryBuilder.scss';
import { QueryBuilderTrigger } from './QueryBuilderTrigger';
import { QueryBuilderGetList } from './QueryBuilderGetList';
import { useFormContext } from 'react-hook-form';
import { useWatch } from 'react-hook-form';

const dateOperators = [
    { label: '=', name: '=' },
    { label: 'before', name: '<' },
    { label: 'after', name: '>' },
    { label: 'on or before', name: '<=' },
    { label: 'on or after', name: '>=' },
    { label: 'between', name: 'between' },
];

const numberOperators = [
    { label: '=', name: '=' },
    { label: '<', name: '<' },
    { label: '>', name: '>' },
    { label: '<=', name: '<=' },
    { label: '>=', name: '>=' },
];

const multiselectOperators = [
    { label: 'is one of (select all that apply)*', name: 'in' }
];

const defaultField = [
    {
        name: 'default',
        label: 'Select...',
        valueEditorType: 'text',
        operators: numberOperators,
        placeholder: 'Enter a value...',
    },
];

const baseFields = [
    // date fields
    {
        name: 'days_last_activity',
        label: 'Days since last activity',
        inputType: 'number',
        operators: numberOperators,
    },    
    {
        name: 'date_last_activity',
        label: 'Last activity date',
        inputType: 'date',
        operators: dateOperators,
    },    
    {
        name: 'days_last_exercise',
        label: 'Days since last performed exercises',
        inputType: 'number',
        operators: numberOperators,
    },    
    {
        name: 'date_last_exercise',
        label: 'Last performed exercises date',
        inputType: 'date',
        operators: dateOperators,
    },
    {
        name: 'has_performed_exercises',
        label: 'Has ever performed exercies',
        valueEditorType: 'select',
        values: [
            { name: 'true', label: 'true' },
            { name: 'false', label: 'false' },
        ],
        operators: [{ name: '=', label: '=' }],
    },    
    // number of days fields
    {
        name: 'patient_status',
        label: 'Patient status',
        valueEditorType: 'select',
        values: [
            { name: 'active_care', label: 'Active care' },
            { name: 'discharged', label: 'Discharged' },
        ],
        operators: [{ label: '=', name: '=' }],
    },
    {
        name: 'app_usage_status',
        label: 'App usage status',
        valueEditorType: 'select',
        values: [
            { name: 'Active', label: 'Onboarded' },
            { name: 'Inactive', label: 'Not Onboarded' },
        ],
        operators: [{ label: '=', name: '=' }],
    },
    {
        name: 'days_last_appointment',
        label: 'Days since last appointment',
        inputType: 'number',
        operators: numberOperators,
    },
    {
        name: 'date_past_appointment',
        label: 'Past appointment date',
        inputType: 'date',
        operators: dateOperators,
    },
    {
        name: 'days_next_appointment',
        label: 'Days until next appointment',
        inputType: 'number',
        operators: numberOperators,
    },
    {
        name: 'date_upcoming_appointment',
        label: 'Upcoming appointment date',
        inputType: 'date',
        operators: dateOperators,
    },
    {
        name: 'future_appointment_exists',
        label: 'Upcoming appointment exists',
        valueEditorType: 'select',
        values: [
            { name: 'true', label: 'true' },
            { name: 'false', label: 'false' },
        ],
        operators: [{ name: '=', label: '=' }],
    },
    {
        name: 'checked_in',
        label: 'Has ever checked in',
        valueEditorType: 'select',
        values: [
            { name: 'true', label: 'true' },
            { name: 'false', label: 'false' },
        ],
        operators: [{ name: '=', label: '=' }],
    },
    {
      name: 'visits_since_new',
      label: "Appointment number (since new or reactivated)",
      inputType: 'number',
      operators: numberOperators,
    },
    {
        name: 'doctor_notes',
        label: 'Doctor notes',
        valueEditorType: 'text',
        operators: [{ name: 'contains', label: 'contains' }],
    },
    {
        name: 'days_patient_added',
        label: 'Days since patient added',
        inputType: 'number',
        operators: numberOperators,
    },
    {
        name: 'appointments_until_reward',
        label: 'Appointments until reward',
        inputType: 'number',
        operators: numberOperators,
    },
    {
        name: 'reward_available',
        label: 'Reward available',
        valueEditorType: 'select',
        values: [
            { name: 'true', label: 'true' },
            { name: 'false', label: 'false' },
        ],
        operators: [{ name: '=', label: '=' }],
    },
];

const appointmentFields = [
    {
        name: 'event_field_AppointmentDateTime',
        label: 'This appointment date', 
        inputType: 'date',
        operators: dateOperators,
    },
    {
        name: 'event_field_AppointmentDurationMinutes',
        label: 'This appointment duration (minutes)',
        inputType: 'number',
        operators: numberOperators,
    },
    // not supported for now
    // {
    //     name: 'event_field_AppointmentAction',
    //     label: 'Appointment action originated from',
    //     valueEditorType: 'select',
    //     values: [
    //         { name: 'app', label: 'App' },
    //         { name: 'web', label: 'Web' },
    //         { name: 'practice', label: 'Practice' },
    //     ],
    //     operators: [{ label: '=', name: '=' }],
    // },
];

const painFields = [
    {
        name: 'event_field_PainLevel',
        label: 'Pain Level: 0 (none) to 10 (severe)',
        valueEditorType: 'select',
        values: [
            { name: '0', label: '0' },
            { name: '1', label: '1' },
            { name: '2', label: '2' },
            { name: '3', label: '3' },
            { name: '4', label: '4' },
            { name: '5', label: '5' },
            { name: '6', label: '6' },
            { name: '7', label: '7' },
            { name: '8', label: '8' },
            { name: '9', label: '9' },
            { name: '10', label: '10' },
        ],
        operators: numberOperators,
    },
];

const rtmFields = [
    {
        name: 'event_field_RTMCode',
        label: 'RTM Code',
        valueEditorType: 'select',
        values: [
            { name: '98975', label: '98975' },
            { name: '98977', label: '98977' },
            { name: '98980', label: '98980' },
            { name: '98981', label: '98981' },
        ],
        operators: [{ label: '=', name: '=' }],
    },
];

const birthdayFields = [
    {
        name: 'event_field_PatientAge',
        label: 'Patient Age',
        inputType: 'number',
        operators: numberOperators,
    },
];

const messageFields = [
    {
        name: 'event_field_MessageType',
        label: 'Message Type',
        valueEditorType: 'select',
        values: [
            { name: 'text', label: 'Text' },
            { name: 'secure', label: 'Secure' },
        ],
        operators: [{ label: '=', name: '=' }],
    },
    {
        name: 'event_field_MessageStatus',
        label: 'Message Status',
        valueEditorType: 'select',
        values: [
            { name: 'unread', label: 'Unread' },
            { name: 'read', label: 'Read' },
        ],
        operators: [{ name: '=', label: '=' }],
    }
];

const optInFields = [
    {
        name: 'event_field_OptInStatus',
        label: 'Opt In Status',
        valueEditorType: 'select',
        values: [
            { name: 'opted_in', label: 'Opted in' },
        ],
        operators: [
            { name: 'true', label: 'has' },
            { name: 'false', label: 'has not' },
        ],
    },
    {
        name: 'event_field_OptInType',
        label: 'Opt In Type',
        valueEditorType: 'select',
        values: [
            { name: 'twoWayMessaging', label: 'Two Way Messaging' },
            { name: 'apptReminder', label: 'Appointment Reminder' },
            { name: 'emailReminders', label: 'Email Reminders' },
            { name: 'appNotifications', label: 'App Notifications' },
        ],
        operators: [{ label: '=', name: '=' }],
    },
];

const ratingFields = [
  {
    name: 'event_field_RatingResponse',
    label: 'Rating',
    valueEditorType: 'select',
    values: [
      { name: '1', label: '1' },
      { name: '2', label: '2' },
      { name: '3', label: '3' },
      { name: '4', label: '4' },
      { name: '5', label: '5' },
    ],
    operators: numberOperators,
  },
];

const confirmationFields = [
  {
    name: 'event_field_ConfirmationResponse',
    label: 'Confirmation Response',
    valueEditorType: 'select',
    values: [
      { name: 'Confirmed', label: 'Confirmed' },
      { name: 'Reschedule', label: 'Reschedule' },
    ],
    operators: [{ label: '=', name: '=' }],
  },
];

// Can we add a limit to these? (-100, 100)
const hepSurveyFields = [
  {
    name: 'event_field_HepSurveyProgress',
    label: 'HEP Survey - Progress: -100 (worse) to 100 (better)',
    valueEditorType: 'number',
    operators: numberOperators,
  }
]

const apptSurveyFields = [
  {
    name: "event_field_ApptSurveyBeginningImprovement",
    label: "Appointment Survey - Since Beginning Improvement: -100 (worse) to 100 (better)",
    valueEditorType: "number",
    operators: numberOperators,
  },
  {
    name: "event_field_ApptSurveyClinicImprovement",
    label: "Appointment Survey - Since Clinic Improvement: -100 (worse) to 100 (better)",
    valueEditorType: "number",
    operators: numberOperators,
  },
  {
    name: "event_field_ApptSurveyConditionSymptoms",
    label: "Appointment Survey - Condition Symptoms: 0 (none) to 10 (severe)",
    valueEditorType: 'select',
        values: [
            { name: '0', label: '0' },
            { name: '1', label: '1' },
            { name: '2', label: '2' },
            { name: '3', label: '3' },
            { name: '4', label: '4' },
            { name: '5', label: '5' },
            { name: '6', label: '6' },
            { name: '7', label: '7' },
            { name: '8', label: '8' },
            { name: '9', label: '9' },
            { name: '10', label: '10' },
        ],
    operators: numberOperators,
  }
]

const combinators = [
    {
        name: 'and', value: 'and',
        label: 'AND - audience needs to meet ALL conditions below',
    },
    {
        name: 'or', value: 'or',
        label: 'OR - audience needs to meet ANY of the conditions below',
    },
];

const calcOptionsHeight = (options: any[]) => {
    const size = Math.min(options.length, 10);
    const sizeClass = size <= 4 ? 'q-m-small' : 'q-m-' + size;
    return sizeClass;
}

const sortArray = (array: any[]) => {
    return array.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    }); 
}

export default function QueryBuilderComponent({ triggerId=0, eventType='' }) {
    const formContext = useFormContext();
    const [record, setRecord] = useState(Object); // trigger record
    const [appointmentTypes, setAppointmentTypes] = useState(Array);
    const [ratingTemplates, setRatingTemplates] = useState(Array);
    const [confirmationTemplates, setConfirmationTemplates] = useState(Array);
    const [providers, setProviders] = useState(Array);
    const [exercies, setExercies] = useState(Array);
    const [hasUpdated, setHasUpdated] = useState(false);

    const [showAppointmentFields, setShowAppointmentFields] = useState(false);
    const [showPainFields, setShowPainFields] = useState(false);
    const [showRatingFields, setShowRatingFields] = useState(false);
    const [showConfirmationFields, setShowConfirmationFields] = useState(false);
    const [finalFields, setFinalFields] = useState<any[]>([]);
    const [showMultiselectWarning, setShowMultiselectWarning] = useState(false);
    const [queryIsObj, setQueryIsObj] = useState(true);

    const [defaultQuery] = useState({
        combinator: 'and',
        rules: [{
            field: 'default',
            id: '99999999-9999-9999-9999-999999999999',
        }],
    });

    const UpdateQuery = (query: any) => {
        formContext.setValue('selection_criteria', query);
        if (!hasUpdated) {
            setHasUpdated(true);
        } else { // if not initial load, remove "backup" of failed advanced query
            formContext.setValue('selection_criteria_with_error', undefined);
        }
    };

    const formQuery = useWatch({ name: 'selection_criteria' });
    useEffect(() => {
        setQueryIsObj(typeof formQuery === 'object');
    }, [formQuery]);

    /* Update QueryBuilder fields based on type of event/trigger selected */
    useEffect(() => {
        setShowPainFields(record && record.category === 'Report Pain' && eventType && eventType === 'event');

        setShowAppointmentFields(
            (eventType && eventType === 'appointment') ||
            (record && (record.category === 'Appointments' || record.category === 'Appointment Confirmation') && eventType && eventType === 'event')
        );

        setShowRatingFields(record && record.category === 'Rating' && eventType && eventType === 'event');

        setShowConfirmationFields(record && record.category === 'Appointment Confirmation' && eventType && eventType === 'event');
    }, [record, eventType]);

    useEffect(() => {
        let fields: any[] = defaultField;
        let lastNextAppt: any[] = []; // allow custom position in field order for last/next appointment fields


        // AppointmentType + NextAppointmentType + LastAppointmentType
        if (appointmentTypes && appointmentTypes.length > 0) {
            let apptArray = appointmentTypes.map((item:any) => {
                let itemLabel = item?.emr_appointment_type?.appt_type;
                if (item.name !== "Null Appointment Type" && itemLabel) {
                    return { name: item.id, label: itemLabel }
                } else return undefined;
            }).filter((element) => {
                return element !== undefined;
            });

            apptArray = sortArray(apptArray);
            const optionsHeight = calcOptionsHeight(apptArray);

            lastNextAppt = [
                {
                    name: 'last_appointment_type',
                    label: 'Last appointment type',
                    valueEditorType: 'multiselect',
                    values: apptArray,
                    operators: multiselectOperators,
                    className: 'query-multiselect ' + optionsHeight,
                },
                {
                    name: 'next_appointment_type',
                    label: 'Next appointment type',
                    valueEditorType: 'multiselect',
                    values: apptArray,
                    operators: multiselectOperators,
                    className: 'query-multiselect ' + optionsHeight,
                }
            ];

            if (showAppointmentFields) {
                fields = [...fields, {
                    name: 'event_field_AppointmentType',
                    label: 'This appointment type',
                    valueEditorType: 'multiselect',
                    values: apptArray,
                    operators: multiselectOperators,
                    className: 'query-multiselect ' + optionsHeight,
                }];
            }
        }

        // RatingTemplate
        if (ratingTemplates && ratingTemplates.length > 0 && showRatingFields) {
            let ratingTemplatesArray = ratingTemplates.map((item:any) => {
                if (item.capture_response && item.response_type === 'Rating') {
                    return { name: item.id, label: item.name }
                } else return undefined;
            }).filter((element) => {
                return element !== undefined;
            });

            ratingTemplatesArray = sortArray(ratingTemplatesArray);

            fields = [...fields, {
                name: 'event_field_RatingTemplate',
                label: 'Response Template',
                valueEditorType: 'multiselect',
                values: ratingTemplatesArray,
                operators: multiselectOperators,
                className: 'query-multiselect ' + calcOptionsHeight(ratingTemplatesArray),
            }];
        }

        // ConfirmationTemplate
        if (confirmationTemplates && confirmationTemplates.length > 0 && showConfirmationFields) {
            let confirmationTemplatesArray = confirmationTemplates.map((item:any) => {
                if (item.capture_response && item.response_type === 'Confirmation') {
                    return { name: item.id, label: item.name }
                } else return undefined;
            }).filter((element) => {
                return element !== undefined;
            });

            confirmationTemplatesArray = sortArray(confirmationTemplatesArray);

            fields = [...fields, {
                name: 'event_field_ConfirmationTemplate',
                label: 'Response Template',
                valueEditorType: 'multiselect',
                values: confirmationTemplatesArray,
                operators: multiselectOperators,
                className: 'query-multiselect ' + calcOptionsHeight(confirmationTemplatesArray),
            }];
        }

        // AppointmentProviderName
        if (providers && providers.length > 0 && showAppointmentFields) {
            let providersArray = providers.map((item:any) => {
                return { name: item.id, label: item.name }
            });

            providersArray = sortArray(providersArray);

            fields = [...fields, {
                name: 'event_field_AppointmentProviderName',
                label: 'This appointment provider name',
                valueEditorType: 'multiselect',
                values: providersArray,
                operators: multiselectOperators,
                className: 'query-multiselect ' + calcOptionsHeight(providersArray),
            }];
        }

        // ExerciseName
        if (exercies && exercies.length > 0 && showPainFields) {
            let exerciesArray = exercies.map((item:any) => {
                return { name: item.name, label: item.name }
            })

            exerciesArray = sortArray(exerciesArray);

            if (exerciesArray && exerciesArray.length > 0) fields = [...fields, {
                name: 'event_field_ExerciseName',
                label: 'Exercise Name',
                valueEditorType: 'multiselect',
                values: exerciesArray,
                operators: multiselectOperators,
                className: 'query-multiselect ' + calcOptionsHeight(exerciesArray),
            }];
        }

        if (eventType && eventType === 'event' && record && record.category) {
            let category = record.category;
            if (category === 'Appointments') fields = [...fields, ...appointmentFields];
            else if (category === 'Report Pain') fields = [...fields, ...painFields];
            else if (category === 'Birthday') fields = [...fields, ...birthdayFields];
            else if (category === 'Message Received') fields = [...fields, ...messageFields];
            else if (category === 'Opt-In') fields = [...fields, ...optInFields];
            else if (category === 'RTM Code') fields = [...fields, ...rtmFields];
            else if (category === 'Rating') fields = [...fields, ...ratingFields];
            else if (category === 'Appointment Confirmation') fields = [...fields, ...confirmationFields, ...appointmentFields];
            else if (category === 'HEP Survey') fields = [...fields, ...hepSurveyFields];
            else if (category === 'Appointment Survey') fields = [...fields, ...apptSurveyFields];
        } else if (eventType && eventType === 'appointment') {
            fields = [...fields, ...appointmentFields];
        }

        // order fields: default 'Select...' field + dynamic fields + last/next appointment (if exists) + base fields
        fields = lastNextAppt.length ? fields.concat(lastNextAppt).concat(baseFields) : fields.concat(baseFields);
        setFinalFields(fields);

        // values from trigger/getList components, event type, and 'show' fields
    }, [appointmentTypes, providers, exercies, ratingTemplates, confirmationTemplates, record, eventType, showAppointmentFields, showPainFields, showRatingFields, showConfirmationFields]);
    
    useEffect(() => {
        let showWarning = false;
        if (formQuery && formQuery.rules && formQuery.rules.length > 0) {
                formQuery.rules.forEach((field: any) => {
                if (field.operator === 'in') {
                    showWarning = true;
                }
            });
        }
        setShowMultiselectWarning(showWarning);
    },[formQuery]);

    return (<>
        {(triggerId && triggerId !== 0) ?
            <QueryBuilderTrigger setRecord={setRecord} triggerId={triggerId} />
        : ''}
        {(showAppointmentFields) &&
            <QueryBuilderGetList setRecord={setProviders} recordType='providers' />
        }
        {showPainFields &&
            <QueryBuilderGetList setRecord={setExercies} recordType='exerciselibrarys' />
        }
        <QueryBuilderGetList setRecord={setAppointmentTypes} recordType='appointmenttypes' />
        <QueryBuilderGetList setRecord={setRatingTemplates} recordType='messagetemplates' />
        <QueryBuilderGetList setRecord={setConfirmationTemplates} recordType='messagetemplates' />

        {formQuery && !queryIsObj &&
            <div className='querybuilder-warning'>
                <p style={{margin: '8px 0'}}><span style={{color: '#d32f2f'}}>There was an error with your JSON! </span>
                Your custom query is not valid JSON and could not be converted to the default criteria selector. If you wish to use the default selector you need to start over.</p>
            </div>
        }

        <QueryBuilder
            fields={finalFields}
            query={(formQuery && queryIsObj && Object.keys(formQuery).length) ? formQuery : defaultQuery}
            onQueryChange={UpdateQuery}
            combinators={combinators}
            controlElements={{ addGroupAction: () => null }}
        />

        {showMultiselectWarning &&
            <p style={{ marginBottom: 0, fontSize: '14px' }}>
                * For Windows, hold down the control (ctrl) button to select multiple options.
                For Mac, hold down the command button to select multiple options.
            </p>
        }
    </>);
};
