import { Box, Grid, Typography } from '@mui/material';
import { 
    Edit,
    SimpleFormIterator,
    SimpleForm,
    TextInput,
    ArrayInput,
    SelectInput,
    ReferenceInput,
 } from 'react-admin';

import '../../scss/pages/Layouts.scss';

const MasterprogramsEdit = (props) => {

    return (
        <Edit 
        {...props}>
            <SimpleForm 
            {...props}
            // validate={validateForm}
            >
                <div>
                    <Grid container width={{ xs: '80%', xl: 1200 }} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom mb={3}>
                                <Box fontWeight='fontWeightBold'>
                                    Edit Protocol
                                </Box>
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={6} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="name" fullWidth />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                    <ArrayInput source="relieve_exercises">
                                        <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                                            <ReferenceInput
                                                source="easy_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="default_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                isRequired
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth isRequired />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="hard_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                    <ArrayInput source="restore_exercises">
                                        <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                                            <ReferenceInput
                                                source="easy_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="default_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                isRequired
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="hard_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }} width='100%'>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} width='100%'>
                                    <ArrayInput source="reinforce_exercises">
                                        <SimpleFormIterator className='RaSimpleFormIterator-form-flex-row'>
                                            <ReferenceInput
                                                source="easy_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth/>
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="default_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                                isRequired
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                            <ReferenceInput
                                                source="hard_exercise_id"
                                                reference="exerciselibrarys"
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <SelectInput optionValue="id" optionText="name" source="name" fullWidth />
                                            </ReferenceInput>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

export default MasterprogramsEdit;
