import ConditionsList from './ConditionsList';
import ConditionsEdit from './ConditionsEdit';
import ConditionsCreate from './ConditionsCreate';

const resource = {
    list: ConditionsList,
    edit: ConditionsEdit,
    create: ConditionsCreate,
};

export default resource;