import { fetchUtils } from 'react-admin';
import tokenManager from './tokenManager';

const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
const httpClient = fetchUtils.fetchJson;

export async function logActivity(name, activity, comment, patient_id, duration, date=0) {
    const accessToken = tokenManager.getToken('AuthToken');

    var args = {
        name: name,
        user_id: patient_id,
        activity: activity,
        client_initiated: false,
        comment: comment,
        duration: duration
    }
    if (date) {
        args.date = Number(date)
    }

    await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/user/${patient_id}/activity`, {
        method: 'POST',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(args),
    }).catch((e)=> {
        console.log(`Error in logActivity:logActivity()`)
        console.error(e)
        throw e
    })

}