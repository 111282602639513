import { Show, SimpleShowLayout, ReferenceField, TextField, DateField, ChipField, RichTextField, useRecordContext, useRedirect, Button, FunctionField } from "react-admin";
import SegmentsField from "./SegmentsField";
import DOMPurify from 'dompurify';

const ConditionalErrorField = () => {

    const record = useRecordContext()
  //    console.log("record", record.data.error_details);
  if (record.data?.error_details) {
    if (!record.data.error_details.smsSent) {
      // console.log("sms error", record.data.error_details.smsSent);

            return <p>Error: {record.data.error_details.toLocaleString()}</p>
    }
    if (record.data.error_details) {
      // console.log("email error", record.data.error_details.emailSent);
      // console.log("ERROR DETAILS", record.data.error_details); // email error details don't get created in local
      return <p>Error: {record.data.error_details.toLocaleString()}</p>
    }

  } else if (record.result === "failure") {
    // to catch anything thats not snagged with error_details
    return <p>Error: Something went wrong</p>
  }

}

const ResponseField = () => {
  const record2 = useRecordContext();
  return record2.response ? (
    <FunctionField
      render={(record: any) => {
        return record && record.response.response !== "" ? (
          <>
            <p
              className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-1pnmrwp-MuiTypography-root"
              style={{ fontSize: ".85em" }}
            >
              <span>Response</span>
            </p>
            <RichTextField label="Response" source="response.response" />
          </>
        ) : (
          <>
            <p
              className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-1pnmrwp-MuiTypography-root"
              style={{ fontSize: ".85em" }}
            >
              <span>Response</span>
            </p>
            <span className="MuiTypography-root MuiTypography-body2 css-e784if-MuiTypography-root">
              Waiting for a response...
            </span>
          </>
        );
      }}
    />
  ) : null;
};

export const MessageLogShow = () => {
  const redirect = useRedirect();

  // ConditionalErrorField.defaultProps = {
  //     label: "someId"
  // }

  const onCancel = (data: any) => {
        redirect('/messagelogs');
    }
    return <div>

      <Show>
        <SimpleShowLayout>
                <ReferenceField source="automation.id" reference="messageautomations" label="Automation Name" link={false} >
                    <TextField source='name' />
          </ReferenceField>
                <ReferenceField source="user.id" reference="users" label="Patient Name" />
                <FunctionField label="Message Created" sortBy="createdAt" render={(record: any) => record && record.updatedAt !== 0 ? new Date(record.updatedAt).toLocaleString() : "N/A"} />
          {/* should always display a time attempted either the sent_date or updatedAt (for even if failure) */}
                <FunctionField label="Date Attempted" render={(record: any) => record && (record.sent_date !== 0) ? new Date(record.sent_date * 1000).toLocaleString() : new Date(record.updatedAt).toLocaleString()} />
                <ChipField sx={{ textTransform: 'capitalize' }} source="channel" label="Channel" sortable={false} />
                <ChipField sx={{ textTransform: 'capitalize' }} source="result" label="Result" sortable={false} />
          <SegmentsField source="type" label="Type" sortable={false} />
                <ReferenceField source="automation.template" reference="messagetemplates" label="Template Name" link={false} >
                    <TextField source='name' />
                </ReferenceField>
                <FunctionField label="Message" render={(record: any) => {
                  const parser = new DOMParser()
                  const message = parser.parseFromString(record.data.message, 'text/html');
                  const elementsWithOnClick = message.querySelectorAll('[onclick]');
                  elementsWithOnClick.forEach(element => { element.removeAttribute('onclick'); });
                  const anchorTags = message.querySelectorAll('a');
                  anchorTags.forEach(anchor => {
                    anchor.removeAttribute('href');
                    anchor.style.cursor = 'none';
                    anchor.style.pointerEvents = 'none';
                  });

                  const sanitizedHtml = DOMPurify.sanitize(message.body.innerHTML);
                  return (<RichTextField source="data.message" label="Message" record={{ data: { message: sanitizedHtml }}} />)
                }} />
                <ResponseField />
                {/* if error_details object show conditional error field else null */}
                <hr />
                <FunctionField render={(record: any) => record && (record.result === "failure") ? <ConditionalErrorField /> : null} />
        </SimpleShowLayout>
      </Show>
      <Button
            label='Cancel'
            size='medium'
            variant='contained'
            sx={{ marginTop: '15px', marginLeft: '20px' }}
        onClick={onCancel}
      />
    </div>
};