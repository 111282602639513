import moment from 'moment-timezone';

const TransformBroadcastData = (data: any, sendNow: boolean) => {

    // get practice timezone instead of using data.time_zone
    if (!sendNow && data.date && data.time) {
        let whenHours = data.time.getHours() || "00";
        let whenMinutes = data.time.getMinutes() || "00";

        // add leading zero when needed
        whenHours = ("0" + whenHours).slice(-2);
        whenMinutes = ("0" + whenMinutes).slice(-2);

        const practiceTZ = localStorage.getItem('selectedPracticeTZ') || 'America/New_York'
        const dateTime = `${data.date} ${whenHours}:${whenMinutes}:00`;
        data.when = moment.tz(dateTime, practiceTZ).unix();
    }

    data = {
        ...data,
        practice_id: localStorage.getItem('selectedPractice') || '',
        scheduled: !sendNow, // requires "when"
    };

    if (data.use_template) {
        data.template_id = data.template;
        delete data.custom_message;
    }

    if (data.group_type === 'user_ids') {
        // match query builder format
        data.selection_criteria = JSON.stringify({
            "combinator": "and",
            "rules": [
                {
                    "field": "_id",
                    "operator": "in",
                    "value": data.user_ids
                },
            ],
        })
    } else if (data.group_type === 'custom_query') {
        if (data.advanced_query_enabled) { // fix formatting
            data.selection_criteria = JSON.stringify(JSON.parse(data.selection_criteria));
        } else {
            data.selection_criteria = JSON.stringify(data.selection_criteria);
        }
    }

    // delete fields that are not in the model
    delete data.user_ids;
    delete data.template; // "converted" to template_id when needed
    delete data.group_type;
    delete data.use_template;
    delete data.selection_criteria_with_error; // temporary "backup" of advanced query
    // schedule fields, used to build data.when
    delete data.date;
    delete data.time;
    // test message fields
    delete data.test_sms;
    delete data.test_email;

    return data;
};

export default TransformBroadcastData;