const mixpanel = require('mixpanel-browser');
const projectToken = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(projectToken);

let actions = {
    identify: (id) => {
      mixpanel.identify(id);
    },
    reset: () => {
      mixpanel.reset();
    },
    alias: (id) => {
      mixpanel.alias(id);
    },
    track: (name, props) => {
      mixpanel.track(name, props);
    },
    people: {
      set: (props) => {
        mixpanel.people.set(props);
      },
    },
  };
  
  export let Mixpanel = actions;