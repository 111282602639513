import { Grid } from '@mui/material';
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    FormDataConsumer,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    Confirm,
    useRefresh,
    useRecordContext,
    TextInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { useWatch, useFormContext } from 'react-hook-form';
import '../../scss/pages/EnhancedMessaging.scss';
import QueryBuilder from '../../components/enhancedmessaging/QueryBuilder';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import ScheduleOrSendToolbar from '../../components/enhancedmessaging/ScheduleOrSendToolbar';
import TransformBroadcastData from '../../components/enhancedmessaging/TransformBroadcastData';
import { useState } from 'react';

const transform = (data: any, sendNow: boolean) => {
    // default submit transform - not in use due to ScheduleOrSendToolbar override
    const transformResult = TransformBroadcastData(data, sendNow);
    return transformResult
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const RenderMessageTemplate = () => {
    const formValues = useWatch();
    const channels = formValues.channels;
    // RenderMessageTemplate is only called when formValues.template exists
    const record = useGetOne(`messagetemplates`, { id: formValues.template });
    
    const formatMessage = (message: string) => {
        // repalce newlines with <p> tags
        let sms = message ? message.replaceAll('\n', '</p><p>') : '';
        if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
        // no more than one consecutive empty paragraph
        sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');
        return sms;
    }
    
    if (channels && record.data) {
        const sms_html = channels.includes('sms') && record.data.sms_message && (
            <>
                <p>SMS template</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.data.sms_message) }}
                />
            </>
        );

        const email_html = channels.includes("email") &&
          record.data.email_message && record.data.name ? (
            <>
              <p >Email Subject</p>
              <div
               id="email-subject"                
               className="broadcast-textarea"
               style={{minHeight: "35px"}}
                dangerouslySetInnerHTML={{
                  __html: formatMessage(record.data.email_subject || record.data.name ),// prefers email_subject over name of template
                }}
              />

              <p>Email template</p>
              <div
                className="broadcast-textarea"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(record.data.email_message),
                }}
              />
            </>
          ): null;
        return (
          <div className="broadcast-textarea-wrapper">
            {sms_html}
            {email_html}
          </div>
        );
    }
    return <div></div>;
};

const ConfirmElectronicMail = () => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const formValues = useWatch();
    const channels = formValues.channels;

    if (channels && channels.includes('email') && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    return (
        <Confirm
            isOpen={open}
            title='Electronic Mail'
            className='broadcast-confirm-modal'
            content={
                <p>
                    Because you have enabled email for this message, we have added formatting options, but keep in mind formatting is not available for Text Messages and App Notifications.
                </p>
            }
            onClose={() => setOpen(false)}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};

const validateAdvancedQuery = (advancedQuery: any) => {
    if (!advancedQuery) return 'Required';
    try { // if valid JSON string
        if (advancedQuery) {
            const query = JSON.parse(advancedQuery);
            return validateQuery(query); // validate if it has a rule besides the default one
        } else {
            return 'Invalid JSON';
        }
    } catch (e) {
        return 'Invalid JSON';
    }
}

const validateQuery = (query: any) => {
    if (query && query.rules && query.rules.length > 0) {
        // invalid if the only rule is the default rule
        const rules = query.rules.filter((rule: any) => rule.field !== 'default');
        if (rules.length > 0) {
            const ruleEmptyValue = rules.filter((rule: any) => !rule.value);
            if (ruleEmptyValue.length > 0) return 'Rule value is required';
            else return undefined;
        } else {
            return 'Query needs at least one rule';
        }
    } else {
        return 'Query needs at least one rule';
    }
}

// ConfirmAdvancedQuery can't be conditionally rendered because it would reset 'hasOpened'
const ConfirmAdvancedQuery = () => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const formValues = useWatch();
    const formContext = useFormContext();
    const advancedMode = formValues.advanced_query_enabled;

    if (advancedMode && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    let query = formValues.selection_criteria;

    if (advancedMode) {
        if (query && typeof query === 'object') {
            if (formValues.selection_criteria_with_error) {
                formContext.setValue('selection_criteria', formValues.selection_criteria_with_error)
                formContext.setValue('selection_criteria_with_error', undefined);
            } else {
                query = JSON.stringify(query, null, 4);
                formContext.setValue('selection_criteria', query)
            }
        }
    } else {
        if (query && typeof query === 'string') {
            try {
                query = JSON.parse(query);
                formContext.setValue('selection_criteria', query)
            } catch (e) {
                console.log('Invalid JSON');
                if (!formValues.selection_criteria_with_error) {
                    // set a "backup" of the invalid JSON so the user can edit it
                    formContext.setValue('selection_criteria_with_error', query);
                }
            }
        }
    }

    return (
        <Confirm
            isOpen={open}
            title='Warning! Advanced Feature!'
            className='broadcast-confirm-modal confirm-query'
            content={<>
                <p>
                    Selections entered here may not work as expected. Use only if you are familiar with the JSON format and the query structure.
                </p>
                <p>
                    If you undo this selection and edit the basic criteria, the advanced criteria will be lost.
                </p>
            </>}
            onClose={() => {
                formContext.setValue('advanced_query_enabled', false);
                setOpen(false)
            }}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};

const MessageBroadcastCreate = () => {
    localStorage.setItem("scheduled", "false");
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = (data:any) => {
        console.log('onSuccess', data)
        if (localStorage.getItem('scheduled') === 'false') {
            notify('Message sent', { type: 'info' });
            redirect('/messagelogs');
        } else {
            notify('Message scheduled', { type: 'info' });
            redirect('/messageautomations'); 
        }
        setTimeout(refresh, 1000);
    }

    const CustomOption = () => {
        let item = useRecordContext()
        
        return <span>{item.first} {item.last} ({item.emr_patient_id})</span>
    }

    return (
        <Create
            className='broadcast-create'
            sx={{ maxWidth: '1020px' }}
            transform={(data: any) => transform(data, false)}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='broadcast'
                toolbar={<ScheduleOrSendToolbar />}
            >
                <h1>Broadcast Messaging</h1>
                <Grid {...gridProps}>
                    <p className='broadcast-label'>1. Select which patients:</p>
                    <Grid item xs={12}>
                        <RadioButtonGroupInput
                            className='broadcast-radio-group'
                            source='group_type'
                            defaultValue='user_ids'
                            choices={[
                                { id: 'user_ids', name: 'Send to specific patients only' },
                                {
                                    id: 'custom_query',
                                    name: 'Send to all patients that match a criteria',
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ConfirmAdvancedQuery />
                        <FormDataConsumer>
                            {({ formData }) => {
                                if (formData.group_type === 'user_ids') {
                                    return (
                                        <ReferenceArrayInput source='user_ids' reference='users'>
                                            <AutocompleteArrayInput className='hello'
                                                // this is what goes into the input array after selection
                                                inputText={(choice)=> `${choice.first} ${choice.last}`}
                                                // needs to be a custom component for the drop down items
                                                optionText={<CustomOption />}
                                                filterToQuery={(value) => ({ name: value })}
                                                label='Select users...'
                                                validate={[required()]}
                                            />
                                        </ReferenceArrayInput>
                                    );
                                } else {
                                    return (
                                        <div className='queryBuilder-wrapper'>
                                            <BooleanInput
                                                source='advanced_query_enabled'
                                                label='Advanced input'
                                                sx={{'& label': { marginLeft: 'auto', marginTop: '-54px', marginBottom: '8px' }, '& p': { display: 'none'}}}
                                            />
                                            {formData.advanced_query_enabled ? (
                                                <TextInput
                                                source='selection_criteria'
                                                fullWidth
                                                multiline
                                                className='textinput-multiline'
                                                validate={()=>validateAdvancedQuery(formData.selection_criteria)}
                                            />
                                            ) : (<>
                                                <QueryBuilder/>
                                                <TextInput
                                                    source='selection_criteria'
                                                    validate={() => validateQuery(formData.selection_criteria)}
                                                    // hide everything but the error message
                                                    sx={{ marginTop: '0', '& label': { display: 'none' }, '& div': { display: 'none' } }}
                                                    // set to QueryBuilder default value in the string format we use for selection_criteria
                                                    defaultValue={
                                                        JSON.stringify({
                                                            combinator: 'and',
                                                            rules: [{
                                                                field: 'default',
                                                                id: '99999999-9999-9999-9999-999999999999',
                                                            }],
                                                        }, null, 4)
                                                    }
                                                />
                                            </>)}
                                        </div>
                                    );
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='broadcast-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                        <CheckboxGroupInput
                            source='channels'
                            choices={[
                                { id: 'sms', name: 'Text Message' },
                                { id: 'email', name: 'Electronic Mail' },
                            ]}
                            defaultValue={['sms']}
                            validate={[required()]}
                        />
                        <ConfirmElectronicMail />
                    </Grid>
                </Grid>

                <Grid {...gridProps} className='broadcast-richtext'>
                    <p className='broadcast-label'>3. Select your message:</p>
                    <Grid item xs={12}>
                        <BooleanInput label='Use existing template' source='use_template' />
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) =>
                            formData.use_template ? (
                                <>
                                    <Grid item xs={12}>
                                        <ReferenceInput
                                            source='template'
                                            reference='messagetemplates'
                                            filter={{ is_broadcast: false, is_provider_notification: false, event_type: 'General' }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <AutocompleteInput
                                                optionText='name'
                                                label='Select a template...'
                                                filterToQuery={(value) => ({ name: value })}
                                                validate={[required()]}
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                    {formData.template && (
                                        <Grid item xs={12} className='broadcast-multiline'>
                                            <RenderMessageTemplate />
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <>
                                    {formData.channels && formData.channels.length > 0 && (
                                        <>
                                       {formData.channels.includes('email') ?  <Grid item xs={12}>
                                         <p >Email Subject</p>
                                                <RichTextInput
                                                    id="email-subject"
                                                    source="email_subject"
                                                    fullWidth
                                                    helperText = "Enter an email subject - dynamic text supported"
                                                    // defaultValue={formData? formData.name: ""} // not showing up when use existing template is picked after Electronic Mail box
                                                    validate={[required()]}
                                                    toolbar={<CustomRichTextToolbar richTextMode={false} />}
                                                />
                                        </Grid> : null}
                                        <Grid item xs={12}>
                                            <RichTextInput
                                                source='custom_message'
                                                fullWidth
                                                validate={[required()]}
                                                toolbar={
                                                    <CustomRichTextToolbar
                                                        richTextMode={formData.channels.includes('email')}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </FormDataConsumer>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default MessageBroadcastCreate;
