import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import { TextInput, Link } from 'react-admin';
import {
    Form,
    required,
    useLogin,
    useNotify,
    useSafeSetState,
} from 'ra-core';

export const LoginForm = (props: LoginFormProps) => {
    const { className } = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const notify = useNotify();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');    


    useEffect(() => {
        
        //look for date url param and store in local storage if there is one
        const queryParameters = new URLSearchParams(window.location.search)
        const date = queryParameters.get('date')
        if (date) {
            localStorage.setItem('testDate', date)
        }

        // clean the URL and store token in local storage
        const notifyMessage = localStorage.getItem('notify')
        if (notifyMessage) {
            notify(notifyMessage)
            localStorage.removeItem('notify')
        }
    }, [])

    const submit = e => {
        setLoading(true);
        login({ username, password })
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        },
                    }
                );
            });
    };

    return (
        <StyledForm
            onSubmit={submit}
            mode="onChange"
            noValidate
            className={className}
        >
            <CardContent className={LoginFormClasses.content}>
                <TextInput
                    autoFocus
                    source="username"
                    label="Email"
                    autoComplete="username"
                    validate={required()}
                    onChange={e => setUsername(e.target.value)}
                    fullWidth
                />
                <TextInput
                    source="password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    validate={required()}
                    fullWidth
                    onChange={e => setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginFormClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        "Sign In"
                    )}
                </Button>
                <Link to="/RecoverPassword">Reset your password &gt;</Link>

            </CardContent>
        </StyledForm>
    );
};

const PREFIX = 'Kaizo';

export const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

export interface LoginFormProps {
    redirectTo?: string;
    className?: string;
}

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};