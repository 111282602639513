import { Button } from "@mui/material"
import { useRedirect } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Form, useSafeSetState, useNotify } from 'ra-core';
import { termsOfUse } from '../TermsofUse';
import { logAcceptTerms } from "../logAcceptTerms"

const AcceptTerms = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const redirect = useRedirect()
  const notify = useNotify();

  const PasswordValidate = values => {
      const errors = {}
      if (values.password !== values.password2) {
          errors['password2'] = 'Password mismatched' ;
      }
      return errors;
  };

  const formSubmitHandler = async e => {
    console.log(e)

    setLoading(true)

    let version = 1;

    if (await logAcceptTerms(version)) {
        setLoading(false)
        redirect(`/`)
    } else {
        setLoading(false)
        notify('Sorry, we were unable to save your information. Please try again later.', {type: 'error'})
    }
  }

  return (
    <Root>
        <Card className={LoginClasses.card}>
            <CardContent className={LoginClasses.content}>

              <StyledForm
                  onSubmit={formSubmitHandler}
                  mode="onChange"
                  noValidate
                  className="recoverPassword"
                  validate={PasswordValidate}
              >
                <h1 className={LoginClasses.header}>
                    Terms of Use
                </h1>
                <div className={LoginClasses.scrolling} dangerouslySetInnerHTML={{__html: termsOfUse}}/>                               
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        "Accept Terms"
                    )}
                </Button>
            </StyledForm>
          </CardContent>
        </Card>
    </Root>
  )
}

export default AcceptTerms

const PREFIX = 'Kaizo';
export const LoginClasses = {
    card: `${PREFIX}-card`,
    avatar: `${PREFIX}-avatar`,
    icon: `${PREFIX}-icon`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    scrolling: `${PREFIX}-scrolling`,
    header: `${PREFIX}-header`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginClasses.content}`]: {
        width: 800,
    },
    [`& .${LoginClasses.button}`]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    [`& .${LoginClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));


const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        minHeight: 500,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${LoginClasses.content}`]: {
        width: 800,
    },    
    [`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },
    [`& .${LoginClasses.scrolling}`]: {
        overflowY: 'scroll',
        maxHeight: '50vh',
        minHeight: 311,
    },
    [`& .${LoginClasses.header}`]: {
        textAlign: 'center',
    },
}));