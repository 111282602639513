import { Box } from '@mui/material';
import {
    CreateButton,
    Datagrid,
    FunctionField,
    List,
    TextField,
    TopToolbar,
} from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ListAside from './ListAside';
import '../../scss/pages/EnhancedMessaging.scss';

const FormatType = (type: string) => {
    switch (type) {
        case "event":
            return "Triggered";
        case "time":
            return "Timed";
        case "firstTrue":
            return "Conditions Match";
        case "appointment":
            return "Reminder/Missed";
        default:
            return "";
    }
}

const MessageTemplateList = () => {
    return (
        <Box mt={2} className="template-container">
            <List
                sort={{ field: 'updatedAt', order: 'DESC' }}
                aside={<ListAside />}
                actions={<ListActions />}
                filter={{ is_provider_notification: false }}
            >
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                    bulkActionButtons={false} 
                >
                    <TextField source="name" label="Automation Name" />
                    <FunctionField label="Enabled" sortBy="enabled" render={(record: any) => record && record.enabled ? <CheckIcon /> : <CloseIcon />} />
                    <TextField source="trigger.name" label="Trigger" sortable={false} />
                    <FunctionField label="Type" sortBy="type" render={(record: any) => record && record.type ? FormatType(record.type) : ""} />
                    <FunctionField label="Last Updated" sortBy="updatedAt" render={(record: any) => record && record.updatedAt !== 0 ? new Date(record.updatedAt).toLocaleString() : "N/A"} />
                    {/* multiplying by 1000 as last_sent is in seconds */}
                    <FunctionField label="Last Sent" sortBy="last_sent" render={(record: any) => record && record.last_sent !== 0 ? new Date(record.last_sent * 1000).toLocaleString() : "N/A"} />
                </Datagrid>
            </List>
        </Box>
    )
}

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default MessageTemplateList