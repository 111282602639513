import * as React from 'react';
import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    return (
        <img src="/EmbodiLogo.png" alt="EMBODI" height="32" />
    );
};

export default Logo;
