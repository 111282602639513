import { Grid } from '@mui/material';
import {
    BooleanInput,
    Button,
    Edit,
    required,
    SimpleForm,
    useNotify,
    useRedirect,
    TextInput,
    SaveButton,
    Toolbar,
} from 'react-admin';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import SelectionCriteria from '../messageautomations/SelectionCriteria';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import transformAutomationsData from '../messageautomations/TransformAutomationsData';
import ScheduleCriteria from '../messageautomations/ScheduleCriteria';
import { RichTextInput } from 'ra-input-rich-text';
import { convert } from 'html-to-text';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

const transform = (data: any) => {
    data = {
        ...data,
        practice_id: localStorage.getItem('selectedPractice') || '',
        template_id: data.template.id,
        trigger_id: data.trigger?.id,
        is_broadcast: false,
    };

    data = transformAutomationsData(data);

    data.email_subject = data.trigger_data.provider_notification.email_subject;
    data.custom_message = data.trigger_data.provider_notification.custom_message;

    // delete fields that are not in the model
    delete data.template; // auto populating, only need template_id
    delete data.practice; // auto populating, only need practice_id
    delete data.trigger; // auto populating, only need trigger_id

    return data;
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const editorOptions = { // Disable automatic URL linking
    extensions: [StarterKit, Link.configure({ autolink: false })]
}

const ProviderNotificationEdit = () => {
    const redirect = useRedirect();

    const notify = useNotify();

    const onSuccess = (data: any) => {
        notify('Notification updated', { type: 'info' });
        redirect('/providernotifications');
    }

    const onCancel = (data: any) => {
        redirect('/providernotifications');
    }

    const CustomToolbar = (props) => {
        return <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button
                label='Cancel'
                size='medium'
                variant='contained'
                onClick={onCancel}
            />
            <SaveButton alwaysEnable />
        </Toolbar>
    }

    return (
        <Edit
            className='provider-notification-edit'
            sx={{ maxWidth: '1020px' }}
            transform={transform}
            mutationOptions={{ onSuccess }}
            mutationMode='pessimistic'
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='provider-notification'
                toolbar={<CustomToolbar />}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Edit Notification</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        <BooleanInput source='enabled' label='Enabled' />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <p className='provider-notification-label'>1. Name your notification:</p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='provider-notification-label'>2. Enter distribution list:</p>
                    <Grid item xs={12}>
                        <p>Enter a comma separated list of emails to receive the notification. Emails must contain PLACEHOLDER to be valid.</p>
                        <TextInput
                            source='provider_email_list'
                            fullWidth
                            validate={[required()]}
                            label='Distribution List'
                            multiline
                        />
                    </Grid>
                </Grid>

                <ScheduleCriteria mode='edit' />

                <SelectionCriteria mode='edit' />

                <Grid {...gridProps} className='provider-notification-richtext'>
                    <p className='automation-label'>5. Enter the text of the notification:</p>
                    <Grid item xs={12}>
                        <p >Email Subject</p>
                        <RichTextInput
                            id="email-subject"
                            source="trigger_data.provider_notification.email_subject"
                            fullWidth
                            helperText = "Enter an email subject - dynamic text supported"
                            // defaultValue={formData? formData.name: ""} // not showing up when use existing template is picked after Electronic Mail box
                            validate={[required()]}
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                            parse={v => convert(v.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), { wordwrap: false })} // strip html off subject
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <p >Email Message</p>
                        <RichTextInput
                            source='trigger_data.provider_notification.custom_message'
                            fullWidth
                            validate={[required()]}
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            editorOptions={editorOptions}
                            // parse={v => convert(v, { wordwrap: false })} // strip html off message too since we aren't allowing extra formating here
                        />
                    </Grid>
                </Grid>
                <SendTestMessage origin='provider-notification-edit' />
            </SimpleForm>
        </Edit>
    );
};

export default ProviderNotificationEdit;
