import { useEffect, useState } from 'react';

const TimeZoneNotice = () => {

const [practiceTZ, setPracticeTZ] = useState('');

useEffect(()=> {
    const practiceTZCity = localStorage.getItem('selectedPracticeTZ') || 'America/New_York';
    switch (practiceTZCity) {
        case 'America/New_York':
            setPracticeTZ('Eastern Daylight Time');
            break;
        case 'America/Chicago':
            setPracticeTZ('Central Daylight Time');
            break;
        case 'America/Denver':
            setPracticeTZ('Mountain Daylight Time');
            break;
        case 'America/Phoenix':
            setPracticeTZ('Mountain Standard Time');
            break;
        case 'America/Los_Angeles':
            setPracticeTZ('Pacific Daylight Time');
            break;
        case 'America/Anchorage':
            setPracticeTZ('Alaska Daylight Time');
            break;
        case 'America/Honolulu':
            setPracticeTZ('Hawaii Standard Time');
            break;
        default:
            setPracticeTZ('Eastern Daylight Time');
            break;
    }
}, []);


return (
    <span style={{fontSize: '12px', lineHeight: '0.9', color: '#212121', }}>
        All messages are scheduled in the time zone of the practice: <strong>{practiceTZ}</strong>.
    </span>
    )
}

export default TimeZoneNotice;
