import { useEffect } from "react";
import { useGetOne } from "react-admin";

export function QueryBuilderTrigger({ setRecord, triggerId }) {
  const record = useGetOne('triggers', { id: triggerId }).data;

  useEffect(() => {
    setRecord(record);
  }, [record, setRecord])

  return null
}