import * as React from 'react';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { Exercise } from '../../types';

const ExerciseRefField = () => {
    const record = useRecordContext<Exercise>();
    return record ? (
        <MuiLink
            component={Link}
            to={`/exercises/${record.id}`}
            underline="none"
            state={{ "id" : record.patient_id }}
        >
            {/* {record.patient_id} */}
            Edit
        </MuiLink>
    ) : null;
};

ExerciseRefField.defaultProps = {
    source: 'id',
    // label: 'patient_id',
    label: 'Edit',
    sortable: false,
};

export default ExerciseRefField;
