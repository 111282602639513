import { Typography, Box, Card, CardContent, Input, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import { useGetIdentity } from 'react-admin';
import * as React from 'react';
import {
    Datagrid,
    FunctionField,
    List,
    NumberField,
    TextField,
    Pagination,
    Button,
    fetchUtils,
} from 'react-admin';
import PatientLinkField from './PatientLinkField';
import SegmentsField from './SegmentsField';
//import { Mixpanel } from  '../../mixpanel.js';

/*
import SegmentsField from './SegmentsField';
import SegmentInput from './SegmentInput';
import CustomerLinkField from './CustomerLinkField';
import ColoredNumberField from './ColoredNumberField';
*/
import PatientsListAside from './PatientsListAside';

// const visitorFilters = [
//     <SearchInput source="first" alwaysOn />,
//     <DateInput source="last_seen" />,
//     <DateInput source="next_visit" />,
// ];

import '../../scss/pages/Patients.scss';
import { useState } from 'react';
import tokenManager from '../../tokenManager';

const LastImprovementWithIcons = (lastImprovement) => {
    switch (lastImprovement) {
        case -5:
            return (
                <div>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.64242 0.16L11.6424 0.16L11.6424 8.16H16.4824L8.64242 16L0.802422 8.16L5.64242 8.16V0.16Z" fill="#F53838"/>
                    </svg>
                    &nbsp;
                    Much worse
                </div>
            )
            break;
        case -4:
            return (
                <div>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.64242 0.16L11.6424 0.16L11.6424 8.16H16.4824L8.64242 16L0.802422 8.16L5.64242 8.16V0.16Z" fill="#F53838"/>
                    </svg>
                    &nbsp;
                    Worse
                </div>
            )
            break;
        case -3:
            return (
                <div>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.642422 10.84V4.84H8.64242V-1.52588e-07L16.4824 7.84L8.64242 15.68V10.84L0.642422 10.84Z" fill="#F2C94C"/>
                    </svg>
                    &nbsp;
                    Same
                </div>
            )
            break;
        case -2:
            return (
                <div>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3224 15.84H5.32242V7.84H0.482422L8.32242 -1.52588e-07L16.1624 7.84H11.3224V15.84Z" fill="#27AE60"/>
                    </svg>
                    &nbsp;
                    Better
                </div>
            )
            break;
        case -1:
            return (
                <div>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3224 15.84H5.32242V7.84H0.482422L8.32242 -1.52588e-07L16.1624 7.84H11.3224V15.84Z" fill="#27AE60"/>
                    </svg>
                    &nbsp;
                    Much better
                </div>
            )
            break;
        case 0:
            return(
                <div>

                </div>
            )
    
        default:
            return lastImprovement;
            break;
    }
}

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const VisitorList = () => {

  const [phone, setPhone] = useState("")
  const [phoneError, setPhoneError] = useState(false)
  const [phoneResultString, setPhoneResultString] = useState("")
  const [errorArr, setErrorArr] = useState([])
  const [infoArr, setInfoArr] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const { identity, isLoading: identityLoading } = useGetIdentity();

  const phoneChangeHandler = (e) => {
    let formattedNumber;
  
    var { name, value } = e.target
    const { length } = value;
    
    //filter non numbers
    const regex = () => value.replace(/[^0-9\.]+/g, "")
  
    // set area code with parentheses
    const areaCode = () => {
      return `(${regex().slice(0, 3)})`
    }
  
    //set formatting for first six digits
    const firstSix = () => {
      return `${areaCode()} ${regex().slice(3, 6)}`
    }
  
    // dynamic trail as user types
    const trailer = (start) => {
      return `${regex().slice(start, regex().length)}`
    }
  
    if (length <= 3) {
      // First 3 digits
      formattedNumber = regex();
    } else if (length === 4) {
      // After area code
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length === 5) {
      // When deleting digits inside parenthesis
      formattedNumber = `${areaCode().replace(")", "")}`;
    } else if (length > 5 && length <= 9) {
      // Before dash
      formattedNumber = `${areaCode()} ${trailer(3)}`;
    } else if (length >= 10) {
      // After dash
      formattedNumber = `${firstSix()}-${trailer(6)}`;
    }

    if (length > 14) {
      return
    }

    setPhone(formattedNumber)
  
  }

  const checkPhone = async () => {
    setPhoneError(false)
    const phoneString = phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "")
    if (phoneString.length !== 10) {
      setPhoneError(true)
      return;
    }

    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    const accessToken = tokenManager.getToken();

    const options = {}
    options['credentials'] = 'include'
    options['user'] = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }

    const url = `${apiUrl}/kaizenovate/provider/1.0.0/patient-link-check?phone=${phoneString}`

    const result = await fetchUtils.fetchJson(url, options)

    const jsonResult = JSON.parse(result.body)

    if (result.status === 200) {
      setPhoneResultString(jsonResult.mainString)
      setErrorArr(jsonResult.errors)
      setInfoArr(jsonResult.information)
      setDialogOpen(true)
      // Mixpanel.track("LINK.PhoneChecker", {
      //   time: Date.now(),
      //   date: Date(),
      //   user_type: "provider",
      //   phone_number: phone,
      //   patient_actions_remaining: jsonResult.patientActions ?? -1
      // })
    }
  }

  const closeDialog = () => {
    setPhoneResultString("")
    setErrorArr([])
    setInfoArr([])
    setDialogOpen(false)
  }

    return (
      <div>
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='lg'
        >
          <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center'}}>
            {"Patient Phone Checker"}
          </DialogTitle>
          <DialogContent>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px'
            }}>
              <DialogContentText>
                {phoneResultString}
              </DialogContentText>
              {errorArr.length ? <div>
                <DialogContentText>
                  {"Errors"}
                </DialogContentText>
                <ul>
                  {errorArr.map(function(str) {
                    return (
                      <li>
                        {str}
                      </li>
                    )
                  })}
                </ul>
              </div> : <div/>}
              {infoArr.length ? <div>
                <DialogContentText>
                  {"Information"}
                </DialogContentText>
                <ul>
                  {infoArr.map(function(str) {
                    return (
                      <li>
                        {str}
                      </li>
                    )
                  })}
                </ul>
              </div> : <div/>}
            </div>
            <DialogActions>
              <Button onClick={closeDialog} label="OK"/>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Box mt={2} className="patient-list-container"> 
            <Card style={{marginBottom: '15px', width: '500px', height: '58px'}}>
              <CardContent style={{ padding: '0 0 0 15px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <form onSubmit={checkPhone} style={{width: '100%'}}>
                    <Input
                      error={phoneError}
                      id="phone"
                      type="tel"
                      placeholder="Enter patient phone to check account linking"
                      value={phone}
                      onChange={phoneChangeHandler}
                      style={{
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </form>
                  <div>
                    <Button 
                      label="CHECK"
                      onClick={checkPhone}
                      style={{
                        backgroundColor: '#dddddd',
                        color: "black",
                        padding: '10px',
                        margin: '10px',
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <List
                // filters={ visitorFilters }
                sort={{ field: 'patient_actions', order: 'DESC' }}
                // perPage={25}
                aside={<PatientsListAside />}
                actions={ false }
                pagination={<PostPagination/>}
            >
                <Datagrid
                    optimized
                    rowClick={(id) => {return `/users/${id}/patient-details`;}}
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <PatientLinkField label="Name" />
                    <SegmentsField source="patient_actions" label="Patient Action" />
                    <FunctionField label="Last Improvement" sortBy="statistics_last_improvement_score" render={record => record ? LastImprovementWithIcons(record.statistics_last_improvement_score) : ""}/>
                    <FunctionField label="Next Visit" sortBy="statistics_next_visit" render={record => record && record.statistics_next_visit != 0 ? new Date(record.statistics_next_visit*1000).toLocaleString() : "N/A"} />
                    <FunctionField label="Last Seen" sortBy="statistics_last_seen" render={record => record && record.statistics_last_seen != 0 ? new Date(record.statistics_last_seen*1000).toLocaleString() : "N/A"} />
                    <NumberField source="statistics_missed" label="Missed" />
                    <FunctionField label="7-day Avg Score" sortBy="statistics_avg_score" render={record => record && record.statistics_avg_score ? record.statistics_avg_score.toString() + "%" : "0%"} />
                    <TextField source="app_usage_status" label="App Usage Status" />
                </Datagrid>
            </List>
        </Box>
      </div>
    );
};

export default VisitorList;