import { Box } from '@mui/material';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ExportButton,
} from 'react-admin';
import DateFilter from './DateFilter';

const LogsActions = () => (
    <TopToolbar>
        <ExportButton />
    </TopToolbar>
)

const LogsList = () => {
    return (
        <Box>
            <List
                filters={<DateFilter />}
                actions={<LogsActions />}
            >
                <Datagrid
                    optimized
                    bulkActionButtons={false}
                >
                    <TextField source="id" label="ID" sortable={false} />
                    <DateField source="_source.time" label="Time" showTime={true} sortable={false} />
                    <TextField source="_source.ip" label="IP Address" sortable={false} />
                    <TextField source="_source.log" label="Log" sortable={false} />
                    <TextField source="_id" label="Log Reference" sortable={false} />
                    <TextField source="_source.container" label="Container" sortable={false} />
                </Datagrid>
            </List>
        </Box>
    )
};

export default LogsList;