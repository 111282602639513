import { Button, FormGroup, Box } from "@mui/material"
import React, { useContext, useState, useEffect } from "react"
import { AUTH_LOGOUT } from "react-admin"
import authProvider from "../authProvider"
import { UserContext } from "../context/UserContext"
import { Card, Avatar, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';
import PasswordChecklist from "react-password-checklist"

import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { TextInput, Link } from 'react-admin';
import {
    Form,
    required,
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
} from 'ra-core';
import { Mixpanel } from '../mixpanel.js'
import config from '../config'
const authUrl = config.authUrl

const ResetPassword = () => {
  useEffect(() => {Mixpanel.track('ON.LoginResetEmailClick')}, [])

  const notify = useNotify();

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [loading, setLoading] = useSafeSetState(false);

  const PasswordValidate = values => {
      const errors = {}
      if (values.password !== values.password2) {
          errors['password2'] = 'Password mismatched' ;
      }
      return errors;
  };

  const formSubmitHandler = e => {
    console.log(e)

    if (password !== password2) {
      setError(`Passwords must match.`)
      return false
    }
    setLoading(true)
    setError("")

    const genericErrorMessage = "Something went wrong! Please try again later."

    // TODO remove
    // console.log(localStorage.getItem('resetPasswordToken'))

    return fetch(`${authUrl}/users/resetPassword?resetPasswordToken=${encodeURIComponent( localStorage.getItem('resetPasswordToken') ?? "" )}`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password }),
    })
      .then(async response => {
        const data = await response.json()
        setLoading(false)
        if (!response.ok) {
          if (response.status === 400) {
            notify(data.error)
            return false
          } else {
            localStorage.setItem('notify',data.error)
            localStorage.removeItem('resetPasswordToken')
            window.location.href='/#/login'            
          }
        } else {
            localStorage.removeItem('resetPasswordToken')
            window.location.href='/#/Success'            
        }
      })
      .catch(error => {
        setLoading(false)
        notify(
            typeof error === 'string'
                ? error
                : typeof error === 'undefined' || !error.message
                ? 'ra.auth.sign_in_error'
                : error.message,
            {
                type: 'error',
                messageArgs: {
                    _:
                        typeof error === 'string'
                            ? error
                            : error && error.message
                            ? error.message
                            : undefined,
                },
            }
        );
        setError(genericErrorMessage)
        return false
      })
  }

  return (
    <Root>
        <Card className={LoginClasses.card}>
            <div className={LoginClasses.avatar}>
                <Avatar className={LoginClasses.icon}>
                    <LockIcon />
                </Avatar>
            </div>
            <CardContent className={LoginClasses.content}>

              <StyledForm
                  onSubmit={formSubmitHandler}
                  mode="onChange"
                  noValidate
                  className="recoverPassword"
                  validate={PasswordValidate}
              >
                <TextInput
                    autoFocus
                    source="password"
                    label="Password"
                    autoComplete="password"
                    type="password"
                    validate={required()}
                    onChange={e => setPassword(e.target.value)}
                    fullWidth
                />
                <TextInput
                    source="password2"
                    label="Password (again)"
                    autoComplete="password"
                    type="password"
                    validate={required()}
                    onChange={e => setPassword2(e.target.value)}
                    fullWidth
                />
                <PasswordChecklist
                  rules={["minLength","specialChar","number","capital","match"]}
                  minLength={8}
                  value={password}
                  valueAgain={password2}
                  onChange={(isValid) => {}}
                />                                
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        "Reset Password"
                    )}
                </Button>
            </StyledForm>
          </CardContent>
        </Card>
    </Root>
  )
}

export default ResetPassword

const PREFIX = 'Kaizo';
export const LoginClasses = {
    card: `${PREFIX}-card`,
    avatar: `${PREFIX}-avatar`,
    icon: `${PREFIX}-icon`,
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginClasses.button}`]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    [`& .${LoginClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));


const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${LoginClasses.content}`]: {
        width: 300,
    },    
    [`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },
}));