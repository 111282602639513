import { fetchUtils } from 'react-admin';
import tokenManager from './tokenManager';


const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
const httpClient = fetchUtils.fetchJson;


export async function getCondition(id) {
    const accessToken = tokenManager.getToken();

    let condition = ''
    await httpClient(`${apiUrl}/conditions/${id}`, {
        method: 'GET',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
    }).catch((e)=> {
        console.log(`Error in getData:getCondition(${id})`)
        console.error(e);
        throw e
    }).then(({ json }) => {
        condition = json.name;
    })

    return condition
}

export async function getExerciseLibrary(id) {
    const accessToken = tokenManager.getToken();

    let name = ''
    await httpClient(`${apiUrl}/exerciselibrarys/${id}`, {
        method: 'GET',
        credentials: 'include',
        user: {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        },
    }).catch((e)=> {
        console.log(`Error in getData:getExerciseLibrary(${id})`)
        console.error(e)
        throw e
    }).then(({ json }) => {
        name = json.name;
    })

    return name
}