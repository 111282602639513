import { Box, Collapse, Grid, IconButton, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { TextField, fetchUtils, useRefresh } from "react-admin";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import tokenManager from "../../../tokenManager";
import PatientDetailsContainer from "./PatientDetailsContainer";
import SyncIcon from '@mui/icons-material/Sync';
import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';

const UserConditionList = ({ data }) => {

  const groupByCondition = (diagnosisList) => {
    return diagnosisList.reduce((acc, diagnosis) => {
      const { name, icd_code, status } = diagnosis
      if (!acc[name]) {
        acc[name] = {
          id: diagnosis.condition_id,
          user_id: diagnosis.user,
          name,
          feedbackEnabled: diagnosis.feedbackEnabled,
          diagnoses: []
        }
      } else if (!acc[name].feedbackEnabled && diagnosis.feedbackEnabled) {
        acc[name].feedbackEnabled = diagnosis.feedbackEnabled
      }

      acc[name].diagnoses.push({
        icd_code,
        icd_description: diagnosis.icd_description,
        status
      })

      return acc;
    }, {});
  }

  const groupedConditions = Object.values(groupByCondition(data.conditions)).sort((a:any, b:any) => {
    if (a.name.toLowerCase().includes('not supported')) return 1;
    if (b.name.toLowerCase().includes('not supported')) return -1;
    return a.name.localeCompare(b.name)
  });

  const [allOpen, setAllOpen] = useState(false)

  let initialOpenStates = {}
  let initialFeedbackStates = {}
  groupedConditions.forEach((condition:any) => {
    initialOpenStates[condition.name] = false
    initialFeedbackStates[condition.id] = condition.feedbackEnabled.toString()
  })
  const [openStates, setOpenStates] = useState(initialOpenStates)
  const [feedbackStates, setFeedbackStates] = useState(initialFeedbackStates)
  const [moreOptionsLoading, setMoreOptionsLoading] = useState(false)
  const refresh = useRefresh();

  const toggleAllOpen = () => {
    const openStatesCopy = {...openStates}
    Object.keys(openStatesCopy).forEach((condition) => {
      openStatesCopy[condition] = !allOpen
    })
    setAllOpen(!allOpen)
    setOpenStates(openStatesCopy)
  }

  const toggleFeedbackEnabled = async (event, conditionIds, user_id, setFeedbackEnabled) => {
      setMoreOptionsLoading(true)
      
      const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
      const httpClient = fetchUtils.fetchJson;
      const accessToken = tokenManager.getToken();

      const copyString = await httpClient(`${apiUrl}/kaizenovate/provider/1.0.0/edit-condition-feedback-status`, {
          method: 'POST',
          credentials: 'include',
          user: {
              authenticated: true,
              token: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
              user_id: user_id,
              condition_ids: conditionIds,
              feedback: event.target.value === 'true' ? true : false
          }),
      }).catch((e)=> {
          console.log(`Error in billingsEdit:handleClickBilled()`)
          console.error(e)
          setMoreOptionsLoading(false)
          throw e
      }).then((response) => {
          if (response.json.error) {
              console.error(response.json.error)
              setMoreOptionsLoading(false)
              throw new Error(response.json.error)
          } else {
              let newFeedbackStates = {...feedbackStates}
              conditionIds.forEach((conditionId) => {
                  newFeedbackStates[conditionId] = event.target.value
              })
              setFeedbackEnabled(newFeedbackStates)
              setMoreOptionsLoading(false)
          }
      })
  }

  const setAllFeedback = async (value) => {
      if (data.conditions.length === 0) return
      await toggleFeedbackEnabled({target: {value: value}}, Object.keys(feedbackStates), data.id, setFeedbackStates)
      return
  }

  const syncDxCodes = async () => {
    console.log("Sync DX codes")

    setMoreOptionsLoading(true)

    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    const accessToken = tokenManager.getToken();

    const options = {}
    options['credentials'] = 'include'
    options['user'] = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }

    const url = `${apiUrl}/kaizenovate/provider/1.0.0/sync-patient-dx?user_id=${data.id}`

    const result = await fetchUtils.fetchJson(url, options)

    if (result.status < 200 || result.status >= 300) {
      console.error("An error occurred while trying to sync patient DX codes")
    }

    setMoreOptionsLoading(false)
    refresh()
  }



  const ConditionRow = ({ record, feedbackStates, setFeedbackStates }) => {

    const rowFeedbackToggle = async (event) => {
        await toggleFeedbackEnabled(event, [record.id], record.user_id, setFeedbackStates)
    }

    const toggleOpen = () => {
      let copyOpenStates = {...openStates}
      copyOpenStates[record.name] = !copyOpenStates[record.name]
      if (copyOpenStates[record.name] && !allOpen) {
        setAllOpen(true)
      } else if (!copyOpenStates[record.name] && allOpen) {
        const openValues = Object.values(copyOpenStates)
        if (!openValues.includes(true)) {
          setAllOpen(false)
        }
      }
      setOpenStates(copyOpenStates)
    }
  
    return (
      <>
        <tr className='condition-row'>
          <td className="condition-expand-icon">
            <IconButton onClick={() => toggleOpen()}>
              {openStates[record.name] ?  <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ transform: 'rotate(270deg)' }} />}
            </IconButton>
          </td>
          <td className="condition-name">
            {<TextField source="name" record={(!record.name.toLowerCase().includes('not supported')) ? record : {name: "Other Diagnosis Codes"}} />}
          </td>
          {(!record.name.toLowerCase().includes('not supported')) ? <td className="condition-feedback-status">
            <Select
              className={`feedback-select ${feedbackStates[record.id] === 'true' ? 'feedback-enabled' : ''}`}
              value={feedbackStates[record.id]}
              onChange={rowFeedbackToggle}
            >
              <MenuItem value={'true'}>Feedback Enabled</MenuItem>
              <MenuItem value={'false'}>Feedback Disabled</MenuItem>
            </Select>
          </td> : null}
        </tr>
        {openStates[record.name] && (
          <tr className='sublist-row'>
            <td colSpan={3}>
              <Collapse in={openStates[record.name]}>
                {/* <Box margin={2}> */}
                <Box className='diagnosis-sublist'>
                  {record.diagnoses.map((diagnosis) => (
                    <div key={diagnosis.icd_code} className='diagnosis-item'>
                      <span className="diagnosis-spacer"></span>
                      <span className="icd-code">{diagnosis.icd_code.toUpperCase()}</span>
                      <span className="icd-description">{diagnosis.icd_description}</span>
                      <span className={`status-label ${diagnosis.status}`}>{diagnosis.status.charAt(0).toUpperCase() + diagnosis.status.slice(1)}</span>
                    </div>
                  ))}
                </Box>
              </Collapse>
            </td>
          </tr>
        )}
      </>
    )
  }





  return (
    <PatientDetailsContainer
      title="Patient Diagnosis"
      showMenu={true}
      menuOptions={[
        {
          label: (<span style={{ color: 'blue' }}>Sync Diagnosis Codes</span>),
          icon: (<SyncIcon sx={{ color: 'blue', marginRight: 1}} />),
          onClick: syncDxCodes
        },
        {
          label: (<span style={{ color: 'green' }}>Enable All Feedback</span>),
          icon: (<HearingIcon sx={{ color: 'green', marginRight: 1}} />),
          onClick: (async () => {await setAllFeedback('true')})
        },
        {
          label: (<span>Disable All Feedback</span>),
          icon: (<HearingDisabledIcon sx={{ color: 'gray', marginRight: 1}} />),
          onClick: (async () => {await setAllFeedback('false')})
        }
      ]}
      menuLoading={moreOptionsLoading}
    >
      <Grid item xs={12}>
        <div className='diagnosisList'>
          <table>
            <thead>
              <tr>
                <th>
                  <IconButton onClick={() => toggleAllOpen()}>
                    {allOpen ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ transform: 'rotate(270deg)' }} />}
                  </IconButton>
                </th>
                <th>
                  Condition
                </th>
                <th>
                  Status / Post-Appt PROs
                </th>
              </tr>
            </thead>
            <tbody>
              {groupedConditions.map((condition:any) => (
                <ConditionRow key={condition.name} record={condition} feedbackStates={feedbackStates} setFeedbackStates={setFeedbackStates} />
              ))}
            </tbody>
          </table>
        </div>
      </Grid>
    </PatientDetailsContainer>
  )
}

export default UserConditionList;
