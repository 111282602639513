import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
    endOfToday,
    addWeeks,
    addMonths,
    endOfMonth,
    endOfWeek,
} from 'date-fns';

import segments from '../../segments/data';
import { subDays } from 'date-fns';

const Aside = () => {
    return (
    
        <Card
            sx={{
                display: {
                    xs: 'none',
                    md: 'block',
                },
                order: -1,
                flex: '0 0 16em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch source="first" label="First Name" />

                <FilterLiveSearch source="last" label="Last Name" />
    
                <FilterList
                    label="Last Seen"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Today"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(endOfYesterday()).getTime()/1000),
                                "<=": Math.round(new Date(endOfToday()).getTime()/1000),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This week"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(subDays(startOfWeek(new Date()),1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfWeek(new Date())).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Last week"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(subWeeks(subDays(startOfWeek(new Date()),1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(subDays(startOfWeek(new Date()),1)).getTime()/1000),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This month"
                        value={{
                            statistics_last_seen: { 
                                ">=": Math.round(new Date(subDays(startOfMonth(new Date()), 1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfMonth(new Date())).getTime()/1000), 
                             },
                        }}
                    />
                    <FilterListItem
                        label="Last month"
                        value={{
                            statistics_last_seen: {
                                ">=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()), 1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(subDays(startOfMonth(new Date()), 1)).getTime()/1000),
                            },
                        }}
                    />
                    <FilterListItem
                        label="Earlier"
                        value={{
                            statistics_last_seen: {"<=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()), 1),1)).getTime()/1000) },
                        }}
                    />
                </FilterList>
    
                <FilterList
                    label="Next Appointment"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Today"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(endOfYesterday()).getTime()/1000), 
                                "<=": Math.round(new Date(endOfToday()).getTime()/1000),
                            },
                        }}
                    />
                    <FilterListItem
                        label="This week"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(subDays(startOfWeek(new Date()),1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfWeek(new Date())).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Next week"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(addWeeks(subDays(startOfWeek(new Date()),1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(addWeeks(subDays(startOfWeek(new Date()),1),2)).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="This month"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(subDays(startOfMonth(new Date()),1)).getTime()/1000), 
                                "<=": Math.round(new Date(endOfMonth(new Date())).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Next month"
                        value={{
                            statistics_next_visit: {
                                ">=": Math.round(new Date(addMonths(subDays(startOfMonth(new Date()),1),1)).getTime()/1000), 
                                "<=": Math.round(new Date(addMonths(subDays(startOfMonth(new Date()),1),2)).getTime()/1000), 
                            },
                        }}
                    />
                    <FilterListItem
                        label="Earlier"
                        value={{
                            statistics_next_visit: {
                                "<=": Math.round(new Date(subMonths(subDays(startOfMonth(new Date()),1),1)).getTime()/1000), 
                            },
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Patient Status"
                    icon={<PersonOutlineIcon />}
                >
                    <FilterListItem
                        label="Active"
                        value={{ status: {'!=': "discharged"} }}
                    />
                    <FilterListItem
                        label="Discharged"
                        value={{ status: "discharged" }}
                    />
                </FilterList>
    
                <FilterList
                    label="Missed Appointment"
                    icon={<MailIcon />}
                >
                    <FilterListItem
                        label="ra.boolean.true"
                        value={{ 
                            statistics_missed: {
                                "!=": 0,
                            },
                        }}
                    />
                    <FilterListItem
                        label="ra.boolean.false"
                        value={{ 
                            statistics_missed: 0,
                         }}
                    />
                </FilterList>
    
                <FilterList
                    label="Patient Action"
                    icon={<LocalOfferIcon />}
                >
                    {segments.map(segment => (
                        <FilterListItem
                            label={segment.name}
                            key={segment.id}
                            value={{ patient_actions: {"contains": segment.name } }}
                        />
                    ))}
                </FilterList>
    
                <FilterList
                    label="App Usage Status"
                    icon={<AccessTimeIcon />}
                >
                    <FilterListItem
                        label="Active"
                        value={{ app_usage_status: "Active" }}
                    />
                    <FilterListItem
                        label="Inactive"
                            value={{ app_usage_status: "Inactive" }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    )
};

export default Aside;
