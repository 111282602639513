export default [
    { id: 'cancelled_appointment', name: 'Cancellation Requested' },
    { id: 'has_increased_symptoms', name: 'Increased Symptoms' },
    { id: 'feeling_worse', name: 'Feeling Worse' },
    { id: 'needs_approved_hep', name: 'Approve HEP' },
    { id: 'sent_message', name: 'Sent Message' },
    { id: 'not_improving', name: 'Not Improving' },
    { id: 'not_adhering', name: 'Not Adhering' },
    { id: 'call_patient', name: 'Call Patient'},
    { id: 'provide_patient_orientation', name: 'Provide Patient Orientation'},
    { id: 'new_patient', name: 'New Patient'},
    ];