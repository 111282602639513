import { useEffect, useRef, useState } from 'react';
import { useSidebarState } from 'react-admin';
import { useTiptapEditor } from 'ra-input-rich-text';
import EmojiPicker from 'emoji-picker-react';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import '../../scss/components/enhancedmessaging/ToolbarEmojiPicker.scss';

const ToolbarEmojiPicker = ({richTextMode}: {richTextMode: boolean}) => {
    const [sidebarOpen] = useSidebarState();
    const editor = useTiptapEditor();
    const [displayEmojiPicker, setDisplayEmojiPicker] = useState(false);
    const ref = useRef<any>(null);

    const InsertEmoji = (emojiObj: any, event: any) => {
        if (editor) editor.chain().focus().insertContent(emojiObj.emoji).run();
        setDisplayEmojiPicker(false);
    }

    const displayPicker = () => {
        setDisplayEmojiPicker(!displayEmojiPicker);
    }

    
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setDisplayEmojiPicker(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    let emojiPickerClass = `toolbar-emoji-container ${richTextMode ? 'rich-toolbar' : 'simple-toolbar'}`;
    if (sidebarOpen) emojiPickerClass += ' sidebar-open';

    return (
        <div ref={ref} className={emojiPickerClass} >
            <button type="button"
                onClick={displayPicker}
                className={`toolbar-emoji-container-button ${displayEmojiPicker ? 'active' : ''}`}
            >
                <SentimentSatisfiedAltIcon/>
            </button>
            {displayEmojiPicker &&
                <EmojiPicker
                    onEmojiClick={(emojiObj, event) => {
                        InsertEmoji(emojiObj, event)
                    }}
                />
            }
        </div>
    );
}

export default ToolbarEmojiPicker;