import React, { useEffect } from "react"


const RedirectPassword = () => {

  useEffect(() => {
    // clean the URL and store token in local storage
    if (window.location.search) {
      localStorage.setItem('resetPasswordToken',window.location.search.replace('?token=',''))
      window.location.href = `${window.location.pathname}#/ResetPassword`
    }
  }, [])

  return <></>

}


export default RedirectPassword