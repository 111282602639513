import { Box, Grid, Typography } from "@mui/material"
import PatientDetailsContainer from "./PatientDetailsContainer"
import { 
  LineChart, 
  CartesianGrid, 
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { DataKey } from 'recharts/types/util/types';

const AppFeedbackHistory = ({ record }) => {

    const formatXAxis = (tickItem) => {
        const d = new Date(tickItem);
        return d.toLocaleString('default', { year: 'numeric' });
    };

    const formatXAxisMonth = (tickItem) => {
        const d = new Date(tickItem);
        return d.toLocaleString('default', { month: 'long' });
    };

    const capitalizeWord = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const renderLegend = () => {
      
        return (
            <Box className="legend-container">
                <Box className="legend-marker">
                    <div className="legend-color green"></div>
                    90-100% - Excellent
                </Box>
                <Box className="legend-marker">
                    <div className="legend-color yellow"></div>
                    70-90% - Good
                </Box>
                <Box className="legend-marker">
                    <div className="legend-color red"></div>
                    70% and below - Warning
                </Box>
            </Box>
        );
      }

    var recordSymptomsDataValues: DataKey<any>[] = [];
    record.symptoms_data.forEach((entry, i) => {
        const keyArr = Object.keys(entry);
        keyArr.forEach((key) => {
            if(key !== "date" && recordSymptomsDataValues.indexOf(key) === -1) recordSymptomsDataValues.push(key);
        })
    });

    return (
        <PatientDetailsContainer title="App Feedback History" showMenu={false} menuOptions = {[]} menuLoading={false}>
            <Grid item xs={12}>
                <Box p={3} mb={4} width={800} sx={{ borderRadius: '8px', border: 1, borderColor: '#E0E4ED' }}>
                    <Typography variant="h6" gutterBottom mb={4}>
                        <Box fontWeight='600'>
                            Accountability Score
                        </Box>
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={record.accountability_data}
                        margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                            <CartesianGrid vertical={false}/>
                            <XAxis 
                                interval={12} 
                                tickFormatter={formatXAxis} 
                                dataKey="date"
                                axisLine={false}
                                tickLine={false}
                                dy={10}
                                dx={100}
                            />
                            <YAxis
                                domain={[0, 10]}
                                tickFormatter={(tick) => {
                                return `${tick}%`;
                                }} 
                                axisLine={false}
                                tickLine={false}
                            />
                            <Tooltip />
                            <Legend content={renderLegend} />
                            <ReferenceLine 
                                y={90}
                                strokeDasharray="6 4"
                                stroke="#11BF95"
                            />
                            <ReferenceLine 
                                y={70}
                                strokeDasharray="6 4"
                                stroke="#FFD645"
                            />
                            <Line 
                                dataKey="score" 
                                stroke="#8884d8" 
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
                <Box p={3} mb={4} width={800} sx={{ borderRadius: '8px', border: 1, borderColor: '#E0E4ED' }}>
                    <Typography variant="h6" gutterBottom mb={4}>
                        <Box fontWeight='600'>
                            Improvement Since Beginning of Care
                        </Box>
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={record.improvement_data}
                        margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                            <CartesianGrid vertical={false}/>
                            <XAxis 
                                interval={12} 
                                tickFormatter={formatXAxis} 
                                dataKey="date"
                                axisLine={false}
                                tickLine={false}
                                dy={10}
                                dx={100}
                            />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                width={120}
                                tickFormatter={(tick) => {
                                    switch(tick) {
                                        case 0:
                                            return "Much Worse";
                                        case 1:
                                            return "A Little Worse";
                                        case 2:
                                            return "Same";
                                        case 3:
                                            return "A Little Better";
                                        case 4:
                                            return "Much Better";
                                        default:
                                            return "";
                                    }
                                }} 
                            />
                            <Tooltip />
                            <ReferenceLine 
                                y={90}
                                strokeDasharray="6 4"
                                stroke="#11BF95"
                            />
                            <ReferenceLine 
                                y={70}
                                strokeDasharray="6 4"
                                stroke="#FFD645"
                            />
                            <Line 
                                dataKey="score" 
                                stroke="#8884d8" 
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
                <Box p={3} mb={8} width={800} sx={{ borderRadius: '8px', border: 1, borderColor: '#E0E4ED' }}>
                    <Typography variant="h6" gutterBottom mb={4}>
                        <Box fontWeight='600'>
                            Reports of Increased Symptoms
                        </Box>
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart 
                        // data={record.symptoms_data}
                        data={record.symptoms_data}
                        margin={{ top: 10, right: 30, left: 0, bottom: 5 }}>
                            <CartesianGrid vertical={false}/>
                            <XAxis 
                                interval={30} 
                                tickFormatter={formatXAxisMonth} 
                                dataKey="date"
                                axisLine={false}
                                tickLine={false}
                                dy={10}
                                dx={60}
                                height={ 50 }
                            />
                            <YAxis
                                domain={[0, 10]}
                                tickFormatter={(tick) => {
                                    switch(tick) {
                                        case 0:
                                            return "None";
                                        case 10:
                                            return "Severe";
                                        default:
                                            return "";
                                    }
                                }} 
                                axisLine={false}
                                tickLine={false}
                                ticks={[0, 10]}
                            />
                            <Tooltip />
                            <Legend
                                iconType="circle"
                            />
                            {recordSymptomsDataValues.map((val, index) => (
                                <Line 
                                    dataKey={val} 
                                    name={val.toString()} 
                                    stroke={index % 2 === 1 ? "#2439F2" : "#F2AC24"} 
                                    strokeWidth={2}
                                    dot={false}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </PatientDetailsContainer>
    )
}

export default AppFeedbackHistory;
