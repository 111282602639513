import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import MoreVertIcon from '@mui/icons-material/MoreVert';

const PatientDetailsContainer = ({ title, children, showMenu, menuOptions, menuLoading }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box
            sx={{
                border: '1px solid #ddd',
                borderRadius: '16px',
                mb: 2,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    borderBottom: '1px solid #ddd',
                    padding: '16px',
                }}
            >
                <Typography variant="h5" fontWeight='fontWeightBold'>{title}</Typography>
                {showMenu && (
                  <>
                      <IconButton onClick={handleMenuClick}>
                          {menuLoading ? (
                              <CircularProgress size={24} thickness={3} />
                            ) : (
                              <MoreVertIcon />
                            )}
                      </IconButton>
                      <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                      >
                          {menuOptions.map((option) => {
                              return (
                                <MenuItem onClick={async () => {await option.onClick(); handleMenuClose();}}>
                                    {option.icon}
                                    {option.label}
                                </MenuItem>
                              );
                          })}
                      </Menu>
                  </>
                )}
            </Box>

            <Box sx={{ padding: 1 }}>
              {children}
            </Box>
        </Box>
    )
}

export default PatientDetailsContainer;
