import * as React from 'react';
import { Stack, Chip } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';
import { Patient } from '../../types';

// const segmentsById = segments.reduce((acc, segment) => {
//     acc[segment.id] = segment;
//     return acc;
// }, {} as { [key: string]: any });

const SegmentsField = (props: FieldProps) => {
    const record = useRecordContext<Patient>();
    if (!record || !record.patient_actions) {
        return null;
    }

    var trimmedRecord = record.patient_actions;
    
    if (trimmedRecord != null) {
        if(trimmedRecord.find((rec) => rec.includes("Cancellation Requested"))) {
            trimmedRecord = trimmedRecord.filter((action) => 
            {
                return !action.includes("Cancellation Requested");
            });
            trimmedRecord.push("Cancellation Requested")
        }
    }

    return (
        <Stack direction="row" gap={1} flexWrap="wrap">
            {trimmedRecord.map(segmentId => (
                <Chip
                    size="small"
                    key={segmentId}
                    label={segmentId}
                    // label={translate(segmentsById[segmentId].name)}
                />
            ))}
        </Stack>
    );
};

export default SegmentsField;
