import List from './List';
import Edit from './Edit';
import { MessageTemplateCreate as Create }  from './Create';

const resource = {
    list: List,
    edit: Edit,
    create: Create
};

export default resource;
