import { FaLock, FaLockOpen } from "react-icons/fa6";
import '../../scss/components/messages/SecureMessageToggle.scss'
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from "react";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: theme.typography.pxToRem(14),
      maxWidth: 360,
    },
  }));

export const SecureMessageToggle = ({ customType, setCustomType, channel, record }) => {

  const toggleCustomType = () => {
    if (channel && channel.data && channel.data.unknown_user) {
      return;
    }

    switch (customType) {
      case "secure":
        setCustomType("non-secure");
        break;
      case "non-secure":
        setCustomType("secure");
        break;
      case "schedule":
        setCustomType("non-secure");
        break;
      default:
        setCustomType("secure");
        break;
    }
  }

  return (
    <div className="secure-message-toggle-container">
      {(!(channel && channel.data && channel.data.unknown_user) && (record && record.status !== 'discharged')) ? <div className={`secure-message-toggle-container-${customType}`}>
        <div className="secure-message-toggle-icon-container">
          {customType === "secure" || customType === "schedule" ? <FaLock color={"#04B02D"} /> : <FaLockOpen color={"#CA0000"} />}
        </div>
        <div className="secure-message-toggle-text-container">
          {customType === "secure" || customType === "schedule" ? "SECURE MESSAGE" : "NON-SECURE TEXT MESSAGE"}
        </div>
        <div className="secure-message-toggle-text-button-container">
          <div style={{ fontSize: 'smaller'}}>
            {"("}
          </div>
          <div className="secure-message-toggle-text-button" onClick={toggleCustomType} >
            {`switch to ${customType === "secure" || customType === "schedule" ? "non-secure text" : "secure"} message`}
          </div>
          <div style={{ fontSize: 'smaller'}}>
            {")"}
          </div>
        </div>
      </div> : <div className={`secure-message-toggle-container-${customType}`}>
        <div className="secure-message-toggle-icon-container">
          <FaLockOpen color={"#CA0000"} />
        </div>
        <div className="secure-message-toggle-text-container">
          NON-SECURE TEXT MESSAGE
        </div>
      </div>}
      <div className="secure-message-toggle-tooltip-container">
        <HtmlTooltip
          title={
          <React.Fragment>
            <Typography color="inherit">Message Type Info - If the current patient is opted in to 2-way messaging, messages you send here will also be sent as an SMS to the patient's phone </Typography>
            <br />
            {"Secure Message - These messages will trigger a text message that lets the patient know they have a secure message waiting for them in the EMBODI app. The text will include a deep link to view the message in the app, but does not include the message content."}
            <br /><br />
            {"Non-Secure Text Message - These messages will send a text to the patient with only the message content. This is not secure and should not be used for PHI or other sensitive information."}
          </React.Fragment>
          }
        >
          <InfoOutlined fontSize='small' />
        </HtmlTooltip>
      </div>
    </div>
  )
}