import { Grid } from '@mui/material';
import {
    Create,
    required,
    SimpleForm,
    useNotify,
    useRedirect,
    TextInput,
    Button,
    Toolbar,
    SaveButton,
} from 'react-admin';
import '../../scss/pages/EnhancedMessaging.scss';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import { RichTextInput } from 'ra-input-rich-text';
import { convert } from 'html-to-text';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';

const transform = (data: any) => {
  data.practice_id = localStorage.getItem('selectedPractice')

  // remove links from message only keeps the text of the a tag
  data.message = convert(data.message.replace(/<a[^>]*>(.*?)<\/a>/g, '$1'), {
    wordwrap: false,
  }) // convert html to text
    .replace(/[\n]{2}/g, '\n') // fix duplicate newlines
    .replace(/[\n]{2,}/g, '\n\n') // no more than one empty line
    .trim() // no leading/trailing newlines or spaces

  return data;
}

const gridProps = {
  container: true,
  rowSpacing: 1,
  mb: 1,
  columnSpacing: 1
}

const editorOptions = { // Disable automatic URL linking
    extensions: [StarterKit, Link.configure({ autolink: false })]
}

const CustomToolbar = ( onCancel ) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={onCancel.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

export const SavedChatMessageCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (record: any) => {
        notify('saved chat message created successfully');
        redirect('/savedchatmessages');
    }

    const onCancel = (data: any) => {
        redirect('/savedchatmessages');
    }

    return <SavedChatMessageForm onCancel={onCancel} onSuccess={onSuccess} />
}

export const SavedChatMessageCreateModal = ({onCancel, onSuccess}) => {
    return <SavedChatMessageForm onCancel={onCancel} onSuccess={onSuccess}/>
};

const SavedChatMessageForm = ({onCancel, onSuccess}) => {
    return (
        <div>
            <Create
                resource='savedchatmessages'
                className="saved-chat-message-create"
                sx={{ maxWidth: '800px', width: '100%' }}
                transform={transform}
                mutationOptions={{ onSuccess: onSuccess }}
                disableAuthentication={true}
            >
                <SimpleForm
                    sx={{ pt: 0, pb: 0 }}
                    className='saved-chat-message-form'
                    toolbar={<CustomToolbar onCancel={onCancel} />}
                >
                    <Grid {...gridProps}>
                        <Grid item xs={10}>
                            <h1>Create Saved Message</h1>
                        </Grid>
                    </Grid>
                    <Grid {...gridProps}>
                        <Grid item xs={12}>
                            <p>Name</p>
                            <TextInput source='name' validate={[required()]} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid {...gridProps} className='saved-chat-message-form-richtext'>
                        <Grid item xs={12}>
                            <p>Message</p>
                            <RichTextInput
                                source='message'
                                fullWidth
                                label={false}
                                validate={[required()]}
                                toolbar={<CustomRichTextToolbar richTextMode={false} />}
                                editorOptions={editorOptions}
                                sx={{minHeight: '200px'}}
                            />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        </div>
    )
}
